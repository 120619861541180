import apiRequest from './apiRequest';
import { timeZone } from '../helpers/date';

/**
 * Default label settings map
 */
export const defaultSettings = {
  project_label: [],
  resource_label: []
};

/**
 * Settings Services
 */
export const settingsServicesAndLabels = (customerId, billingAccountId) => {
  const options = {
    url: `/v2/billing/settings/${customerId}?billing_account_id=${billingAccountId}`,
    method: 'GET',
  };

  return apiRequest(options);
};

/**
 * Aggregated By Settings Request data
 *
 * Aggregated: Services or Project
 * Type: chart or Table
 */
export const aggregatedBy = (
  customerId,
  billingAccountId,
  settings,
  allServices,
  aggregated,
  type = 'chart'
) => {
  const {
    provider,
    services,
    selectedDate,
    startDate,
    endDate,
  } = settings;

  let queryString = `billing_account_id=${billingAccountId}&provider=${provider}&period=${selectedDate}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const options = {
    url: `/v2/billing/aggregated/${aggregated}/${type}/${customerId}?${queryString}`,
    method: 'POST',
    data: JSON.stringify({
      services: allServices && services && (allServices.length === services.length) ? [] : services
    })
  };

  return apiRequest(options);
};

/**
 * Details by Settings Request data
 *
 * Type: chart or Table
 */
export const details = (
  customerId,
  billingAccountId,
  settings,
  allServices,
  type = 'chart'
) => {
  const {
    provider,
    services,
    selectedDate,
    startDate,
    endDate,
  } = settings;

  let queryString = `billing_account_id=${billingAccountId}&provider=${provider}&period=${selectedDate}&timezone=${timeZone}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const options = {
    url: `/v2/billing/details/${type}/${customerId}?${queryString}`,
    method: 'POST',
    data: JSON.stringify({
      services: allServices && services && (allServices.length === services.length) ? [] : services
    })
  };

  return apiRequest(options);
};

/**
 * Total Project Labels By Settings Request data
 *
 * Type: chart or Table
 */
export const totalProjectLabels = (
  customerId,
  billingAccountId,
  settings,
  defaultLabel,
  type = 'chart'
) => {
  const {
    projectLabel,
    selectedDate,
    startDate,
    endDate
  } = settings;

  const label = projectLabel || defaultLabel;
  let queryString = `billing_account_id=${billingAccountId}&period=${selectedDate}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const options = {
    url: `/v2/billing/project/labels/${type}/${customerId}?${queryString}`,
    method: 'POST',
    data: JSON.stringify({
      project_label: label
    })
  };

  return apiRequest(options);
};

/**
 * Project Labels Details by Settings Request data
 *
 * Type: chart or Table
 */
export const detailsProjectLabels = (
  customerId,
  billingAccountId,
  settings,
  defaultLabel,
  type = 'chart'
) => {
  const {
    projectLabel,
    selectedDate,
    startDate,
    endDate,
  } = settings;

  const label = projectLabel || defaultLabel;
  let queryString = `billing_account_id=${billingAccountId}&period=${selectedDate}&timezone=${timeZone}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const options = {
    url: `/v2/billing/project/labels/details/${type}/${customerId}?${queryString}`,
    method: 'POST',
    data: JSON.stringify({
      project_label: label
    })
  };

  return apiRequest(options);
};

/**
 * Total Project Resource By Settings Request data
 *
 * Type: chart or Table
 */
export const totalResourceLabels = (
  customerId,
  billingAccountId,
  settings,
  type = 'chart',
) => {
  const {
    resourceLabel,
    selectedDate,
    startDate,
    endDate,
  } = settings;

  let queryString = `billing_account_id=${billingAccountId}&period=${selectedDate}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const options = {
    url: `/v2/billing/resource/labels/${type}/${customerId}?${queryString}`,
    method: 'POST',
    data: JSON.stringify({
      resource_label: resourceLabel
    })
  };

  return apiRequest(options);
};

/**
 * Project Labels Details by Settings Request data
 *
 * Type: chart or Table
 */
export const detailsResourceLabels = (
  customerId,
  billingAccountId,
  settings,
  type = 'chart'
) => {
  const {
    resourceLabel,
    selectedDate,
    startDate,
    endDate
  } = settings;

  let queryString = `billing_account_id=${billingAccountId}&period=${selectedDate}&timezone=${timeZone}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  if (billingAccountId) {
    queryString += `&billing_account_id=${billingAccountId}`;
  }

  const options = {
    url: `/v2/billing/resource/labels/details/${type}/${customerId}?${queryString}`,
    method: 'POST',
    data: JSON.stringify({
      resource_label: resourceLabel
    })
  };

  return apiRequest(options);
};

/**
 * Export Service Details by Settings
 */
export const detailsServiceExport = (
  customerId,
  settings
) => {
  const {
    selectedDate,
    startDate,
    endDate,
    billingAccountId
  } = settings;

  let queryString = `period=${selectedDate}&timezone=${timeZone}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  if (billingAccountId) {
    queryString += `&billing_account_id=${billingAccountId}`;
  }

  const options = {
    url: `/v2/billing/details/chart/${customerId}/export?${queryString}`,
    method: 'POST'
  };

  return apiRequest(options);
};

/**
 * Export Project Labels Details by Settings
 */
export const detailsProjectExport = (
  customerId,
  billingAccountId,
  settings,
  projectLabel
) => {
  const {
    selectedDate,
    startDate,
    endDate
  } = settings;

  let queryString = `billing_account_id=${billingAccountId}&period=${selectedDate}&timezone=${timeZone}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    queryString += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const options = {
    url: `/v2/billing/project/labels/details/chart/${customerId}/export?${queryString}`,
    method: 'POST',
    data: JSON.stringify({
      project_label: projectLabel
    })
  };

  return apiRequest(options);
};
