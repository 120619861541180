import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  MenuItem, Box, TextField, FormControlLabel, Checkbox, Divider
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Context } from '../../../../components/ContextProvider';
import GroupSelector from './GroupSelector';
import Button from '../../../../components/Button';
import colors from '../../../../helpers/colors';
import {
  set, defaultSettings, defaultSelected, providersLabels
} from '../../../../helpers/settings';
import { settingsControls } from '../../../../helpers/styles';
import rbac from '../../../../helpers/rbac';

const useStyles = makeStyles(() => (settingsControls));

const Controls = ({ close }) => {
  const classes = useStyles();

  const {
    settings, selected, updateContext, billing, user
  } = useContext(Context);

  const [selectedControl, setSellectedControl] = useState(selected);

  const [settingsControl, setSettingsControl] = useState({
    ...settings,
    resourceLabel: settings.resourceLabel || null,
    // required validation for billing filters
    projectLabel: settings.projectLabel || Object.keys(billing.project_labels)[0],
  });

  const handleSettingsControl = (key: string, value: string) => {
    const newSettings = {
      ...settingsControl,
      [key]: value
    };

    setSettingsControl(newSettings);
  };

  const handleSelectedControl = (key: string, value: string) => {
    const newSelected = {
      ...selectedControl,
      [key]: value
    };

    setSellectedControl(newSelected);
  };

  const setControlContext = (reset: boolean) => {
    const updateSettings = reset ? defaultSettings : settingsControl;
    const updateSelected = reset ? defaultSelected : selectedControl;

    updateContext({
      settings: updateSettings,
      selected: updateSelected,
    });

    set('settings', updateSettings);
    set('selected', updateSelected);

    close();
  };

  const renderGcpControls = () => (
    <>
      <TextField
        select
        label='Group by'
        className={classes.input}
        variant='outlined'
        value={settingsControl.groupBy}
        onChange={(e) => handleSettingsControl('groupBy', e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      >
        <MenuItem value='serviceAndProject'>Service</MenuItem>
        <MenuItem value='projectLabel'>Project Label</MenuItem>
        <MenuItem value='resourceLabel'>Resource Label</MenuItem>
      </TextField>

      {
        settingsControl.groupBy === 'serviceAndProject' && billing.services && (
          <GroupSelector
            label='Services'
            selected={settings.services}
            options={billing.services}
            handleSettingsControl={(value) => handleSettingsControl('services', value)}
          />
        )
      }

      {
        settingsControl.groupBy === 'projectLabel' && billing.project_labels && (
          <Autocomplete
            value={settingsControl.projectLabel}
            onChange={(event, newValue) => {
              handleSettingsControl('projectLabel', newValue);
            }}
            options={Object.keys(billing.project_labels)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!settingsControl.projectLabel}
                helperText={!settingsControl.projectLabel ? 'This field is required' : ''}
                className={classes.input}
                label='Project Labels'
                variant='outlined'
              />
            )}
          />
        )
      }

      {
        settingsControl.groupBy === 'resourceLabel' && billing.resource_labels && (
          <Autocomplete
            value={settingsControl.resourceLabel}
            onChange={(event, newValue) => {
              handleSettingsControl('resourceLabel', newValue);
            }}
            options={Object.keys(billing.resource_labels)}
            getOptionDisabled={(option) => billing.resource_labels[option].length < 1}
            getOptionLabel={(option) => `${option} ${billing.resource_labels[option].length < 1 ? '(not applicable)' : ''}`}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!settingsControl.resourceLabel}
                helperText={!settingsControl.resourceLabel ? 'This field is required' : ''}
                className={classes.input}
                label='Resource Labels'
                variant='outlined'
              />
            )}
          />
        )
      }
    </>
  );

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        {
          user.claims.role !== 'customerViewer'
          && billing
          && billing.providers
          && billing.providers.length > 1
          && (
            <TextField
              fullWidth
              select
              label='Cloud provider'
              className={classes.input}
              variant='outlined'
              value={settingsControl.provider}
              onChange={(e) => {
                const providerChange = {
                  ...settingsControl,
                  groupBy: 'serviceAndProject',
                  provider: e.target.value
                };
                setSettingsControl(providerChange);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {
                billing.providers.map((provider, i) => (
                  <MenuItem key={i} value={provider}>{providersLabels[provider]}</MenuItem>
                ))
              }
            </TextField>
          )
        }
        <TextField
          select
          label='Date range'
          className={classes.input}
          variant='outlined'
          value={settingsControl.selectedDate}
          onChange={(e) => handleSettingsControl('selectedDate', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value='7days'>Last 7 days</MenuItem>
          <MenuItem value='30days'>Last 30 days</MenuItem>
          <MenuItem value='12months'>Last 12 months</MenuItem>
          <MenuItem value='CustomizedPeriod'>Customized Period</MenuItem>
        </TextField>

        {
          settingsControl.selectedDate === 'CustomizedPeriod' && (
            <Box>
              <TextField
                variant='outlined'
                label='Start Date'
                className={classes.input}
                value={settingsControl.startDate}
                onChange={(e) => handleSettingsControl('startDate', e.target.value)}
                format='yyyy-MM-dd'
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                label='End Date'
                variant='outlined'
                className={classes.input}
                value={settingsControl.endDate}
                onChange={(e) => handleSettingsControl('endDate', e.target.value)}
                format='yyyy-MM-dd'
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          )
        }

        {
          rbac('gcp_*') && settingsControl.provider === 'gcp' && (
            renderGcpControls()
          )
        }

        <FormControlLabel
          className={classes.checkInput}
          control={(
            <Checkbox
              checked={selectedControl.showSpendSum}
              onChange={(e) => handleSelectedControl('showSpendSum', e.target.checked)}
              name='showSpendSum'
            />
          )}
          label='Show spend sum'
        />
      </div>
      <div className={classes.footer}>
        <Button
          backgroundColor={colors.darkGray}
          onClick={() => setControlContext()}
          disabled={
            (settingsControl.groupBy === 'projectLabel' && !settingsControl.projectLabel)
            || (settingsControl.groupBy === 'resourceLabel' && !settingsControl.resourceLabel)
          }
        >
          Save
        </Button>
        <Divider className={classes.divider} />
        <Button
          onClick={() => setControlContext(true)}
        >
          Reset to default
        </Button>
      </div>
    </div>
  );
};

Controls.propTypes = {
  close: PropTypes.func.isRequired
};

export default Controls;
