import React, { useContext, useState } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Context } from '../../../components/ContextProvider';
import { detailsProjectExport, detailsServiceExport } from '../../../api/billing';
import Dialog from '../../../components/Dialog';
import colors from '../../../helpers/colors';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { groupByOptions, settingsDateList } from '../../../helpers/settings';
import { dateRange } from '../../../helpers/date';
import rbac from '../../../helpers/rbac';

const ExportCsv = () => {
  const [csvData, setCsvData] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    settings,
    selectedCustomer,
    billingAccountId,
    projectLabels
  } = useContext(Context);

  if (
    settings.groupBy === 'resourceLabel'
    || !rbac('export_*')
  ) {
    return null;
  }

  const billingDataSelector = () => {
    switch (settings.groupBy) {
    case 'projectLabel':
      return detailsProjectExport(
        selectedCustomer,
        billingAccountId,
        settings,
        settings.projectLabel || projectLabels[0]
      );
    default:
      return detailsServiceExport(selectedCustomer, settings);
    }
  };

  const exportToCsv = () => {
    billingDataSelector().then((data) => {
      setCsvData(data);
    });
  };

  const fileName = `cc_${settings.provider}_billing_data_${dateRange(settings)}.csv`;

  return (
    <>
      <Box
        style={{
          marginRight: 5,
          cursor: 'pointer'
        }}
        onClick={() => {
          setOpen(true);
          exportToCsv();
        }}
      >
        <Tooltip title='Export Data'>
          <GetAppIcon />
        </Tooltip>
      </Box>

      <Dialog
        title='Export Billing Data'
        open={open}
        onClose={() => {
          setOpen(false);
          setCsvData(null);
        }}
      >
        {
          csvData && csvData.rows && csvData.headers ? (
            <>
              <Typography gutterBottom variant='h6'>
                Download your billing data here:
              </Typography>
              <Typography>
                {`Group By: ${groupByOptions[settings.groupBy]}`}
              </Typography>
              <Typography>
                {`Date Range: ${settingsDateList[settings.selectedDate]}`}
              </Typography>
              <Typography>
                {
                  settings.selectedDate === 'CustomizedPeriod' && (
                    `From: ${settings.startDate} To: ${settings.endDate}`
                  )
                }
              </Typography>

              <CSVLink
                data={csvData.rows}
                headers={csvData.headers}
                target='_self'
                filename={fileName}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    color: colors.poppy,
                    marginTop: 10,
                    marginBottom: 10,
                    marginLeft: -4,
                  }}
                  onClick={() => setTimeout(() => {
                    setOpen(false);
                    setCsvData(null);
                  }, 1000)}
                >
                  <GetAppIcon />
                  {fileName}
                </Box>
              </CSVLink>
            </>
          ) : <LoadingIndicator minHeight={150} />
        }
      </Dialog>
    </>
  );
};

export default ExportCsv;
