import React from 'react';
import clsx from 'clsx';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box, Drawer, AppBar, Toolbar, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import logoWhite from '../../assets/images/logoWhite.svg';
import colors from '../../helpers/colors';
import RightToolbar from './RightToolbar';
import Menu from './Menu';
import Footer from './Footer';

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: colors.darkGray
  },
  toolbar: {
    minWidth: 1024
  },
  menuButton: {
    marginRight: 12,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    backgroundColor: 'white',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  paper: {
    backgroundColor: 'whitesmoke',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 30
  },
  barImg: {
    paddingTop: 5,
    float: 'right'
  },
  menu: {
    paddingLeft: 20
  }
}));

export default function Layout() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(location.pathname);

  const handleDrawer = (isOpen = true) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(isOpen);
  };

  return (
    <>
      <AppBar
        position='fixed'
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>

          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={() => handleDrawer(!open)}
            edge='start'
            className={clsx(classes.menuButton, open)}
          >
            <MenuIcon />
          </IconButton>

          <Box
            style={{ flex: 1 }}
            onClick={() => {
              setSelectedIndex('/');
              history.push('/');
            }}
          >
            <img
              src={logoWhite}
              style={{
                padding: 10,
                paddingLeft: 0,
                float: 'left',
                height: 60,
                cursor: 'pointer'
              }}
              alt='Logo'
            />
          </Box>

          <RightToolbar />

        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant='temporary'
        anchor='left'
        open={open}
        onClose={() => handleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}
      >
        <Menu
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          close={() => handleDrawer(false)}
        />

        <Footer />
      </Drawer>
    </>
  );
}
