import React, { useContext } from 'react';
import {
  Grid, Paper
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SpendSum from './SpendSum';
import BarChart from '../../../components/BarChart';
import { Context } from '../../../components/ContextProvider';

const Chart = ({ title, data }) => {
  const { selected } = useContext(Context);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={selected.showSpendSum ? 10 : 12}>
        <Paper square elevation={0}>
          <BarChart
            title={title}
            keys={data.keys}
            chartData={data.data}
            colors={data.colors}
            maxValue={data.max_value}
          />
        </Paper>
      </Grid>
      {
        selected.showSpendSum && (
          <Grid item xs={12} md={2}>
            <SpendSum summary={data.summary} />
          </Grid>
        )
      }
    </Grid>
  );
};

Chart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Chart;
