import React, { useContext } from 'react';
import { Context } from '../../../../components/ContextProvider';
import Tabs from '../../../../components/Tabs';
import ChartTotalService from './ChartTotalService';
import ChartProjectServices from './ChartProjectService';

const ServiceAndProject = () => {
  const { settings, billing } = useContext(Context);

  const labels = () => {
    if (settings.provider === 'gcp') {
      return [
        'Total (with Service)',
        'Project (with Service)'
      ];
    }

    return ['Total'];
  };

  return billing && billing.services && (
    <Tabs
      transparent
      labels={labels()}
      content={[
        <ChartTotalService />,
        <ChartProjectServices />
      ]}
    />
  );
};

export default ServiceAndProject;
