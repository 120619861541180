import React, { useState, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import {
  Box, Grid, Paper, MenuItem, Typography
} from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { form } from '../../helpers/styles';
import { saveNewTicket, signedUrlAttachment } from '../../api/support';
import { saveFile } from '../../api/files';
import { Context } from '../../components/ContextProvider';
import { jiraLimits, prioritylist } from '../../helpers/support';
import TitleBar from '../../components/TitleBar';
import SubmitGroup from '../../components/Button/SubmitGroup';

const useStyles = makeStyles(() => (form));

const New = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { selectedCustomer, user } = useContext(Context);

  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState({
    summary: location.state ? location.state.summary : '',
    description: location.state ? location.state.description : '',
    priority: ''
  });

  const back = () => {
    history.push('/supportrequest');
  };

  const handleChange = (key, input) => {
    const newValue = {
      ...value,
      [key]: input
    };

    setValue(newValue);
  };

  const saveTicket = (attachments) => {
    setUpdateIndicator(true);

    const newTicket = {
      summary: value.summary,
      creation_date: new Date().toISOString().toString(),
      creation_by: user.claims.email,
      description: value.description,
      priority: value.priority,
      customer: selectedCustomer,
      attachments,
    };

    saveNewTicket(newTicket).then(() => {
      back();
    }).finally(() => setUpdateIndicator(false));
  };

  const handleSubmit = () => {
    const attachmentFiles = [];

    files.forEach((file) => {
      attachmentFiles.push(
        new Promise((resolveFile) => {
          signedUrlAttachment(file.file.name).then((data) => {
            saveFile(data.signed_url, file);
            resolveFile(data.generated_name);
          });
        })
      );
    });

    Promise.all(attachmentFiles).then((attachments) => saveTicket(attachments));
  };

  return (
    <ValidatorForm
      ref={useRef('form')}
      onSubmit={() => handleSubmit()}
    >
      <TitleBar
        title='Rapid Architecture Ticket'
        updateIndicator={updateIndicator}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            className={classes.paper}
            style={{ height: '100%' }}
          >
            <Box mb={2}>
              <Typography variant='h6'>
                New Ticket
              </Typography>
            </Box>
            <TextValidator
              className={classes.textField}
              fullWidth
              label='Summary'
              variant='outlined'
              onChange={(event) => handleChange('summary', event.target.value)}
              name='summary'
              value={value.summary}
              validators={['required']}
              errorMessages={['This field is required']}
              helperText={`The summary cannot be longer than ${jiraLimits.summary} characters.`}
              inputProps={{
                maxLength: jiraLimits.summary,
              }}
            />

            <TextValidator
              className={classes.textField}
              select
              fullWidth
              label='Priority'
              variant='outlined'
              onChange={(event) => handleChange('priority', event.target.value)}
              name='priority'
              value={value.priority}
              validators={['required']}
              errorMessages={['This field is required']}
            >
              {Object.keys(prioritylist.detailed_priorities).map((key) => (
                <MenuItem key={key} value={prioritylist.detailed_priorities[key].name}>
                  {prioritylist.detailed_priorities[key].description}
                </MenuItem>
              ))}
            </TextValidator>

            <TextValidator
              className={classes.textField}
              fullWidth
              label='Description'
              variant='outlined'
              onChange={(event) => handleChange('description', event.target.value)}
              name='description'
              multiline
              minRows={4}
              maxRows={Infinity}
              value={value.description}
              validators={['required']}
              errorMessages={['This field is required']}
              helperText={`The description cannot be longer than ${jiraLimits.description} characters.`}
              inputProps={{
                maxLength: jiraLimits.description,
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            className={classes.paper}
            style={{ height: '100%' }}
          >
            <DropzoneAreaBase
              dropzoneClass='drag-n-drop'
              fileObjects={files}
              Icon={AttachFile}
              showPreviewsInDropzone
              showFileNames
              filesLimit={10}
              onAdd={(file) => setFiles(files.concat(file))}
              onDelete={(file) => setFiles(files.filter((el) => el !== file))}
              showPreviews={false}
              alertSnackbarProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                }
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <SubmitGroup
        cancel={() => back()}
        updateIndicator={updateIndicator}
      />

    </ValidatorForm>
  );
};

export default New;
