import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton } from '@material-ui/core';
import colors from '../../helpers/colors';

const Button = ({
  disabled,
  type,
  color,
  backgroundColor,
  onClick,
  buttonIcon,
  children,
  size
}) => (
  <MaterialButton
    size={size}
    variant='contained'
    color={color}
    disabled={disabled}
    type={type}
    onClick={onClick ? () => onClick() : undefined}
    startIcon={buttonIcon || undefined}
    style={{
      height: '50px',
      width: '100%',
      backgroundColor: !disabled ? backgroundColor : ''
    }}
  >
    {children}
  </MaterialButton>
);

Button.defaultProps = {
  disabled: false,
  type: 'button',
  color: 'primary',
  size: 'large',
  backgroundColor: colors.poppy,
  onClick: null,
  buttonIcon: null
};

Button.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  buttonIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Button;
