import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper, TableContainer, Typography, TableCell, TableRow, TableBody, TableHead, Table, Grid, Box
} from '@material-ui/core';

const ConfirmDialog = ({
  message,
  users,
}) => (
  <Box pb={2}>
    <Grid container spacing={2} direction='column'>
      <Grid item>
        <Typography variant='subtitle2'>
          {message}
        </Typography>
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table aria-label='user-role'>
            <TableHead>
              <TableRow>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Role
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.role}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Box>
);

ConfirmDialog.defaultProps = {
  message: ''
};

ConfirmDialog.propTypes = {
  message: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ConfirmDialog;
