import React, {
  useState, useEffect, useContext, lazy, Suspense
} from 'react';
import { Box } from '@material-ui/core';
import { Context } from '../../../../components/ContextProvider';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { aggregatedBy } from '../../../../api/billing';
import Discount from '../Discount';
import { cacheName } from '../../../../helpers/utils';
import { loadingStyles } from '../../../../helpers/styles';

const Chart = lazy(() => import('../Chart'));

const ChartTotalService = () => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    customerData, selectedCustomer, settings, chartTotalService, updateContext,
    billingAccountId, billing
  } = useContext(Context);

  const { services } = billing;

  const cache = cacheName(`${selectedCustomer}-${billingAccountId}`, settings);

  const getTotalData = () => {
    if (services) {
      aggregatedBy(
        selectedCustomer,
        billingAccountId,
        settings,
        services,
        'by_service',
      ).then((response) => {
        updateContext({
          chartTotalService: {
            ...chartTotalService,
            services: settings.services,
            [cache]: response
          }
        });
      });
    }
  };

  useEffect(() => {
    if (
      chartTotalService
      && chartTotalService[cache]
      && chartTotalService.services === settings.services
    ) {
      setIsLoading(false);
    } else {
      getTotalData();
    }
  }, [billingAccountId]);

  const discounts = () => {
    const data = chartTotalService[cache];

    if (data.discount && data.discount.length > 0) {
      return (
        <Discount
          selectedDate={settings.selectedDate}
          data={data.discount}
          customer={customerData}
        />
      );
    }

    return null;
  };

  return (
    isLoading ? <LoadingIndicator /> : (
      <>
        {discounts()}
        <Suspense fallback={<Box style={loadingStyles} />}>
          <Chart
            title=''
            data={chartTotalService[cache]}
          />
        </Suspense>
      </>
    )
  );
};

export default ChartTotalService;
