import log from './log';

const storage = {
  set: async (key, data) => {
    try {
      await localStorage.setItem(key, JSON.stringify(data));
    } catch (err) {
      log(`ERROR ON SET: ${err}`);
    }
  },
  get: (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (err) {
      log(`ERROR ON GET: ${err}`);
      return err;
    }
  },
  clear: async () => {
    try {
      return await localStorage.clear();
    } catch (err) {
      log(`ERROR ON CLEAR: ${err}`);
      return err;
    }
  }
};

export default storage;
