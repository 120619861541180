import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import AddBox from '../../components/Table/AddBox';

const EntityTable = ({ entities }) => {
  const history = useHistory();

  const newEntity = () => {
    history.push('/entitymanagement/new');
  };

  const updateEntity = (id) => {
    history.push(`/entitymanagement/update/${id}`);
  };

  /**
   * Users List
   */
  const renderUsers = (value) => {
    if (Array.isArray(value) && value.length) {
      const list = (ul, color = '') => (ul && ul.length ? (
        <ul>
          {ul.map((user) => (
            <li
              key={`id:${user}`}
              style={{
                listStyleType: 'square',
                padding: 2,
                color
              }}
            >
              {user}
            </li>
          ))}
        </ul>
      ) : null);

      return list(value.sort());
    }

    return (
      <Typography>
        No Users
      </Typography>
    );
  };

  return (
    <Table
      title='Entities'
      data={entities}
      columns={[
        {
          title: 'Name',
          field: 'name',
          removable: false
        },
        {
          field: 'admin_email',
          title: 'Admin Email',
          filtering: false,
          sorting: false,
          render: (row) => renderUsers(row.admins),
          removable: false
        }
      ]}
      actions={[
        {
          icon: (() => <AddBox title='New' />),
          onClick: () => newEntity(),
          isFreeAction: true
        }
      ]}
      options={{
        filtering: true,
        columnsButton: true
      }}
      onRowClick={(evt, rowData) => updateEntity(rowData.id)}
    />
  );
};

EntityTable.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default EntityTable;
