import React, {
  useEffect, useState, useContext
} from 'react';
import TitleBar from '../../components/TitleBar';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import firebaseApp from '../../firebase/Base';
import UsersTable from './UsersTable';

const UserManagement = () => {
  const { customers, entities } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [data, setData] = useState(null);

  let listener = null;

  /**
   * Move to management api
   */
  const usersData = () => {
    firebaseApp.firestore().collection('customers').get().then((customersSnap) => {
      const customersArray = [];

      customersSnap.forEach((doc) => {
        customersArray.push({ ...doc.data(), id: doc.id });
      });

      listener = firebaseApp.firestore().collection('users').onSnapshot((usersSnap) => {
        const usersArray = [];

        usersSnap.forEach((doc) => {
          const userCostumers = [];

          customersArray.forEach((customer) => {
            if (customer.users && customer.users.includes(doc.id)) {
              userCostumers.push({
                id: customer.id,
                name: customer.customer_name,
                entity: customer.entity
              });
            }
          });

          usersArray.push({
            ...doc.data(),
            id: doc.id,
            customers: userCostumers
          });
        });

        setData({
          users: usersArray,
          customers: customersArray,
          entities
        });

        setIsLoading(false);
      });
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    usersData();

    return () => listener();
  }, [customers]);

  return (
    <>
      <TitleBar
        title='User Management'
        updateIndicator={updateIndicator}
      />
      {
        isLoading ? <LoadingIndicator /> : (
          <UsersTable
            data={data}
            setData={setData}
            setUpdateIndicator={(value) => setUpdateIndicator(value)}
          />
        )
      }
    </>
  );
};

export default UserManagement;
