import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import TitleBar from '../../../components/TitleBar';
import ServiceAndProject from './ServiceAndProject';
import ProjectLabel from './ProjectLabel';
import ResourceLabel from './ResourceLabel';
import { Context } from '../../../components/ContextProvider';
import { currentTimeZone } from '../../../helpers/date';
import colors from '../../../helpers/colors';
import { settingsDateList } from '../../../helpers/settings';
import Settings from './Settings';
import { settingsServicesAndLabels } from '../../../api/billing';
import { objectEquals } from '../../../helpers/utils';
import ExportCsv from './ExportCsv';
import BillingAlerts from './BillingAlerts';
import BillingViewers from './BillingViewers';

const useStyles = makeStyles(() => ({
  right: {
    width: '40%',
    float: 'right',
    color: colors.neutralGray
  },
  label: {
    fontWeight: 'normal',
    fontSize: '9pt',
    textAlign: 'right',
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const {
    customerData,
    selectedCustomer,
    billingAccounts,
    billingAccountId,
    updateContext,
    settings,
    billing,
    user
  } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);

  const billingSettings = () => {
    settingsServicesAndLabels(selectedCustomer, billingAccountId).then((response) => {
      if (!objectEquals(billing, response)) {
        updateContext({
          billing: response,
        });
      }
    }).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    billingSettings();
  }, [selectedCustomer, billingAccountId]);

  const tabSelector = () => {
    switch (settings.groupBy) {
    case 'projectLabel':
      return <ProjectLabel />;
    case 'resourceLabel':
      return <ResourceLabel />;
    default:
      return <ServiceAndProject />;
    }
  };

  /**
   * Billing Viewer filters
   */
  const claimsFitersValidation = () => {
    const { claims } = user;

    if (claims.role === 'customerViewer') {
      if (
        !claims.filters
        || claims.filters.length < 1
        || claims.filters.find((f) => f.customer === selectedCustomer) === undefined
      ) {
        return (
          <Typography>
            Please contact your system administrator
          </Typography>
        );
      }

      return <ProjectLabel />;
    }

    return tabSelector();
  };

  /**
   * Remove this validation when billing_account_id is added to Alerts, Filters and Export
   */
  const defaultBillingIdValidation = () => billingAccountId === billingAccounts[0] && (
    <>
      {
        customerData.billing_viewer && (
          <BillingViewers />
        )
      }
      <BillingAlerts />
      <ExportCsv />
    </>
  );

  return (
    <>
      <TitleBar
        title='Billing Dashboard'
        subTitle='Standard'
        updateIndicator={isLoading}
      >
        {
          !isLoading && (
            <>
              {
                settings.provider === 'gcp' && defaultBillingIdValidation()
              }
              <Settings />
            </>
          )
        }
      </TitleBar>
      {
        !isLoading && (
          <>
            <Box className={classes.right}>
              <Typography className={classes.label}>
                {`Time zone: US Pacific (current time : ${currentTimeZone()})`}
              </Typography>
              <Typography className={classes.label}>
                {`Date Range: ${settingsDateList[settings.selectedDate]}`}
              </Typography>
              <Typography className={classes.label}>
                {`Currency: ${customerData.billing_currency || 'EUR'}`}
              </Typography>
            </Box>

            {claimsFitersValidation()}
          </>
        )
      }
    </>
  );
};

export default Dashboard;
