import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import image from '../../assets/images/error.png';
import colors from '../../helpers/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 100,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 200
  },
  subtitle: {
    marginTop: 20,
    color: colors.poppy
  }
}));

const FallBack = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent='center'
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant='h3'>
              Something went wrong
            </Typography>
            <Typography variant='subtitle2' className={classes.subtitle}>
              Please try again...
            </Typography>
            <img
              alt='Under development'
              className={classes.image}
              src={image}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FallBack;
