const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10
  },
  imagesBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageStyle: {
    marginBottom: 35,
    height: 80,
    width: null,
  },
  powered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  smallLogo: {
    margin: 10,
    marginTop: 18
  }
};

export default styles;
