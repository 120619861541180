import MuiList from './MuiList';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiInputLabel from './MuiInputLabel';
import MuiButton from './MuiButton';
import MuiPaper from './MuiPaper';
import MuiTypography from './MuiTypography';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiAutocomplete from './MuiAutocomplete';

export default {
  MuiList,
  MuiButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTypography,
  MuiOutlinedInput,
  MuiInputLabel,
  MuiAutocomplete,
};
