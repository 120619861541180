import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import colors from '../../helpers/colors';

const useStyles = makeStyles(() => ({
  mtop: {
    marginTop: 10
  },
  btnContainer: {
    textAlign: 'right',
    padding: '0px !important'
  },
  btnSave: {
    color: colors.poppy,
    height: 30,
    width: 80,
    fontWeight: 700
  },
  btnReset: {
    color: colors.neutralGray,
    width: 80,
    fontWeight: 700
  }
}));

function SaveReset({
  updateIndicator,
  save,
  reset
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      className={classes.mtop}
    >
      <Grid item xs={12} className={classes.btnContainer}>
        <Button
          className={classes.btnSave}
          size='small'
          disabled={updateIndicator}
          onClick={save ? () => save() : undefined}
          type={save ? 'button' : 'submit'}
        >
          SAVE
        </Button>
        <Button
          className={classes.btnReset}
          size='small'
          onClick={() => reset()}
          disabled={updateIndicator}
        >
          RESET
        </Button>
      </Grid>
    </Grid>
  );
}

SaveReset.defaultProps = {
  save: null
};

SaveReset.propTypes = {
  save: PropTypes.func,
  reset: PropTypes.func.isRequired,
  updateIndicator: PropTypes.bool.isRequired,
};

export default SaveReset;
