import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const {
  REACT_APP_APIKEY,
  REACT_APP_AUTHDOMAIN,
  REACT_APP_DATABASEURL,
  REACT_APP_PROJECTID,
  REACT_APP_STORAGEBUCKET,
  REACT_APP_MESSAGINGSENDERID,
  REACT_APP_APPID,
  REACT_APP_MEASUREMENTID,
} = process.env;

const config = {
  apiKey: REACT_APP_APIKEY,
  authDomain: REACT_APP_AUTHDOMAIN,
  databaseURL: REACT_APP_DATABASEURL,
  projectId: REACT_APP_PROJECTID,
  storageBucket: REACT_APP_STORAGEBUCKET,
  messagingSenderId: REACT_APP_MESSAGINGSENDERID,
  appId: REACT_APP_APPID,
  measurementId: REACT_APP_MEASUREMENTID,
};

export const arrayToUpdate = (value) => firebase.firestore.FieldValue.arrayUnion(value);
export const arrayToRemove = (value) => firebase.firestore.FieldValue.arrayRemove(value);
export const fieldToRemove = () => firebase.firestore.FieldValue.delete();

export const provider = new firebase.auth.GoogleAuthProvider();

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
