import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AddBox from '../../components/Table/AddBox';
import colors from '../../helpers/colors';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    '& .MuiIconButton-colorInherit': {
      color: colors.poppy
    },
    '& .MuiTableBody-root': {
      '& .MuiButtonBase-root': {
        paddingRight: 10,
      },
      paddingRight: 20,
    },
    '& .MuiIcon-root': {
      // padding: 6,
      color: colors.poppy
    },
    '& .MuiToolbar-root': {
      padding: 16,
      paddingRight: 6
    },
    '& .MTableToolbar-searchField': {
      paddingRight: 10
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.poppy,
    }
  },
  title: {
    color: colors.poppy
  }
}));

const WorkspaceLicenseTable = ({
  columns, data, setSelectedLicense, toggleCreateLicense,
  options,
  components,
  pushUrl,
  detailPanel
}) => {
  const classes = useStyles();
  const history = useHistory();

  const styles = {
    headerStyle: {
      fontWeight: 'bold',
      color: colors.gray,
      backgroundColor: colors.pink
    },
    searchFieldStyle: {
      span: { color: colors.poppy }
    }
  };

  const sortLicenseData = (license) => {
    const { tableData, id, ...tempLicense } = license; // Remove tableData and tableId
    setSelectedLicense(tempLicense);
  };

  const localization = {
    header: {
      actions: ''
    },
  };

  const renderTitle = (
    <Typography variant='h6' className={classes.title}>
      Workspace License
    </Typography>
  );

  const tableOptions = {
    actionsColumnIndex: -1,
    tableLayout: 'auto',
    filtering: false,
    sorting: true,
    draggable: false,
    exportButton: false,
    pageSize: 10,
    pageSizeOptions: [10, 50, 100],
    emptyRowsWhenPaging: false,
    ...styles,
    ...options
  };

  return (

    <MaterialTable
      title={renderTitle}
      data={data}
      columns={columns}
      options={tableOptions}
      actions={[
        {
          icon: (() => <AddBox title='Create License Configuration' />),
          onClick: () => toggleCreateLicense(),
          isFreeAction: true
        }
      ]}
      onRowClick={(evt, rowData) => {
        sortLicenseData(rowData);
        setSelectedLicense(rowData);
        history.push(pushUrl, rowData);
      }}
      components={{
        ...components,
        Container: (props) => <Paper {...props} elevation={1} />,
      }}
      localization={localization}
      detailPanel={detailPanel}
    />

  );
};

WorkspaceLicenseTable.defaultProps = {
  toggleCreateLicense: PropTypes.null,
  detailPanel: null,
  options: {},
  components: {},
};

WorkspaceLicenseTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedLicense: PropTypes.func.isRequired,
  pushUrl: PropTypes.string.isRequired,
  toggleCreateLicense: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.any),
  components: PropTypes.objectOf(PropTypes.any),
  detailPanel: PropTypes.arrayOf(PropTypes.any),
};

export default WorkspaceLicenseTable;
