import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, } from 'react-router-dom';
import {
  Typography, List, ListItem, ListItemIcon, ListItemText, Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BuildIcon from '@material-ui/icons/Build';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import colors from '../../helpers/colors';
import rbac, { hasAccess } from '../../helpers/rbac';
import { Context } from '../ContextProvider';

const useStyles = makeStyles(() => ({
  title: {
    color: colors.poppy,
    padding: 4
  },
  divider: {
    backgroundColor: 'whitesmoke'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: colors.poppy,
    cursor: 'pointer'
  }
}));

const Menu = ({
  selectedIndex,
  setSelectedIndex,
  close
}) => {
  const classes = useStyles();

  const { user } = useContext(Context);

  const roleBasedItems = () => {
    const roleBased = [];

    if (hasAccess('anomalyDetection')) {
      roleBased.push({
        label: 'Anomaly Detection',
        index: '/anomalydetection',
        icon: <VerticalAlignBottomIcon />,
      });
    }

    if (hasAccess('systemHealth')) {
      roleBased.push({
        label: 'System Health',
        index: '/systemhealth',
        icon: <DashboardIcon />
      });
    }

    if (hasAccess('workspaceLicense')) {
      roleBased.push({
        label: 'Workspace License',
        index: '/workspacelicense',
        icon: <ReceiptIcon />
      });
    }

    if (hasAccess('inventory')) {
      roleBased.push({
        label: 'Inventory',
        index: '/inventory',
        icon: <AssessmentIcon />
      });
    }

    if (hasAccess('projectProvisioning')) {
      roleBased.push({
        label: 'Project Provisioning',
        index: '/projectprovisioning',
        icon: <BuildIcon />
      });
    }

    if (hasAccess('support')) {
      roleBased.push({
        label: 'Rapid Architecture Ticket',
        index: '/supportrequest',
        icon: <ContactSupportIcon />
      });
    }

    if (hasAccess('documentation')) {
      roleBased.push({
        label: 'Playbook',
        index: '/playbook',
        icon: <LibraryBooksIcon />
      });
    }

    if (rbac('entitymanagement_read')) {
      roleBased.push({
        label: 'Entity Management',
        index: '/entitymanagement',
        icon: <BusinessIcon />
      });
    }

    if (rbac('customermanagement_read') && user.claims.role === 'customer') {
      roleBased.push({
        label: 'Customer Settings',
        index: '/customermanagement/view',
        icon: <SettingsIcon />
      });
    }

    if (rbac('customermanagement_read') && user.claims.role === 'admin') {
      roleBased.push({
        label: 'Customer Management',
        index: '/customermanagement',
        icon: <SettingsIcon />
      });
    }

    if (rbac('usermanagement_read')) {
      roleBased.push({
        label: 'User Management',
        index: '/usermanagement',
        icon: <SupervisorAccountIcon />
      });
    }

    return roleBased;
  };

  const items = [
    {
      label: 'Billing',
      index: '/',
      icon: <CreditCardIcon />
    },
    ...roleBasedItems()
  ];

  return (
    <List>
      <ListItem>
        <ListItemText
          primary={(
            <Typography variant='h5' className={classes.title}>
              Command Center
            </Typography>
          )}
        />
        <ListItemIcon
          className={classes.closeIcon}
          onClick={() => close()}
        >
          <CloseIcon />
        </ListItemIcon>
      </ListItem>
      <Divider className={classes.divider} />

      {
        items.map((item) => (
          <div key={`div-${item.index}`}>
            {
              item.label === 'Entity Management' && (
                <Divider className={classes.divider} />
              )
            }
            <ListItem
              button
              key={item.index}
              selected={selectedIndex === item.index}
              onClick={() => {
                setSelectedIndex(item.index);
                close();
              }}
              component={Link}
              to={item.index}
            >
              <ListItemIcon style={{ paddingLeft: 8 }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          </div>
        ))
      }
    </List>
  );
};

Menu.propTypes = {
  selectedIndex: PropTypes.string.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default Menu;
