import React, { useEffect, useState } from 'react';
import TitleBar from '../../components/TitleBar';
import LoadingIndicator from '../../components/LoadingIndicator';
import firebaseApp from '../../firebase/Base';
import EntityTable from './EntityTable';

const EntityManagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  /**
   * Move to management api
   */
  const entitiesData = () => {
    firebaseApp.firestore()
      .collection('entities')
      .orderBy('name', 'asc')
      .get()
      .then((snap) => {
        const entities = [];

        snap.forEach((doc) => {
          entities.push({ ...doc.data(), id: doc.id });
        });

        setData(entities);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    entitiesData();
  }, []);

  return (
    <>
      <TitleBar title='Entity Management' />
      {
        isLoading ? <LoadingIndicator /> : (
          <EntityTable entities={data} />
        )
      }
    </>
  );
};

export default EntityManagement;
