import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebaseApp from '../../firebase/Base';
import DotLoader from '../LoadingIndicator/DotLoader';
import log from '../../helpers/log';
import { settings, selected } from '../../helpers/settings';
import { getCustomerList } from '../../api/customers';
import { selectedCustomers } from '../../helpers/customers';
import { entityList } from '../../firebase/requests';
import storage from '../../helpers/storage';

export const Context = React.createContext();
export default class ContextProvider extends Component {
  constructor() {
    super();

    this.state = {
      selectedCustomer: '',
      billingAccountId: '',
      settings: settings(),
      selected: selected(),
      isLoading: true,
      user: null,
      entities: null,
      customers: null,
      customerData: null,
      billing: null,
      billingAccounts: null,
    };

    this.listener = null;
    this.refreshAuth = null;
    this.refreshTimer = 60000 * 59;
  }

  componentDidMount() {
    this.setUser();
  }

  componentWillUnmount() {
    clearInterval(this.refreshAuth);
    this.listener();
  }

  /**
   * Set Firebase User auth()
   */
  setUser = async () => {
    const { user } = this.state;

    this.listener = firebaseApp.auth().onAuthStateChanged((auth) => {
      if (auth != null) {
        auth.getIdTokenResult(true).then((authUser) => {
          storage.set('token', authUser.token);

          if (!user) {
            this.setUserContext(authUser);
          }
        }).finally(() => {
          this.refreshAuth = setInterval(
            () => this.setUser(), this.refreshTimer
          );
        });
      } else {
        this.stopLoading();
      }
    });
  };

  /**
   * Set User Context State
   * Move to management api
   */
  setUserContext(user) {
    // Customers
    getCustomerList().then((customers) => {
      const {
        selectedCustomer,
        customerData,
        billingAccounts,
        billingAccountId
      } = selectedCustomers(customers);
      // Entities
      entityList().then((entities) => {
        this.setState({
          isLoading: false,
          billingAccountId,
          selectedCustomer,
          billingAccounts,
          customerData,
          customers,
          entities,
          user
        });
      });
    }).catch((error) => {
      log.error(error);
      this.stopLoading();
    });
  }

  /**
   * Update App Context
   */
  updateContext = (state) => {
    this.setState(state);
  };

  /**
   * Clear saved Context
   */
   clearContex = () => {
     storage.clear();
     location.reload();
   };

   /**
    * Stop Loading
    */
   stopLoading() {
     this.setState({
       isLoading: false
     });
   }

   render() {
     const { children } = this.props;
     const { state, updateContext, clearContex } = this;

     log.data('APP CONTEXT', state);

     if (state.isLoading) {
       return (
         <DotLoader />
       );
     }

     return (
       <Context.Provider
         value={{
           ...state,
           updateContext,
           clearContex
         }}
       >
         {children}
       </Context.Provider>
     );
   }
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
