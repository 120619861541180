import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Typography, Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../helpers/colors';
import { numberFormat } from '../../../helpers/utils';

const useStyles = makeStyles(() => ({
  paper: {
    textAlign: 'left',
    padding: 24
  },
  divider: {
    backgroundColor: colors.background,
    margin: 4
  },
  sum: {
    fontSize: 16,
    fontWeight: 700,
    color: colors.darkGray,
  },
  title: {
    color: colors.darkGray,
    fontSize: 14,
    fontWeight: 600,
  },
  subTitle: {
    color: colors.neutralGray,
    fontSize: 14,
    fontWeight: 400,
  }
}));

const SpendSum = ({ summary }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} square elevation={0}>

      <Typography className={classes.sum}>Spend Sum</Typography>
      <Divider className={classes.divider} />

      <Typography className={classes.title}>Selected period</Typography>
      <Typography className={classes.subTitle}>
        {numberFormat(summary.custom)}
      </Typography>
      <Divider className={classes.divider} />

      <Typography className={classes.title}>Month-to-date</Typography>
      <Typography className={classes.subTitle}>
        {numberFormat(summary['month-to-date'])}
      </Typography>
      <Divider className={classes.divider} />

      <Typography className={classes.title}>Previous month</Typography>
      <Typography className={classes.subTitle}>
        {numberFormat(summary['previous-month'])}
      </Typography>

    </Paper>
  );
};

SpendSum.propTypes = {
  summary: PropTypes.objectOf(PropTypes.number).isRequired
};

export default SpendSum;
