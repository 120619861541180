import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const SnackBar = ({
  open,
  onClose,
  message,
  severity,
  autoHideDuration
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
      >
        <Alert
          onClose={onClose}
          severity={severity}
          elevation={6}
          variant='filled'
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

SnackBar.defaultProps = {
  message: '',
  autoHideDuration: 3000,
  severity: 'success',
  onClose: null
};

SnackBar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func,
  severity: PropTypes.string,
  autoHideDuration: PropTypes.number,
};

export const defaultSnackState = {
  open: false,
  message: '',
  severity: 'success',
  onClose: null
};

export const defaultErrorSnackState = {
  ...defaultSnackState,
  severity: 'error',
};

export default SnackBar;
