/**
 * Represents a search trough an array.
 * @function search
 * @param {Array} array - The array you wanna search trough
 * @param {string} value - The value to search for
 * @param {string} prop - The property to find it in
 */
export const arraySearch = (array, value, prop) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][prop] === value) {
      return array[i];
    }
  }

  return false;
};

/**
 * Email Validator
 */
export const emailIsValid = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

/**
 * Array equals compare lenght and element
 */
export const arrayEquals = (array1: Array<any>, array2: Array<any>) => (
  Array.isArray(array1)
  && Array.isArray(array2)
  && array1.length === array2.length
  && array1.every((element, index) => element === array2[index])
);

/**
 * Fast JSON object Compare
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns boolean
 */
export const objectEquals = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

/**
 * Cache name based on customer and settings
 * @param {string} selectedCustomer
 * @param {Object} settings
 * @returns string
 */
export const cacheName = (
  customerBilling,
  settings
) => {
  let cache = `${customerBilling}-${settings.provider}-${settings.selectedDate}`;

  if (settings.selectedDate === 'CustomizedPeriod') {
    cache += `-${settings.startDate}-${settings.endDate}`;
  }

  return cache;
};

/**
 * Sort Array of Objects passing a key
 */
export const sortArray = (items, key) => items.sort((a, b) => a[key].localeCompare(b[key]));

/**
 * Number Format
 * @param {number} number
 * @param {number} fractionDigits
 * @returns string
 */
export const numberFormat = (number, fractionDigits = 2) => Number(number).toLocaleString('en-EN', {
  minimumFractionDigits: fractionDigits,
});

/**
 * Validate Global path
 * @param {string} pathname
 * @returns boolean
 */
export const validateGlobal = (pathname) => {
  const global = ['entitymanagement', 'customermanagement', 'usermanagement'];
  const isGlobal = global.findIndex((g) => pathname.indexOf(g) > -1) > -1;

  return isGlobal;
};
