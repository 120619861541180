import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  TableCell,
  Typography,
  Paper
} from '@material-ui/core';
import colors from '../../helpers/colors';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    '& .MuiIconButton-colorInherit': {
      color: colors.poppy
    },
    '& .MuiTableBody-root': {
      '& .MuiButtonBase-root': {
        paddingRight: 10,
      },
      paddingRight: 20,
    },
    '& .MuiIcon-root': {
      // padding: 6,
      color: colors.poppy
    },
    '& .MuiToolbar-root': {
      padding: 16,
      paddingRight: 6
    },
    '& .MTableToolbar-searchField': {
      paddingRight: 10
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.poppy,
    }
  },
  title: {
    color: colors.poppy
  }
}));

const Table = ({
  title,
  data,
  columns,
  options,
  actions,
  onRowClick,
  components,
  detailPanel
}) => {
  const classes = useStyles();

  const styles = {
    headerStyle: {
      fontWeight: 'bold',
      color: colors.gray,
      backgroundColor: colors.pink
    },
    searchFieldStyle: {
      span: { color: colors.poppy }
    }
  };

  const tableOptions = {
    actionsColumnIndex: -1,
    tableLayout: 'auto',
    filtering: false,
    sorting: true,
    draggable: false,
    exportButton: false,
    pageSize: 10,
    pageSizeOptions: [10, 100, 200, 500],
    emptyRowsWhenPaging: false,
    ...styles,
    ...options
  };

  const localization = {
    header: {
      actions: ''
    },
  };

  const renderTitle = (
    <Typography variant='h6' className={classes.title}>
      {title}
    </Typography>
  );

  const renderFooter = ({ count }) => (
    <TableCell colSpan={100}>
      <Box display='flex' justifyContent='flex-end' padding={1}>
        <Typography>
          {`${count} Rows`}
        </Typography>
      </Box>
    </TableCell>
  );

  return (
    <Box
      key={`table-${title}`}
      className={classes.root}
    >
      <MaterialTable
        title={renderTitle}
        data={data}
        columns={columns}
        options={tableOptions}
        actions={actions}
        onRowClick={onRowClick}
        components={{
          ...components,
          Pagination: (props) => renderFooter(props),
          Container: (props) => <Paper {...props} elevation={1} />,
        }}
        localization={localization}
        detailPanel={detailPanel}
      />
    </Box>
  );
};

Table.defaultProps = {
  onRowClick: null,
  detailPanel: null,
  options: {},
  components: {},
  actions: []
};

Table.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.objectOf(PropTypes.any),
  components: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.arrayOf(PropTypes.object),
  detailPanel: PropTypes.arrayOf(PropTypes.any),
  onRowClick: PropTypes.func,
};

export default memo(Table);
