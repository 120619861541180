import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import firebaseApp from '../../firebase/Base';
import { getCustomer, getCustomerProject, updateCustomer } from '../../api/customers';
import Tabs from '../../components/Tabs';
import UserAccess from './UserAccess';
import Notifications from './Notifications';
import TitleBar from '../../components/TitleBar';
import LoadingIndicator from '../../components/LoadingIndicator';
import SnackBar, { defaultErrorSnackState, defaultSnackState } from '../../components/SnackBar';
import { validateDriveId } from '../../helpers/customers';
import Form from './Form';
import {
  addUserToAlertList,
  addUserToCustomer,
  addUserToNotificationList,
  removeUserFromAlertList,
  removeUserFromCustomer,
  removeUserFromNotificationList
} from '../../firebase/requests';
import Alerts from './Alerts';

const Update = () => {
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [projects, setProjects] = useState([]);
  const [entities, setEntities] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [snack, setSnack] = useState(defaultSnackState);

  const back = () => {
    history.push('/customermanagement');
  };

  const entitiesData = () => {
    firebaseApp.firestore()
      .collection('entities')
      .orderBy('name', 'asc')
      .get()
      .then((response) => {
        const entitiesArray = [];

        response.forEach((doc) => {
          entitiesArray.push({ ...doc.data(), id: doc.id });
        });

        setEntities(entitiesArray);
      });
  };

  const usersData = () => {
    firebaseApp.firestore().collection('users').get().then((response) => {
      const usersList = [];
      response.forEach((doc) => {
        const data = doc.data();
        usersList.push({
          id: doc.id,
          role: data.role,
          filters: data.filters
        });
      });

      setUsers(usersList);
    });
  };

  const customersProjects = () => {
    getCustomerProject(id).then((response) => {
      setProjects(response);
    });
  };

  const customersData = () => getCustomer(id).then((response) => {
    if (response && response.gcp_service_account && response.service_account_email) {
      customersProjects();
    }

    setCustomer(response);
    setIsLoading(false);
  }).catch(() => {
    setSnack({
      open: true,
      message: 'Customer not found',
      severity: 'error',
      onClose: () => back()
    });
  });

  const updateForm = (values) => {
    const validatedValues = {
      ...values,
      drive_id: validateDriveId(values.drive_id)
    };
    setUpdateIndicator(true);
    updateCustomer(validatedValues).then(() => {
      setSnack({
        open: true,
        message: `${customer.customer_name} successfully updated`,
        onClose: () => {
          setSnack(defaultSnackState);
        }
      });
    }).catch(() => {
      setSnack({
        open: true,
        message: 'Error saving customer',
        severity: 'error',
        onClose: () => setSnack(defaultErrorSnackState)
      });
    }).finally(() => setUpdateIndicator(false));
  };

  const updateUserAccess = async (value, access) => {
    setUpdateIndicator(true);

    if (access) {
      await Promise.all(value.map((user) => addUserToCustomer(id, user.id)));
    } else {
      await Promise.all(value.map((user) => removeUserFromCustomer(id, user.id)));
    }

    getCustomer(id).then((response) => {
      setCustomer(response);
    }).catch(() => {
      setSnack({
        open: true,
        message: 'Error saving customer access list',
        severity: 'error',
        onClose: () => setSnack(defaultErrorSnackState)
      });
    }).finally(() => setUpdateIndicator(false));
  };

  const updateUserNotification = async (value, notify) => {
    setUpdateIndicator(true);

    if (notify) {
      await Promise.all(value.map((user) => addUserToNotificationList(id, user.id)));
    } else {
      await Promise.all(value.map((user) => removeUserFromNotificationList(id, user.id)));
    }

    getCustomer(id).then((response) => {
      setCustomer(response);
    }).catch(() => {
      setSnack({
        open: true,
        message: 'Error saving customer notification list',
        severity: 'error',
        onClose: () => setSnack(defaultErrorSnackState)
      });
    }).finally(() => setUpdateIndicator(false));
  };

  const updateUserAlert = async (value, alert) => {
    setUpdateIndicator(true);

    if (alert) {
      await Promise.all(value.map((user) => addUserToAlertList(id, user.id)));
    } else {
      await Promise.all(value.map((user) => removeUserFromAlertList(id, user.id)));
    }

    getCustomer(id).then((response) => {
      setCustomer(response);
    }).catch(() => {
      setSnack({
        open: true,
        message: 'Error saving customer alert list',
        severity: 'error',
        onClose: () => setSnack(defaultErrorSnackState)
      });
    }).finally(() => setUpdateIndicator(false));
  };

  useEffect(() => {
    entitiesData();
    usersData();
    customersData();
  }, []);

  const customerForm = () => (
    <Form
      entities={entities}
      projects={projects}
      customer={customer}
      updateIndicator={updateIndicator}
      back={() => back()}
      updateForm={(value) => {
        setCustomer(value);
        updateForm(value);
      }}
    />
  );

  const userAccess = () => (
    <UserAccess
      customer={customer}
      users={users}
      updateIndicator={updateIndicator}
      back={() => back()}
      updateUserAccess={(value, access) => updateUserAccess(value, access)}
    />
  );

  const notifications = () => (
    <Notifications
      customer={customer}
      users={users}
      updateIndicator={updateIndicator}
      back={() => back()}
      updateUserNotification={(value, notify) => updateUserNotification(value, notify)}
    />
  );

  const alerts = () => (
    <Alerts
      customer={customer}
      users={users}
      updateIndicator={updateIndicator}
      back={() => back()}
      updateUserAlert={(value, alert) => updateUserAlert(value, alert)}
    />
  );

  return (
    <>
      <TitleBar
        title='Customer Management'
        subTitle={`Update${customer ? `: ${customer.customer_name}` : ''}`}
        updateIndicator={updateIndicator}
      />

      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />

      {
        isLoading ? <LoadingIndicator /> : (

          <Tabs
            transparent
            labels={[
              'Customer Info',
              'User Access',
              'R.A.T Notification',
              'Billing Alert Notification',
            ]}
            content={[
              customerForm(),
              userAccess(),
              notifications(),
              alerts(),
            ]}
          />
        )
      }
    </>
  );
};

export default Update;
