import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper, MenuItem, Button
} from '@material-ui/core';
import firebaseApp from '../../firebase/Base';
import roles from '../../helpers/roles';
import { Context } from '../../components/ContextProvider';
import CustomersAccess from './CustomersAccess';
import { form } from '../../helpers/styles';
import LoadingIndicator from '../../components/LoadingIndicator';
import { customerData, userCustomers } from '../../helpers/customers';
import Dialog from '../../components/Dialog';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import { dialogInitalState, userExists } from '../../helpers/users';
import ConfirmDialog from './ConfirmDialog';
import {
  addUserToCustomer, createUser, deleteUser, editUserRole, removeUserFromCustomer,
  replaceUserInAlert,
  replaceUserInCustomers, replaceUserInNotify
} from '../../firebase/requests';
import TitleBar from '../../components/TitleBar';
import SubmitGroup from '../../components/Button/SubmitGroup';
import { updateClaims } from '../../api/users';

const useStyles = makeStyles(() => (form));

const Update = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { entities } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [access, setAccess] = useState([]);
  const [errorDialog, setErrorDialog] = useState(dialogInitalState);
  const [confirmDialog, setConfirmDialog] = useState(dialogInitalState);
  const [snack, setSnack] = useState(defaultSnackState);
  const [updateIndicator, setUpdateIndicator] = useState(false);

  const back = () => {
    history.push('/usermanagement');
  };

  const reload = () => {
    history.push(`/usermanagement/update/${user.email}`);
  };

  const handleUserChange = (key, value) => {
    const newValue = {
      ...user,
      [key]: value
    };

    setUser(newValue);
  };

  const successSnack = () => {
    setSnack({
      open: true,
      message: 'User updated with success'
    });
  };

  const updateUserActions = async () => {
    const manageCustomers = {
      added: [],
      deleted: [],
    };

    if (user.email !== id) {
      await deleteUser(id);
      await createUser(user.email, user.role);
      await replaceUserInCustomers(id, user.email);
      await replaceUserInNotify(id, user.email);
      await replaceUserInAlert(id, user.email);
    } else {
      await editUserRole(user.email, user.role);
    }

    user.customers.forEach((customer) => {
      if (!customers.includes(customer)) {
        manageCustomers.deleted.push(customer);
      }
    });

    access.forEach((customer) => {
      if (!user.customers.includes(customer)) {
        manageCustomers.added.push(customer);
      }
    });

    await Promise.all(
      manageCustomers.deleted.map(
        async (customer) => removeUserFromCustomer(customer.id, user.email)
      ),
    );

    return Promise.all(
      manageCustomers.added.map(
        async (customer) => addUserToCustomer(customer.id, user.email)
      ),
    );
  };

  const saveUser = async () => {
    try {
      setUpdateIndicator(true);

      updateUserActions().then(() => {
        setUpdateIndicator(false);
        successSnack();
      }).finally(() => updateClaims(user.email));
    } catch (err) {
      setUpdateIndicator(false);

      setErrorDialog({
        open: true,
        title: 'Firebase error',
        description: err.message,
      });
    }
  };

  const validateUser = () => {
    const errorList = [];

    if (user.email !== id) {
      access.forEach((customer) => {
        const customerUsers = customerData(customers, customer.id, 'id').users;

        if (userExists(user.email, customerUsers)) {
          errorList.push(user.email);
        }
      });
    }

    if (errorList.length > 0) {
      setErrorDialog({
        open: true,
        title: 'E-mail already exists',
        description: `${user.email} already exists, please provide a different email`,
      });
    } else {
      setConfirmDialog({
        open: true,
        title: 'Please confirm',
      });
    }
  };

  const handleSubmit = () => {
    if (access.length === 0) {
      setErrorDialog({
        open: true,
        title: 'User Access',
        description: 'Please grant access to at least one customer',
      });
    } else {
      validateUser();
    }
  };

  const userData = () => {
    firebaseApp.firestore().collection('users').doc(id).get()
      .then((response) => {
        firebaseApp.firestore().collection('customers').get().then((customersSnap) => {
          const customersArray = [];

          customersSnap.forEach((doc) => {
            customersArray.push({
              ...doc.data(),
              id: doc.id
            });
          });

          const initialAccess = userCustomers(id, customersArray);

          setUser({
            email: id,
            role: response.data().role,
            customers: initialAccess
          });

          setAccess(userCustomers(id, customersArray));
          setCustomers(customersArray);
          setIsLoading(false);
        });
      });
  };

  useEffect(() => {
    userData();
  }, []);

  const renderForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h6'>
              User
            </Typography>
          </Box>
          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='role'
            variant='outlined'
            onChange={(event) => handleUserChange('role', event.target.value)}
            value={user.role}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {roles.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextValidator>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Email'
            variant='outlined'
            onChange={(event) => handleUserChange('email', event.target.value)}
            value={user.email}
            validators={['required', 'isEmail']}
            errorMessages={[
              'This field is required',
              'Email is not valid'
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper elevation={0} className={classes.paper}>
          <CustomersAccess
            entities={entities}
            access={access}
            setAccess={(value) => setAccess(value)}
            options={customers}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  const renderButtons = () => (
    <SubmitGroup
      cancel={() => back()}
      updateIndicator={updateIndicator}
    />
  );

  const renderDialogs = () => (
    <>
      <Dialog
        title={errorDialog.title}
        open={errorDialog.open}
        onClose={() => setErrorDialog(dialogInitalState)}
      >
        <Typography gutterBottom>
          {errorDialog.description}
        </Typography>
      </Dialog>

      <Dialog
        title={confirmDialog.title}
        open={confirmDialog.open}
        onClose={() => setConfirmDialog(dialogInitalState)}
        actionButtons={(
          <>
            <Button
              color='primary'
              onClick={() => {
                setConfirmDialog(dialogInitalState);
                saveUser();
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => setConfirmDialog(dialogInitalState)}
              color='default'
            >
              Cancel
            </Button>
          </>
        )}
      >
        <Box pb={2}>
          {confirmDialog.open && (
            <ConfirmDialog
              users={[user.email]}
              role={user.role}
              customers={access}
              update
            />
          )}
        </Box>
      </Dialog>
    </>
  );

  const renderSnack = () => (
    <SnackBar
      open={snack.open}
      message={snack.message}
      onClose={() => {
        setSnack(defaultSnackState);
        reload();
      }}
    />
  );

  return (
    isLoading ? <LoadingIndicator /> : (
      <>
        <TitleBar
          title='User Management'
          subTitle={`Update${user ? `: ${user.email}` : ''}`}
          updateIndicator={updateIndicator}
        />
        <ValidatorForm onSubmit={() => handleSubmit()}>

          {renderForm()}
          {renderButtons()}
          {renderDialogs()}
          {renderSnack()}

        </ValidatorForm>
      </>
    )
  );
};

export default Update;
