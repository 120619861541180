import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import firebaseApp from '../../../firebase/Base';
import { Context } from '../../../components/ContextProvider';
import TitleBar from '../../../components/TitleBar';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Back from '../../../components/Button/Back';
import { settingsServicesAndLabels, defaultSettings } from '../../../api/billing';
import {
  addFilterToUser,
  addUserToFilterList,
  removeFilterToUser,
  removeUserFromFilterList,
} from '../../../firebase/requests';
import { getCustomer } from '../../../api/customers';
import Filters from './Filters';
import { updateClaims } from '../../../api/users';

const Viewers = () => {
  const history = useHistory();

  const {
    billingAccounts,
    billingAccountId,
    selectedCustomer,
  } = useContext(Context);

  const [customer, setCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [settings, setSettings] = useState(defaultSettings);
  const [isLoading, setIsLoading] = useState(true);
  const [updateIndicator, setUpdateIndicator] = useState(false);

  let listener = null;

  const customersData = () => getCustomer(selectedCustomer).then((response) => {
    setCustomer(response);
  });

  const billingSettings = () => settingsServicesAndLabels(
    selectedCustomer,
    billingAccountId
  ).then((response) => {
    setSettings({
      project_label: response.project_labels,
      resource_label: response.resource_labels,
    });
  });

  /**
   * Move to management api
   */
  const usersData = () => {
    listener = firebaseApp.firestore().collection('users').onSnapshot((snapshoot) => {
      const usersList = [];
      snapshoot.forEach((doc) => {
        const data = doc.data();
        usersList.push({
          id: doc.id,
          role: data.role,
          filters: data.filters
        });
      });

      setUsers(usersList);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    billingSettings()
      .then(() => customersData())
      .then(() => usersData());

    return () => listener();
  }, [selectedCustomer, billingAccountId]);

  /**
   * Move to management api
   */
  const updateUserFilter = async (value, filter) => {
    setUpdateIndicator(true);

    if (filter) {
      /**
       * add filters
       */
      await Promise.all(value.users.map((user) => addUserToFilterList(selectedCustomer, user.id)));
      await Promise.all(
        value.users.map(
          (user) => addFilterToUser(selectedCustomer, user.id, value.label_key, value.label_value)
        )
      );
      await Promise.all(value.users.map((user) => updateClaims(user.id)));
    } else {
      /**
       * remove filters
       */
      await Promise.all(value.map((user) => removeUserFromFilterList(selectedCustomer, user.id)));
      await Promise.all(value.map((user) => removeFilterToUser(user.id, selectedCustomer)));
      await Promise.all(value.map((user) => updateClaims(user.id)));
    }

    customersData().then(() => setUpdateIndicator(false));
  };

  /**
   * Remove this validation when billing_account_id is added to Alerts, Filters and Export
   */
  if (billingAccountId !== billingAccounts[0]) {
    return (
      <>
        <TitleBar title='Billing Alerts'>
          <Back path='/' />
        </TitleBar>
        <Typography>
          Billing Viewers is not implemented for this billing account
        </Typography>
      </>
    );
  }

  return (
    isLoading ? <LoadingIndicator /> : (
      <>
        <TitleBar
          title='Billing Viewers'
          updateIndicator={updateIndicator}
        >
          <Back path='/' />
        </TitleBar>

        <Filters
          customer={customer}
          settings={settings}
          users={users}
          updateIndicator={updateIndicator}
          back={() => history.push('/customermanagement')}
          updateUserFilter={(value, filter) => updateUserFilter(value, filter)}
        />
      </>
    )
  );
};

export default Viewers;
