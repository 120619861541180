import React, { useState, useEffect, useContext } from 'react';
import Chart from '../Chart';
import { Context } from '../../../../components/ContextProvider';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { totalProjectLabels } from '../../../../api/billing';
import { cacheName } from '../../../../helpers/utils';

const ChartTotalProjectLabels = () => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    selectedCustomer, settings, chartTotalProjectLabels, updateContext, billingAccountId, billing
  } = useContext(Context);

  const cache = cacheName(`${selectedCustomer}-${billingAccountId}`, settings);
  const defaultLabel = Object.keys(billing.project_labels)[0];

  const getTotalData = () => {
    totalProjectLabels(
      selectedCustomer,
      billingAccountId,
      settings,
      defaultLabel
    ).then((response) => {
      updateContext({
        chartTotalProjectLabels: {
          ...chartTotalProjectLabels,
          projectLabel: settings.projectLabel,
          [cache]: response
        }
      });
    });
  };

  useEffect(() => {
    if (
      chartTotalProjectLabels
      && chartTotalProjectLabels[cache]
      && chartTotalProjectLabels.projectLabel === settings.projectLabel
    ) {
      setIsLoading(false);
    } else {
      getTotalData();
    }
  }, [billingAccountId]);

  return (
    isLoading ? <LoadingIndicator /> : (
      <Chart
        title={settings.projectLabel || defaultLabel}
        data={chartTotalProjectLabels[cache]}
      />
    )
  );
};

export default ChartTotalProjectLabels;
