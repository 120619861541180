/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemText, Button, TextField, Typography, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import colors from '../../helpers/colors';
import { dateMoment } from '../../helpers/date';
import { saveNewComment } from '../../api/support';
import { Context } from '../../components/ContextProvider';
import { jiraLimits, supportColor } from '../../helpers/support';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiListItem-root': {
      padding: 0
    },
    marginTop: 20
  },
  list: {
    marginTop: 20
  },
  item: {
    cursor: 'pointer',
  },
  button: {
    marginTop: 10,
    color: colors.poppy,
    borderColor: colors.poppy
  },
  comment: {
    borderBottom: '1px solid whitesmoke',
    paddingBottom: 10,
    textAlign: 'justify',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line'
  },
  commentTitle: {
    color: colors.neutralGray,
    fontSize: '11pt',
  },
  status: {
    fontSize: '11pt',
    paddingTop: 2
  },
  textArea: {
    textAlign: 'justify',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
  }
}));

const Comments = ({
  comments,
  data,
  updateIndicator,
  setUpdateIndicator,
  reload
}) => {
  const classes = useStyles();
  const { user } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState('');

  const saveComment = () => {
    const newComment = {
      message: comment.trim(),
      author: user.claims.email,
      date: new Date().toISOString().toString(),
      previous_status: '',
      current_status: ''
    };

    saveNewComment(data.jira_issue_key, newComment).then(() => {
      reload();
    }).finally(() => {
      setComment('');
    });
  };

  const handleSubmit = () => {
    if (comment) {
      setUpdateIndicator(true);
      saveComment();
    }
  };

  const renderCommentStatus = (commentData) => {
    const statusChange = commentData.previous_status && commentData.current_status;
    return (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography align='left' className={classes.commentTitle}>
            {`${commentData.author}, ${dateMoment(commentData.date, 'YYYY/MM/DD HH:mm')}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            style={{ color: supportColor(commentData.current_status) }}
            align='right'
            className={classes.status}
          >
            {statusChange ? `${commentData.previous_status} > ${commentData.current_status}` : ''}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      {
        data.status !== 'Closed' && data.status !== 'Cancel' ? (
          <>
            <TextField
              disabled={updateIndicator}
              className={classes.textArea}
              fullWidth
              label='Add Comment'
              variant='outlined'
              onChange={(event) => setComment(event.target.value)}
              multiline
              minRows={comment ? 6 : 1}
              maxRows={Infinity}
              value={comment}
              helperText={comment ? `The comment cannot be longer than ${jiraLimits.comment} characters.` : ''}
              inputProps={{
                maxLength: jiraLimits.comment,
              }}
            />
            {
              comment && !updateIndicator ? (
                <>
                  <Button
                    className={classes.button}
                    style={{
                      marginRight: 10,
                      color: colors.darkGray,
                      borderColor: colors.darkGray,
                    }}
                    variant='outlined'
                    component='span'
                    size='small'
                    startIcon={<AddIcon />}
                    onClick={() => handleSubmit()}
                  >
                    Add Comment
                  </Button>

                  <Button
                    className={classes.button}
                    variant='outlined'
                    component='span'
                    size='small'
                    startIcon={<ClearIcon />}
                    onClick={() => setComment('')}
                  >
                    Cancel
                  </Button>
                </>
              ) : null
            }
          </>
        ) : null
      }
      <List className={classes.list}>
        {comments.map((commentData) => (
          <ListItem key={`item-${commentData.date}`}>
            <ListItemText
              className={classes.comment}
              key={`text-${commentData.date}`}
              primary={renderCommentStatus(commentData)}
              secondary={commentData.message}
              secondaryTypographyProps={{
                style: {
                  fontSize: '11pt',
                  color: colors.darkGray
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

Comments.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  reload: PropTypes.func.isRequired,
  updateIndicator: PropTypes.bool.isRequired,
  setUpdateIndicator: PropTypes.func.isRequired,
};

export default Comments;
