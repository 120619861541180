import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import Table from '../../components/Table';
import AddBox from '../../components/Table/AddBox';
import { dialogInitalState } from '../../helpers/users';
import Dialog from '../../components/Dialog';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import {
  deleteUser,
  removeUserFromAllAlertList,
  removeUserFromAllBillingViewerList,
  removeUserFromAllNotifyList,
  removeUserFromCustomers
} from '../../firebase/requests';

const UsersTable = ({
  data,
  setUpdateIndicator
}) => {
  const history = useHistory();
  const [snack, setSnack] = useState(defaultSnackState);
  const [deleteDialog, setDeleteDialog] = useState(dialogInitalState);
  const [errorDialog, setErrorDialog] = useState(dialogInitalState);

  const newUsers = () => {
    history.push('/usermanagement/new');
  };

  const updateUser = (id) => {
    history.push(`/usermanagement/update/${id}`);
  };

  const deleteSingleUser = (user) => (
    Promise.all([
      deleteUser(user),
      removeUserFromCustomers(user),
      removeUserFromAllNotifyList(user),
      removeUserFromAllAlertList(user),
      removeUserFromAllBillingViewerList(user),
    ])
  );

  const deleteUsers = async (users) => {
    setUpdateIndicator(true);
    setDeleteDialog(dialogInitalState);

    try {
      await Promise.all(
        users.map(async (user) => deleteSingleUser(user.id)),
      ).then(() => {
        setSnack({
          open: true,
          message: `${users.length} user(s) deleted with success`
        });
      });
    } catch (err) {
      setErrorDialog({
        open: true,
        title: 'Firebase error',
        description: err.message,
      });
    }

    setUpdateIndicator(false);
  };

  const renderEntities = (value) => {
    if (Array.isArray(value) && value.length) {
      const { entities } = data;
      const customersEntities = [];
      value.map((customer) => {
        if (customer.entity && !customersEntities.includes(entities[customer.entity].name)) {
          customersEntities.push(entities[customer.entity].name);
        }

        return null;
      });

      return (
        <ul>
          {customersEntities.sort().map((entity, i) => (
            <li
              key={`id:${entity}${i}`}
              style={{
                listStyleType: 'square',
                padding: 2
              }}
            >
              {entity}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <Typography>
        No Entities
      </Typography>
    );
  };

  const renderUsers = (value) => {
    if (Array.isArray(value) && value.length) {
      return (
        <ul>
          {value.sort().map((customer) => (
            <li
              key={`id:${customer.id}`}
              style={{
                listStyleType: 'square',
                padding: 2
              }}
            >
              {`${customer.name} (${customer.id})`}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <Typography>
        No Customers
      </Typography>
    );
  };

  const renderErrorDialog = () => (
    <Dialog
      title={errorDialog.title}
      open={errorDialog.open}
      onClose={() => setErrorDialog(dialogInitalState)}
    >
      <Typography gutterBottom>
        {errorDialog.description}
      </Typography>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog
      title={deleteDialog.title}
      open={deleteDialog.open}
      onClose={() => setDeleteDialog(dialogInitalState)}
      actionButtons={(
        <>
          <Button
            color='primary'
            onClick={() => deleteUsers(deleteDialog.value)}
          >
            Submit
          </Button>
          <Button
            onClick={() => setDeleteDialog(dialogInitalState)}
            color='default'
          >
            Cancel
          </Button>
        </>
      )}
    >
      {
        deleteDialog.open && (
          <ConfirmDialog users={deleteDialog.value} />
        )
      }
    </Dialog>
  );

  const renderSnack = () => (
    <SnackBar
      open={snack.open}
      message={snack.message}
      onClose={() => setSnack(defaultSnackState)}
    />
  );

  return (
    <>
      <Table
        title='Users'
        data={data.users}
        columns={[
          {
            title: 'Email',
            field: 'id',
            removable: false
          },
          {
            title: 'Role',
            field: 'role',
            removable: false,
            lookup: {
              admin: 'Admin',
              customer: 'Customer',
              customerViewer: 'Customer Viewer'
            }
          },
          {
            title: 'Entities',
            filtering: false,
            sorting: false,
            render: (value) => renderEntities(value.customers),
          },
          {
            title: 'Customer Access',
            field: 'customers',
            filtering: false,
            sorting: false,
            render: (value) => renderUsers(value.customers),
          }
        ]}
        actions={[
          {
            icon: (() => <AddBox title='New' />),
            onClick: () => newUsers(),
            isFreeAction: true
          },
          {
            tooltip: 'Remove All Selected Users',
            icon: 'delete',
            onClick: (evt, value) => setDeleteDialog({
              open: true,
              title: `Please confirm to delete the following ${value.length} users`,
              value,
            })
          }
        ]}
        options={{
          filtering: true,
          selection: true,
          columnsButton: true,
          pageSize: 100
        }}
        onRowClick={(evt, rowData) => updateUser(rowData.id)}
      />

      {renderDeleteDialog()}
      {renderErrorDialog()}
      {renderSnack()}
    </>
  );
};

UsersTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  setUpdateIndicator: PropTypes.func.isRequired,
};

export default UsersTable;
