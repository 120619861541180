import React, { useContext } from 'react';
import { Context } from '../../../../components/ContextProvider';
import Tabs from '../../../../components/Tabs';
import ChartTotalResourceLabels from './ChartTotalResourceLabels';
import ChartResourceLabels from './ChartResourceLabels';

const ResourceLabel = () => {
  const { billing } = useContext(Context);

  return billing && billing.resource_labels && (
    <Tabs
      transparent
      labels={[
        'Total (with Resource Label)',
        'Resource Labels (with Project)',
      ]}
      content={[
        <ChartTotalResourceLabels />,
        <ChartResourceLabels />,
      ]}
    />
  );
};

export default ResourceLabel;
