import React, {
  useState, useContext, lazy, Suspense
} from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, Paper, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from '../../../components/ContextProvider';
import { set } from '../../../helpers/settings';
import { billingProjects, loadingStyles } from '../../../helpers/styles';
import Projects from './Projects';
import Top from './Top';

const Chart = lazy(() => import('./Chart'));

const useStyles = makeStyles(() => (billingProjects));

const ChartMap = ({
  data,
  title
}) => {
  const classes = useStyles();

  const { selected } = useContext(Context);

  const [top, setTop] = useState(selected.top);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const handleSelectedTop = (value) => {
    set('top', value);
    setTop(value);
    setSelectedProjects([]);
  };

  const handleSelectedProject = (value) => {
    if (value.length > 0) {
      handleSelectedTop(0);
    } else {
      handleSelectedTop(10);
    }

    setSelectedProjects(value);
  };

  const selectedTitle = () => (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant='h6' style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
    </Paper>
  );

  const projectSelector = () => (
    <Paper className={classes.paper} elevation={0}>
      <Top
        selected={top}
        handleSelected={(selectedTop) => handleSelectedTop(selectedTop)}
      />

      <Projects
        key={`top-sync-${top}`}
        options={Object.keys(data)}
        selectedOptions={selectedProjects}
        handleSelected={(value) => handleSelectedProject(value)}
      />
    </Paper>
  );

  const projectCharts = () => {
    const validate = (key: string, index: number) => (
      (!top || top > index)
      && (selectedProjects.length === 0 || selectedProjects.includes(key))
    );

    const charts = Object.keys(data).map((k, i) => (validate(k, i) ? (
      <Box
        key={`${k}-${i}`}
        paddingBottom={2}
      >
        <Chart
          title={k}
          data={{
            data: data[k].data,
            summary: data[k].summary,
            max_value: data[k].max_value,
            keys: data[k].keys,
            colors: data[k].colors
          }}
        />
      </Box>
    ) : null));

    return charts;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {
          title && (
            <Grid item xs={12}>
              {selectedTitle()}
            </Grid>
          )
        }
        <Grid item xs={12}>
          {projectSelector()}
        </Grid>
        <Grid item xs={12}>
          <Suspense fallback={<Box style={loadingStyles} />}>
            {projectCharts()}
          </Suspense>
        </Grid>
      </Grid>
    </div>
  );
};

ChartMap.defaultProps = {
  title: ''
};

ChartMap.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string
};

export default ChartMap;
