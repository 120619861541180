import React, { useContext, useEffect, useState } from 'react';
import getAnomalies from '../../api/anomalies';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import TitleBar from '../../components/TitleBar';
import Table from '../../components/Table';

const Anomalies = () => {
  const [loading, setLoading] = useState(true);
  const [anomaliesTmp, setAnomaliesTmp] = useState([]);
  const {
    selectedCustomer: customerId,
    billingAccountId,
    customerData,
  } = useContext(Context);
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    getAnomalies(customerId, billingAccountId, customerData.minimum_anomaly_threshold,
      customerData.minimum_value_toggled_anomaly)
      .then((response) => {
        setAnomaliesTmp(response);
        setLoading(false);
      });
  }, [billingAccountId]);

  const title = 'Anomalies: ';
  const billingAccount = ' - Billing Account ';

  const getCurrency = (currency) => {
    if (currency === 'SN') return 'EU';
    return currency;
  };

  return (
    customerData.anomaly_detection ? (
      <>
        <TitleBar title='Anomaly Detection' />
        <>
          {
            loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                title={title + anomaliesTmp.date
                  + billingAccount + anomaliesTmp.billing_account_id}
                columns={[
                  {
                    title: 'Project',
                    field: 'project_id',
                    width: '100%',
                    removable: false
                  },
                  {
                    field: 'service',
                    title: 'Service',
                  },
                  {
                    title: 'Mean Daily Spend',
                    field: 'mean_cost',
                    type: 'currency',
                    currencySetting: { currencyCode: getCurrency(customerData.billing_currency) },
                    width: '100%',
                    removable: false,
                    filtering: false,
                    cellStyle: {
                      textAlign: 'left'
                    },
                    headerStyle: {
                      textAlign: 'left',
                    },
                  },
                  {
                    title: 'Latest Daily Spend',
                    field: 'to_date_cost',
                    type: 'currency',
                    currencySetting: { currencyCode: getCurrency(customerData.billing_currency) },
                    width: '100%',
                    filtering: false,
                    cellStyle: {
                      textAlign: 'left'
                    },
                    headerStyle: {
                      textAlign: 'left',
                    },
                  },
                  {
                    title: 'Percentage Change',
                    field: 'delta_from_mean_cost_perc',
                    width: '100%',
                    removable: false,
                    filtering: false,
                    render: (rowData) => {
                      const { delta_from_mean_cost_perc: delt } = rowData;
                      return (
                        <td align='center'>
                          { delt }
                          %
                        </td>
                      );
                    },
                  }

                ]}
                data={anomaliesTmp.anomalies}
                options={{
                  filtering: true,
                  columnsButton: true,
                  paging: true
                }}
              />
            )
          }
        </>
      </>
    )
      : (
        <>
          <TitleBar
            title='Anomaly Detection'
            subTitle={`for ${customerData.customer_name} is disabled`}
          />
        </>
      ));
};

export default Anomalies;
