const roles = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'customer',
    label: 'Customer',
  },
  {
    value: 'customerViewer',
    label: 'Customer Viewer',
  }
];

export default roles;
