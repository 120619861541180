import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SimpleTable from '../../../components/Table/SimpleTable';
import { tierTable, tableDataDays, tableDataMonths } from '../../../helpers/discount';

const Discount = ({
  selectedDate,
  data,
  customer
}) => {
  const tableDiscount = () => {
    switch (selectedDate) {
    case '12months':
      return tableDataMonths(data);
    default:
      return tableDataDays(data[0]);
    }
  };

  const tableData = tableDiscount();
  const tierData = tierTable(customer);

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={tierData.tiers ? 10 : 12}>
        <SimpleTable
          columns={tableData.columns}
          data={tableData.data}
        />
      </Grid>
      {
        tierData.tiers ? (
          <Grid item xs={12} md={2}>
            <SimpleTable
              columns={tierData.columns}
              data={tierData.data}
            />
          </Grid>
        ) : null
      }
    </Grid>
  );
};

Discount.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Discount;
