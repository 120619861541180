import firebaseApp, { arrayToRemove, arrayToUpdate } from './Base';

/**
 * MOVE DIRECT FIREBASE REQUESTS TO API
 */

/**
 * Create Log
 */
export const createLog = (
  entry: Object
) => firebaseApp.firestore().collection('logs').doc().set(entry);

/**
 * Entity List
 */
export const entityList = () => firebaseApp.firestore().collection('entities').get().then((response) => {
  const entities = {};

  response.forEach((doc) => {
    if (doc && doc.id) {
      entities[doc.id] = {
        id: doc.id,
        ...doc.data()
      };
    }
  });

  return entities;
});

/**
 * Create Entity
 */
export const createEntity = (
  entity: Object
) => firebaseApp.firestore().collection('entities').doc().set(entity);

/**
 * Update Entity
 */
export const updateEntity = (
  id: string,
  entity: Object
) => firebaseApp.firestore().collection('entities').doc(id).update(entity);

/**
 * Create User
 */
export const createUser = (
  userId: string,
  role: string
) => firebaseApp.firestore().collection('users').doc(userId).set({ role });

/**
 * Delete User
 */
export const deleteUser = (userId: string) => firebaseApp.firestore().collection('users').doc(userId).delete();

/**
 * Edit User Role
 */
export const editUserRole = (email, userRole) => firebaseApp.firestore().collection('users').doc(email).update({
  role: userRole,
});

/**
 * Add user to customer
 */
export const addUserToCustomer = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ users: arrayToUpdate(user) });

/**
 * Remove Users from customer
 */
export const removeUserFromCustomer = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ users: arrayToRemove(user) });

/**
 * Remove Users from all customers
 */
export const removeUserFromCustomers = (email) => firebaseApp.firestore()
  .collection('customers')
  .where('users', 'array-contains', email)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ users: arrayToRemove(email) });
  }));

/*
* Replace User in Customers
*/
export const replaceUserInCustomers = (emailToRemove, emailToAdd) => firebaseApp.firestore()
  .collection('customers')
  .where('users', 'array-contains', emailToRemove)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ users: arrayToRemove(emailToRemove) });
    q.ref.update({ users: arrayToUpdate(emailToAdd) });
  }));

/**
 * Add user to notification list
 */
export const addUserToNotificationList = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ notify: arrayToUpdate(user) });

/**
 * Remove Users from notification list
 */
export const removeUserFromNotificationList = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ notify: arrayToRemove(user) });

/**
 * Remove User from Notify List
 */
export const removeUserFromAllNotifyList = (email) => firebaseApp.firestore()
  .collection('customers')
  .where('notify', 'array-contains', email)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ notify: arrayToRemove(email) });
  }));

/*
* Replace User in Notify
*/
export const replaceUserInNotify = (emailToRemove, emailToAdd) => firebaseApp.firestore()
  .collection('customers')
  .where('notify', 'array-contains', emailToRemove)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ notify: arrayToRemove(emailToRemove) });
    q.ref.update({ notify: arrayToUpdate(emailToAdd) });
  }));

/**
 * Add user to notification list
 */
export const addUserToAlertList = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ alert: arrayToUpdate(user) });

/**
 * Remove Users from alert list
 */
export const removeUserFromAlertList = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ alert: arrayToRemove(user) });

/**
 * Remove User from Alert List
 */
export const removeUserFromAllAlertList = (email) => firebaseApp.firestore()
  .collection('customers')
  .where('alert', 'array-contains', email)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ alert: arrayToRemove(email) });
  }));

/**
 * Remove User Billing Viewer List
 */
export const removeUserFromAllBillingViewerList = (email) => firebaseApp.firestore()
  .collection('customers')
  .where('filters', 'array-contains', email)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ filters: arrayToRemove(email) });
  }));

/*
* Replace User in Alert
*/
export const replaceUserInAlert = (emailToRemove, emailToAdd) => firebaseApp.firestore()
  .collection('customers')
  .where('alert', 'array-contains', emailToRemove)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ alert: arrayToRemove(emailToRemove) });
    q.ref.update({ alert: arrayToUpdate(emailToAdd) });
  }));

/**
 * Add Filter to User
 */
export const addFilterToUser = (
  customer: string,
  user: string,
  labelKey: string,
  labelValue: string
) => firebaseApp.firestore()
  .collection('users')
  .doc(user)
  .update({
    filters: arrayToUpdate({
      customer,
      project_label: [
        {
          label_key: labelKey,
          label_value: labelValue
        }
      ]
    })
  });

/**
 * Add Filter to User
 */
export const removeFilterToUser = (
  user: string,
  id: string
) => firebaseApp.firestore()
  .collection('users')
  .doc(user)
  .get()
  .then((response) => {
    const newFilters = [];

    response.data().filters.map((filter) => {
      if (filter.customer !== id) {
        newFilters.push(filter);
      }

      return null;
    });

    response.ref.update({ filters: newFilters });
  });

/**
 * Add user to filter list
 */
export const addUserToFilterList = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ filters: arrayToUpdate(user) });

/**
 * Remove Users from filter list
 */
export const removeUserFromFilterList = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('customers')
  .doc(customer)
  .update({ filters: arrayToRemove(user) });

/**
 * Remove User from filters List
 */
export const removeUserFromAllFilterList = (email) => firebaseApp.firestore()
  .collection('customers')
  .where('filter', 'array-contains', email)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ alert: arrayToRemove(email) });
  }));

/*
* Replace User in filters
*/
export const replaceUserInFilter = (emailToRemove, emailToAdd) => firebaseApp.firestore()
  .collection('customers')
  .where('filter', 'array-contains', emailToRemove)
  .get()
  .then((data) => data.docs.forEach((q) => {
    q.ref.update({ alert: arrayToRemove(emailToRemove) });
    q.ref.update({ alert: arrayToUpdate(emailToAdd) });
  }));

/**
 * Add user to Entity Admin
 */
export const addUserToEntityAdmin = (
  entity: string,
  user: string
) => firebaseApp.firestore()
  .collection('entities')
  .doc(entity)
  .update({ admins: arrayToUpdate(user) });

/**
 * Remove Users from Entity Admin
 */
export const removeUserFromEntityAdmin = (
  customer: string,
  user: string
) => firebaseApp.firestore()
  .collection('entities')
  .doc(customer)
  .update({ admins: arrayToRemove(user) });
