/**
 * Dialog Initial State
 */
export const dialogInitalState = {
  open: false,
  title: '',
  description: '',
};

/**
 * User Exists in given array
 */
export const userExists = (user: string, users: Array) => users.includes(user);

/**
 * Email split
 */
export const emailSplit = (emails: string) => emails.replace(/ /g, '').replace(', $', '').split(',');

/**
 * Customers Viewers
 */
export const roleUsers = (
  users: Array,
  role: string
) => {
  const usersMap = [];

  users.map((user) => {
    if (user.role === role) {
      usersMap.push(user);
    }
    return null;
  });

  return usersMap;
};

export const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

export const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

export const availableCustomers = (options, access) => {
  const available = [];
  options.map((customer) => {
    if (access && access.some((item) => item.id === customer.id)) {
      return null;
    }

    return available.push({
      id: customer.id,
      name: customer.customer_name,
      entity: customer.entity
    });
  });

  return available;
};

export const availableEntities = (options, access) => {
  const available = [];
  Object.keys(options).map((entity) => {
    const entityData = options[entity];

    if (access && access.some((item) => item.id === entityData.id)) {
      return null;
    }

    return available.push({
      id: entityData.id,
      name: entityData.name,
    });
  });

  return available;
};
