import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography
} from '@material-ui/core';
import colors from '../../helpers/colors';

const useStyles = makeStyles({
  table: {
    width: '100%'
  },
  root: {
    border: '1px solid whitesmoke'
  },
  icon: {
    cursor: 'pointer'
  }
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: colors.pink,
    color: colors.darkGray,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: colors.background,
    },
  },
}))(TableRow);

const ViewTierDiscount = ({ rows }) => {
  const classes = useStyles();

  const renderRow = (row, index) => (
    <StyledTableRow key={index}>
      <StyledTableCell>{index + 1}</StyledTableCell>
      <StyledTableCell>
        <Typography>
          {row.threshold_spend}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>
          {row.percentage}
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <div className={classes.root}>
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label='tier table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Tier</StyledTableCell>
              <StyledTableCell>Monthly Spend</StyledTableCell>
              <StyledTableCell>Discount (%)</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.length > 0 ? rows.map((row, index) => (renderRow(row, index))) : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

ViewTierDiscount.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ViewTierDiscount;
