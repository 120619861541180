import apiRequest from './apiRequest';

/**
 * Init Monitoring
 */
export const initMonitoring = (id: string) => {
  const options = {
    url: `/v1/monitoring/${id}/`,
    method: 'POST'
  };

  return apiRequest(options, false);
};

/**
   * Rebuild Monitoring
   */
export const rebuildMonitoring = (id: string) => {
  const options = {
    url: `/v1/monitoring/${id}/rebuild`,
    method: 'POST'
  };

  return apiRequest(options, false);
};
