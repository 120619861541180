/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@material-ui/core';
import firebaseApp, { provider } from '../../firebase/Base';
import { Context } from '../../components/ContextProvider';
import Button from '../../components/Button';
import styles from './styles';
import Google from '../../assets/icons/Google';
import colors from '../../helpers/colors';
import DividerWithText from '../../components/DividerWithText';

class Login extends Component {
    static contextType = Context;

    constructor(props) {
      super(props);

      this.state = {
        email: '',
        password: ''
      };
    }

    /**
     * Submit Submit action
     */
    handleSubmit = (event) => {
      event.preventDefault();

      const { email, password } = this.state;

      if (email && password) {
        this.firebaseAuth(email, password);
      }
    };

    firebaseAuth = async (email, password) => {
      try {
        await firebaseApp.auth().signInWithEmailAndPassword(email, password).then((snap) => {
          const { user } = snap;

          if (user.emailVerified) {
            <Redirect to='/' />;
          } else {
            alert('Please check your email to activate this account');
          }
        });
      } catch (error) {
        alert('Invalid username or password');
      }
    };

    firebaseAuthWithGoogle = () => {
      firebaseApp.auth().signInWithPopup(provider).then((snap) => {
        const { user } = snap;
        if (user.emailVerified) {
          <Redirect to='/' />;
        } else {
          alert('Email is not verified');
        }
      }).catch((e) => alert(e.message));
    };

    render() {
      const { email, password } = this.state;

      return (
        <ValidatorForm
          style={styles.form}
          ref='form'
          onSubmit={this.handleSubmit}
        >
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <TextValidator
                fullWidth
                label='Email'
                variant='outlined'
                onChange={(event) => this.setState({ email: event.target.value })}
                name='email'
                value={email}
                validators={['required', 'isEmail']}
                errorMessages={[
                  'This field is required',
                  'Email is not valid'
                ]}
              />
            </Grid>
            <Grid item>
              <TextValidator
                fullWidth
                label='Password'
                type='password'
                variant='outlined'
                onChange={(event) => this.setState({
                  password: event.target.value
                })}
                name='password'
                value={password}
                validators={['required', 'minStringLength: 6']}
                errorMessages={[
                  'This field is required',
                  'Must have at least 6 characters'
                ]}
              />
            </Grid>
            <Grid item>
              <Button
                type='submit'
              >
                Sign In
              </Button>
            </Grid>
            <Grid item>
              <DividerWithText> Or </DividerWithText>
            </Grid>
            <Grid item>
              <Button
                backgroundColor={colors.darkGray}
                onClick={this.firebaseAuthWithGoogle}
              >
                <Google />
                Sign in with Google
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      );
    }
}

export default withRouter(Login);
