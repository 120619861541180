import React, { useContext, useEffect, useState } from 'react';
import WorkspaceLicenseTable from './WorkspaceLicenseTable';
import WorkspaceLicenseTabs from './WorkspaceLicenseTabs';
import {
  historyLicenseColumns, workspaceLabels, existingLicenseColumns, orderLicenseColumns
} from '../../helpers/workspaceLicense';
import { Context } from '../../components/ContextProvider';
import { getLicenseInfo, getLicenseOrderInfo, getLicenseHistoryInfo } from '../../api/license';
import LoadingIndicator from '../../components/LoadingIndicator';
import CreateLicense from './CreateLicense';
import { VIEW_MODE } from './constants';
import ViewLicensePage from './pages/ViewLicensePage';
import ViewOrderPage from './pages/ViewOrderPage';
import TitleBar from '../../components/TitleBar';

const WorkspaceLicense = () => {
  // Label variables
  const existing = 'Existing';
  const orders = 'Orders';
  const history = 'History';

  const [existingData, setExistingData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [doUpdateTable, setDoUpdateTable] = useState(false);
  const [selectedView, setSelectedView] = useState(existing);
  const [doCreateLicense, setDoCreateLicense] = useState(false);
  const [viewMode] = useState('');

  const {
    customerData, selectedCustomer
  } = useContext(Context);

  // Custom history data mapping to object due to nested array 'update' in response
  const mapHistoryData = (data) => {
    let newData = [];
    data.forEach((object) => {
      object.updates.forEach((subObject) => {
        newData = [...newData, { ...subObject, ...object }];
      });
    });
    return newData;
  };
  const addTableIds = (array) => {
    let newData = [];
    array.forEach((obj, index) => {
      newData = [...newData, { ...obj, id: index + 1 }];
    });
    return newData;
  };

  const loadExistingData = () => getLicenseInfo(customerData.domain_name).then((response) => {
    const tmpArray = addTableIds(response);
    setExistingData(tmpArray);
  });

  const loadOrderData = () => getLicenseOrderInfo(selectedCustomer)
    .then((response) => {
      if (response != null) {
        const tmpArray = addTableIds(response.licenses);
        setOrderData(tmpArray);
      }
    });

  const loadHistoryData = () => getLicenseHistoryInfo(customerData.domain_name, true)
    .then((response) => {
      if (response != null) {
        const tmpArray = addTableIds(mapHistoryData(response));
        setHistoryData(tmpArray);
      }
    });

  const isNotLoaded = (data) => {
    if (data === null) {
      return true;
    }

    return false;
  };

  const loadTableData = (label) => {
    switch (label) {
    case workspaceLabels[0]:
      if (isNotLoaded(existingData)) {
        loadExistingData();
      }
      break;
    case workspaceLabels[1]:
      if (isNotLoaded(orderData)) {
        loadOrderData();
      }
      break;
    case workspaceLabels[2]:
      if (isNotLoaded(historyData)) {
        loadHistoryData();
      }
      break;
    default:
      break;
    }
  };

  const toggleCreateLicense = () => {
    setDoCreateLicense(!doCreateLicense);
  };

  const tableContent = [
    isNotLoaded(existingData) ? <LoadingIndicator />
      : (
        <WorkspaceLicenseTable
        // TODO onRowClick history push
          columns={existingLicenseColumns}
          data={existingData}
          setSelectedLicense={setSelectedLicense}
          selectedView={selectedView}
          toggleCreateLicense={toggleCreateLicense}
          pushUrl='/workspacelicense/license'
        />
      ),
    isNotLoaded(orderData) ? <LoadingIndicator />
      : (
        <WorkspaceLicenseTable
          columns={orderLicenseColumns}
          data={orderData}
          setSelectedLicense={setSelectedLicense}
          toggleCreateLicense={toggleCreateLicense}
          pushUrl='/workspacelicense/order'
        />
      ),
    isNotLoaded(historyData) ? <LoadingIndicator />
      : (
        <WorkspaceLicenseTable
          columns={historyLicenseColumns}
          data={historyData}
          setSelectedLicense={() => null}
          toggleCreateLicense={toggleCreateLicense}
          options={{
            pageSize: 10
          }}
        />
      ),
  ];

  const clearData = () => {
    setSelectedLicense(null);
    setExistingData(null);
    setOrderData(null);
    setHistoryData(null);
    setDoUpdateTable(false);
  };

  const onTabClick = (label) => {
    switch (label) {
    case existing:
      setSelectedView(existing);
      break;
    case orders:
      setSelectedView(orders);
      break;
    case history:
      setSelectedView(history);
      break;
    default:
      break;
    }
  };

  useEffect(() => {
    if (doUpdateTable) {
      clearData();
    }
    loadTableData(selectedView);
  }, [doUpdateTable, []]);

  useEffect(() => {
    if ([existingData, orderData, historyData].some((data) => data)) {
      setDoUpdateTable(true);
    }
  }, [selectedCustomer]);

  if (selectedLicense !== null) {
    if (viewMode === VIEW_MODE.VIEW_LICENSE) {
      return <ViewLicensePage />;
    }
    if (viewMode === VIEW_MODE.VIEW_ORDER) {
      return <ViewOrderPage />;
    }
  }

  return (
    <>
      <TitleBar title='Workspace License' />
      <WorkspaceLicenseTabs
        transparent
        labels={workspaceLabels}
        content={tableContent}
        onTabClick={onTabClick}
      />
      <CreateLicense
        setDoCreateLicense={setDoCreateLicense}
        doCreateLicense={doCreateLicense}
        setDoUpdateTable={setDoUpdateTable}
      />
    </>
  );
};
export default WorkspaceLicense;
