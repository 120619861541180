import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import rbac from '../../../helpers/rbac';

const BillingViewers = () => {
  const history = useHistory();

  return rbac('alerts_*') && (
    <Box
      style={{
        marginRight: 5,
        cursor: 'pointer'
      }}
      onClick={() => history.push('billing/viewers')}
    >
      <Tooltip title='Viewers'>
        <VisibilityIcon />
      </Tooltip>
    </Box>
  );
};

export default BillingViewers;
