import React, { useEffect, useState, useContext } from 'react';
import TitleBar from '../../components/TitleBar';
import LoadingIndicator from '../../components/LoadingIndicator';
import firebaseApp from '../../firebase/Base';
import ProjectsTable from './ProjectsTable';
import { Context } from '../../components/ContextProvider';

const ProjectProvisioning = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { selectedCustomer } = useContext(Context);

  /**
   * Move to management api
   */
  const projectsData = () => {
    firebaseApp.firestore()
      .collection('projects')
      .where('customer_id', '==', selectedCustomer)
      .orderBy('project_name', 'asc')
      .get()
      .then((snap) => {
        const projects = [];

        snap.forEach((doc) => {
          projects.push({ ...doc.data(), id: doc.id });
        });

        setData(projects);
        setIsLoading(false);
      })
      .catch(() => {
        setData([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    projectsData();
  }, [selectedCustomer]);

  return (
    <>
      <TitleBar title='Project Provisioning' />
      {
        isLoading ? <LoadingIndicator /> : (
          <ProjectsTable projects={data} />
        )
      }
    </>
  );
};

export default ProjectProvisioning;
