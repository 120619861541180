import apiRequest from './apiRequest';

/**
 * Get Alert List
 * @param {string} customerId
 */
export const getAlerts = (customerId) => {
  const options = {
    url: `/v1/alerts/${customerId}/`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Delete Alert
 * @param {string} customerId
 * @param {Object} data
 */
export const deleteAlerts = (customerId, data) => {
  const options = {
    url: `/v1/alerts/${customerId}/`,
    method: 'DELETE',
    data
  };

  return apiRequest(options);
};

/**
 * Set Alert
 * @param {string} customerId
 * @param {Object} data
 */
export const setGroupByAlert = (customerId, data) => {
  const options = {
    url: `/v1/alerts/${customerId}/`,
    method: 'POST',
    data
  };

  return apiRequest(options);
};
