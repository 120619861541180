import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Typography, Divider
} from '@material-ui/core';
import colors from '../../helpers/colors';
import BarLoader from '../LoadingIndicator/BarLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 50,
    alignItems: 'center',
    marginBottom: 10
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  title: {
    color: colors.neutralGray,
    paddingRight: 14
  },
  subTitle: {
    color: colors.poppy
  },
  divider: {
    width: '100%',
    backgroundColor: '#EEEEEE'
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 2
  }
}));

const TitleBar = ({
  title,
  subTitle,
  updateIndicator,
  children
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
    >
      <Grid
        item
        xs={10}
        className={classes.container}
      >
        <Grid item>
          <Typography
            variant='h6'
            className={classes.title}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.subTitle}
          >
            {subTitle}
          </Typography>
        </Grid>
      </Grid>

      {
        children ? (
          <Grid item xs={2} className={classes.right}>
            {children}
          </Grid>
        ) : null
      }

      <Grid item xs={12}>
        {
          !updateIndicator && <Divider className={classes.divider} />
        }

        <BarLoader loading={updateIndicator} />
      </Grid>
    </Grid>
  );
};

TitleBar.defaultProps = {
  updateIndicator: false,
  children: null,
  subTitle: ''
};

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  updateIndicator: PropTypes.bool,
  subTitle: PropTypes.string,
  children: PropTypes.node
};

export default TitleBar;
