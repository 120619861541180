import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';
import Back from '../../../../components/Button/Back';
import TitleBar from '../../../../components/TitleBar';
import PlaceOrderCard from '../../components/PlaceOrderCard/PlaceOrderCard';
import ViewLicenseCard from '../../components/ViewLicenseCard/ViewLicenseCard';
import useWorkspaceLicenseStyles from '../../styles';

function ViewLicensePage() {
  const [orderInProgress, setOrderInProgress] = useState(false);

  const classes = useWorkspaceLicenseStyles();
  const slideLength = window.innerWidth / 6;

  const onOrderClick = () => {
    setOrderInProgress(!orderInProgress);
  };
  const location = useLocation();
  const { state: license } = location;
  return (
    <>
      <TitleBar subTitle={`Update: ${license.sku_name}`} title='Workspace License'>
        <Back path='/workspacelicense' />
      </TitleBar>
      <div className={classes.container}>
        <ViewLicenseCard
          license={license}
          orderInProgress={orderInProgress}
          slideDistance={slideLength}
          onOrderClick={onOrderClick}
        />
        <PlaceOrderCard
          license={license}
          orderInProgress={orderInProgress}
          slideDistance={slideLength}
        />
      </div>
    </>

  );
}

export default ViewLicensePage;
