import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import {
  MenuItem, ListItemIcon, ListItemText, FormControl, Select, Checkbox
} from '@material-ui/core';
import { useStyles, MenuProps } from '../../../../helpers/select';

const GroupSelector = ({
  label,
  options,
  selected,
  handleSettingsControl
}) => {
  const classes = useStyles();

  const [selectedGroup, setSelectedGroup] = useState(selected || options);
  const isAllSelected = options.length > 0 && options.length === selectedGroup.length;

  const handleChange = (value: Array) => {
    let updateValue = value;

    if (value[value.length - 1] === 'all') {
      updateValue = selectedGroup.length === options.length ? [] : options;
    }

    setSelectedGroup(updateValue);
    handleSettingsControl(updateValue);
  };

  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel id='selector-label'>{label}</InputLabel>
      <Select
        labelId='selector-label'
        label={label}
        multiple
        value={selectedGroup}
        onChange={(event) => handleChange(event.target.value)}
        renderValue={(value) => value.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value='all'
          classes={{
            root: isAllSelected ? classes.selectedAll : ''
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selectedGroup.length > 0 && selectedGroup.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem>
        {
          options.map((name) => (
            <MenuItem key={name} value={name} className={classes.listItem}>
              <Checkbox checked={selectedGroup.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

GroupSelector.defaultProps = {
  selected: null,
};

GroupSelector.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  handleSettingsControl: PropTypes.func.isRequired,
};

export default GroupSelector;
