/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tabs as MuiTabs, Tab, Typography, Paper
} from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import colors from '../../helpers/colors';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& .MuiTab-root': {
      maxWidth: 300
    }
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  titleIcon: {
    fontSize: 18,
    fontWeight: 'bold',
    verticalAlign: 'middle',
    marginRight: 2
  },
  tab: {
    '&.Mui-selected': {
      color: colors.poppy
    }
  },
  paper: {
    marginTop: 20,
    paddingTop: 10,
    // textAlign: 'center'
  },
  transparent: {
    backgroundColor: 'transparent'
  }
}));

const WorkspaceLicenseTabs = ({
  labels,
  content,
  transparent,
  onTabClick,
  tabsDisabled
}) => {
  const classes = useStyles();
  const tabClasses = { root: classes.tab };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = ({
    children,
    index,
  }) => (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && (
        <Paper
          className={clsx(classes.paper, transparent ? classes.transparent : null)}
          elevation={0}
          square
        >
          {children}
        </Paper>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      <MuiTabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable billing tab'
        TabIndicatorProps={{
          style: {
            background: colors.poppy
          }
        }}
      >
        {
          labels.map((label, index) => (
            <Tab
              key={labels[index]}
              classes={tabClasses}
              disabled={tabsDisabled}
              onClick={() => onTabClick(label)}
              label={(
                <Typography className={classes.title}>
                  <PlaylistAddCheckIcon className={classes.titleIcon} />
                  {label}
                </Typography>
              )}
            />
          ))
        }
      </MuiTabs>
      {
        content.map((item, index) => (
          <TabPanel
            value={value}
            key={`content-${labels[index]}`}
            index={index}
          >
            {item}
          </TabPanel>
        ))
      }
    </div>
  );
};

WorkspaceLicenseTabs.defaultProps = {
  transparent: false,
  onTabClick: () => null,
  tabsDisabled: false
};

WorkspaceLicenseTabs.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.arrayOf(PropTypes.node).isRequired,
  transparent: PropTypes.bool,
  onTabClick: PropTypes.func,
  tabsDisabled: PropTypes.bool
};

export default WorkspaceLicenseTabs;
