import { makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { TextValidator } from 'react-material-ui-form-validator';
import colors from '../../../helpers/colors';

const useWorkspaceLicenseStyles = makeStyles(() => ({
  orderContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 430px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 430px)',
    gridTemplateRows: '1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumn: 'span 2'
  },
  animatedContainer: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: 2,
  },
  paper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: '1rem',
    outline: 0,
    borderRadius: '6px',
  },
  primaryHeader: {
    display: 'flex',
    backgroundColor: colors.poppy,
    color: colors.pink,
    width: '100%',
    padding: '2rem',
    justifyContent: 'center',
    borderRadius: '5px 5px 0px 0px',
  },
  secondaryHeader: {
    display: 'flex',
    backgroundColor: colors.darkGray,
    color: colors.pink,
    width: '100%',
    padding: '2rem',
    justifyContent: 'center',
    borderRadius: '5px 5px 0px 0px',
  },
  cardHeader: {
    display: 'flex',
    gridColumn: 'span 2',
    color: colors.poppy,
    width: '100%',
    padding: '2rem',
    justifyContent: 'center',
  },
  orderHeader: {
    display: 'flex',
    gridColumn: 'span 2',
    color: colors.darkGray,
    width: '100%',
    padding: '2rem',
    justifyContent: 'center',
  },
  licenseInformation: {
    display: 'grid',
    gridColumn: 'span 2',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(5, 60px)',
    padding: '2rem',
    width: '100%',
  },
  orderInformation: {
    display: 'grid',
    gridColumn: 'span 2',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(3, 60px)',
    padding: '2rem',
    width: '95%',
  },
  licenseField: {
    display: 'flex',
    gridColumn: 'span 2',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
  updateButton: {
    width: '140px',
    height: '35px',
    marginTop: '40px',
    alignSelf: 'center',
    backgroundColor: colors.poppy,
    color: colors.pink,
    fontWeight: 'bolder',
  },
  orderButton: {
    width: '140px',
    height: '35px',
    marginTop: '40px',
    alignSelf: 'center',
    backgroundColor: colors.darkGray,
    color: colors.pink,
    fontWeight: 'bolder',
  },
  fullSpanButton: {
    marginTop: '40px',
    margin: '20px',
    backgroundColor: colors.darkGray,
    color: colors.pink,
    fontWeight: 'bolder',
  },
  manageOrderButton: {
    marginTop: '40px',
    backgroundColor: colors.darkGray,
    color: colors.pink,
    fontWeight: 'bolder',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gridColumn: 'span 2',
    width: '100%',
  },
  skuContainer: {
    display: 'flex',
  },
  twoButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridColumn: 'span 2',
    gridRowStart: '5'
  },
  oneButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumn: 'span 2',
    gridRowStart: '5'
  },
  skuNumber: {
    color: colors.poppy,
    marginLeft: '5px',
  }
}));

export const NumberValidationField = styled(TextValidator)({
  '& .MuiInputBase-input': {
  },
  '& label.Mui-focused': {
    color: '#FA4860',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FA4860',
  },
  '& .MuiOutlinedInput-root': {
    width: '10px',
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
});

export default useWorkspaceLicenseStyles;
