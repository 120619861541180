/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Context } from '../../components/ContextProvider';
import { cloudColumns, recommendationsColumns } from '../../helpers/inventory';

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 20
  },
}));

const Grids = ({
  cloudInventory,
  recommendations
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { selectedCustomer } = useContext(Context);
  const [selectedInventory, setSelectedInventory] = useState('');
  const [selectedRecommendations, setSelectedRecommendations] = useState('');

  const raiseSupport = (summary, description) => {
    history.push({
      pathname: '/supportrequest/new',
      state: {
        summary,
        description
      },
    });
  };

  const rowsSelectInventory = (currentRowsSelected, allRowsSelected) => {
    const items = [];
    allRowsSelected.map((item) => items.push(`${cloudInventory[item.dataIndex][5]} ${cloudInventory[item.dataIndex][4]}`));

    setSelectedInventory(`Inventory items: ${items.join(', ')}`);
  };

  const rowsSelectRecommendations = (currentRowsSelected, allRowsSelected) => {
    const items = [];
    allRowsSelected.map((item) => items.push(recommendations[item.dataIndex][5]));

    setSelectedRecommendations(`Google Cloud Recommendations: ${items.join(', ')}`);
  };

  const options = {
    filterType: 'checkbox',
    print: false,
  };

  return (
    <>
      <MUIDataTable
        title='Google Cloud Inventory'
        data={cloudInventory}
        columns={cloudColumns}
        options={{
          ...options,
          onRowSelectionChange: rowsSelectInventory,
          customToolbarSelect: () => (
            <Tooltip title='Raise Support Ticket'>
              <IconButton
                className={classes.iconButton}
                onClick={() => raiseSupport('Inventory investigation', selectedInventory)}
              >
                <AssignmentLateIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )
        }}
      />

      <div style={{ padding: 15 }} />

      <MUIDataTable
        title='Google Cloud Recommendations'
        data={recommendations}
        columns={recommendationsColumns}
        options={{
          ...options,
          onRowSelectionChange: rowsSelectRecommendations,
          customToolbarSelect: () => (
            <Tooltip title='Raise Support Ticket'>
              <IconButton
                className={classes.iconButton}
                onClick={() => raiseSupport('Recommendation investigation', selectedRecommendations)}
              >
                <AssignmentLateIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )
        }}
      />
    </>
  );
};

Grids.propTypes = {
  cloudInventory: PropTypes.arrayOf(PropTypes.array).isRequired,
  recommendations: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default Grids;
