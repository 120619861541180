import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Box, Typography, Paper, Button, Checkbox, ListItemText, ListItemIcon,
  ListItem, List, ListSubheader, // ListSubheader
} from '@material-ui/core';
import { checkLists } from '../../helpers/styles';
import { availableCustomers, intersection, not } from '../../helpers/users';

const useStyles = makeStyles((theme) => checkLists(theme));

const CustomersAccess = ({
  entities,
  access,
  setAccess,
  options
}) => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(availableCustomers(options, access));
  const [right, setRight] = React.useState(access);
  const [searchTerm, setSearchTerm] = React.useState('');

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  useEffect(() => {
    if (right !== access) {
      setAccess(right);
    }
  }, [right]);

  const customList = (items) => {
    const entityCustomers = {};
    items.map((item) => {
      const entity = item.entity ? entities[item.entity].name : 'Not Set';

      if (!entityCustomers[entity]) {
        entityCustomers[entity] = [];
      }

      return entityCustomers[entity].push(item);
    });

    return (
      <Paper elevation={0} className={classes.paper}>
        <List component='div' role='list'>
          <div>
            <input
              type='text'
              placeholder='Search customer...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={classes.searchInput}
            />
          </div>
          {
            Object.keys(entityCustomers).sort().map((entity, i) => (

              <li key={`section-${i}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader disableSticky>{entity}</ListSubheader>

                  {entityCustomers[entity].filter((customer) => customer.name.toLowerCase().includes(searchTerm.toLowerCase())).map((customer) => (
                    <ListItem
                      key={customer.id}
                      role='listitem'
                      button
                      onClick={handleToggle(customer)}
                      style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          className={classes.checkbox}
                          checked={checked.indexOf(customer) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': `transfer-list-item-${customer.id}-label` }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`transfer-list-item-${customer.id}-label`}
                        primary={`${customer.name} (${customer.id})`}
                        classes={{ primary: classes.checkboxLabel }}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>

            ))
          }
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      className={classes.root}
    >
      <Grid item>
        <Box mb={2}>
          <Typography variant='h6'>All customers</Typography>
        </Box>
        {customList(left)}
      </Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label='move all right'
          >
            ≫
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='move selected left'
          >
            &lt;
          </Button>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label='move all left'
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Box mb={2}>
          <Typography variant='h6'>Grant access</Typography>
        </Box>
        {customList(right)}
      </Grid>
    </Grid>
  );
};

CustomersAccess.defaultProps = {
  access: []
};

CustomersAccess.propTypes = {
  entities: PropTypes.objectOf(PropTypes.any).isRequired,
  access: PropTypes.arrayOf(PropTypes.object),
  setAccess: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CustomersAccess;
