import React, {
  useEffect, useState, useContext
} from 'react';
import TitleBar from '../../components/TitleBar';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import { jiraTicketsList } from '../../api/support';
import { tickets } from '../../helpers/support';
import RequestsTable from './RequestsTable';
import { hasAccess } from '../../helpers/rbac';

const SupportRequest = () => {
  const { selectedCustomer, customerData } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const supportData = () => {
    jiraTicketsList(selectedCustomer).then((response) => {
      const tickes = tickets(response);

      setData(tickes);
      setIsLoading(false);
    });
  };

  const reloadData = () => {
    setIsLoading(true);
    supportData();
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    supportData();
  }, [selectedCustomer]);

  return hasAccess('support') ? (
    <>
      <TitleBar title='Rapid Architecture Ticket' />
      {
        isLoading ? <LoadingIndicator /> : (
          <RequestsTable
            data={data}
            reload={() => reloadData()}
          />
        )
      }
    </>
  ) : (
    <TitleBar
      title='Rapid Architecture Ticket'
      subTitle={`for ${customerData.customer_name} is disabled`}
    />
  );
};

export default SupportRequest;
