import React, {
  useState, useEffect, useContext
} from 'react';
import { Context } from '../../../../components/ContextProvider';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { detailsProjectLabels } from '../../../../api/billing';
import { cacheName } from '../../../../helpers/utils';
import ChartMap from '../ChartMap';

const ChartProjectLabels = () => {
  const {
    selectedCustomer,
    settings,
    chartProjectLabels,
    updateContext,
    billingAccountId,
    billing
  } = useContext(Context);

  const cache = cacheName(`${selectedCustomer}-${billingAccountId}`, settings);
  const defaultLabel = Object.keys(billing.project_labels)[0];

  const [isLoading, setIsLoading] = useState(true);

  const getTotalData = () => {
    detailsProjectLabels(
      selectedCustomer,
      billingAccountId,
      settings,
      defaultLabel
    ).then((response) => {
      updateContext({
        chartProjectLabels: {
          ...chartProjectLabels,
          projectLabel: settings.projectLabel,
          [cache]: response
        }
      });
    });
  };

  useEffect(() => {
    if (
      chartProjectLabels
      && chartProjectLabels[cache]
      && chartProjectLabels.projectLabel === settings.projectLabel
    ) {
      setIsLoading(false);
    } else {
      getTotalData();
    }
  }, [billingAccountId]);

  return (
    isLoading ? <LoadingIndicator /> : (
      <ChartMap
        title={settings.projectLabel || defaultLabel}
        data={chartProjectLabels[cache]}
      />
    )
  );
};

export default ChartProjectLabels;
