import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper, TextField, FormControlLabel, Checkbox
} from '@material-ui/core';
import { form } from '../../helpers/styles';
import ViewTierDiscount from './ViewTierDiscount';
import TitleBar from '../../components/TitleBar';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  ...form,
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    cursor: 'pointer'
  }
}));

const View = () => {
  const classes = useStyles();

  const { customerData, selectedCustomer } = useContext(Context);

  const [state, setState] = useState({
    isLoading: true,
    customer: null
  });

  useEffect(() => {
    setState({
      isLoading: false,
      customer: customerData
    });
  }, [selectedCustomer]);

  const values = state.customer;

  const customerInfo = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h6'>
              Basic Information
            </Typography>
          </Box>

          <TextField
            className={classes.textField}
            fullWidth
            label='Customer Name'
            variant='outlined'
            value={values.customer_name}
            disabled
          />

          <TextField
            className={classes.textField}
            fullWidth
            label='Customer Id'
            variant='outlined'
            value={selectedCustomer}
            disabled
          />

          <Box mb={2}>
            <Typography variant='h6'>
              Billing dashboard
            </Typography>
          </Box>

          <TextField
            className={classes.textField}
            fullWidth
            label='Billing account Id'
            variant='outlined'
            value={values.billing_account_id}
            disabled
          />

          <TextField
            className={classes.textField}
            fullWidth
            label='Currency'
            variant='outlined'
            value={values.billing_currency || 'EUR'}
            disabled
          />

          <TextField
            className={classes.textField}
            fullWidth
            label='Dashboard type'
            variant='outlined'
            value={values.billing_dashboard_type || 'Standard'}
            disabled
          />
          {
            values.billing_dashboard_type === 'Set Discount' ? (
              <TextField
                className={classes.textField}
                fullWidth
                label='Discount (%)'
                variant='outlined'
                value={values.billing_dashboard_set_discount}
                disabled
              />
            ) : null
          }
          {
            values.billing_dashboard_type === 'Tier Discount' ? (
              <ViewTierDiscount
                rows={values.billing_dashboard_tier_discount || []}
              />
            ) : null
          }

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.support_request}
              />
            )}
            label='Rapid Architecture Ticket'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.billing_viewer}
              />
            )}
            label='Billing Viewer'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.gcp_service_account}
              />
            )}
            label='Service Account'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.inventory}
              />
            )}
            label='Inventory'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.system_health_dashboard}
              />
            )}
            label='System health'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.documentation}
              />
            )}
            label='Documentation'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.anomaly_detection}
              />
            )}
            label='Anomaly Detection'
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        {
          values.gcp_service_account || values.system_health_dashboard || values.documentation ? (
            <Paper elevation={0} className={classes.paper} style={{ marginBottom: 20 }}>
              {
                values.gcp_service_account && (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        GCP Service Account
                      </Typography>
                    </Box>

                    <TextField
                      className={classes.textField}
                      fullWidth
                      disabled
                      label='Service Account email'
                      variant='outlined'
                      value={values.service_account_email}
                      helperText='Only used for System Health, Inventory, etc.'
                    />
                  </>
                )
              }
              {
                values.system_health_dashboard && (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        System health dashboard
                      </Typography>
                    </Box>

                    <TextField
                      disabled
                      className={classes.textField}
                      fullWidth
                      label='Customized Grafana dashboard'
                      variant='outlined'
                      value={values.grafana_external_link || 'Built-in (default)'}
                    />
                  </>
                )
              }

              {
                values.documentation && (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        Documentation
                      </Typography>
                    </Box>

                    <TextField
                      disabled
                      className={classes.textField}
                      fullWidth
                      label='Customized Google Drive folder'
                      variant='outlined'
                      value={values.drive_id || 'Built-in (default)'}
                    />
                  </>
                )
              }
            </Paper>
          ) : null
        }
      </Grid>
    </Grid>
  );

  return (
    <>
      <TitleBar title='Customer Settings' />
      {
        state.isLoading ? <LoadingIndicator /> : (
          customerInfo()
        )
      }
    </>
  );
};

export default View;
