import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Typography, Grid, Box } from '@material-ui/core';
import Table from '../../components/Table';
import AddBox from '../../components/Table/AddBox';
import colors from '../../helpers/colors';

const styles = {
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginBottom: 10
  },
  title: {
    fontWeight: 'bold',
    color: colors.poppy
  },
  icon: {
    marginRight: 5,
    fontSize: '22px',
    color: colors.poppy
  },
};

const CustomersTable = ({
  data,
  entities
}) => {
  const history = useHistory();
  const { users, customers } = data;

  const newCustomer = () => {
    history.push('/customermanagement/new');
  };

  const updateCustomer = (id) => {
    history.push(`/customermanagement/update/${id}`);
  };

  const entitiesFilter = () => {
    const lookup = {};

    Object.keys(entities).forEach((entity) => {
      lookup[entity] = entities[entity].name;
    });
    return lookup;
  };

  /**
   * Users List
   */
  const renderUsers = (value) => {
    if (Array.isArray(value) && value.length) {
      const usersArray = {
        admin: [],
        customer: [],
        customerViewer: [],
        notfound: []
      };

      value.sort().map((user) => (
        (
          users[user]
          && usersArray[users[user].role]
        ) ? usersArray[users[user].role].push(user) : usersArray.notfound.push(user)
      ));

      const list = (title, ul, color = '') => (ul && ul.length ? (
        <div>
          <b style={{ color }}>{title}</b>
          <ul>
            {ul.map((user) => (
              <li
                key={`id:${user}`}
                style={{
                  listStyleType: 'square',
                  padding: 2,
                  color
                }}
              >
                {user}
              </li>
            ))}
          </ul>
        </div>
      ) : null);

      return (
        <>
          {list('Admin', usersArray.admin)}
          {list('Customer', usersArray.customer)}
          {list('Customer Viewer', usersArray.customerViewer)}
          {list('User or Role not found', usersArray.notfound, 'red')}
        </>
      );
    }

    return (
      <Typography>
        No Users
      </Typography>
    );
  };

  const rendertitle = (text, icon) => (
    <div style={styles.wrapIcon}>
      {icon}
      <Typography style={styles.title}>
        {text}
      </Typography>
    </div>
  );

  const costumerDetails = (customer) => (
    <Box p={4}>
      <Grid container justifyContent='center' spacing={6}>
        <Grid item>
          {rendertitle('User Access', <SupervisorAccountIcon style={styles.icon} />)}
          {renderUsers(customer.users)}
        </Grid>
        <Grid item>
          {rendertitle('Notify', <ContactSupportIcon style={styles.icon} />)}
          {renderUsers(customer.notify)}
        </Grid>
        <Grid item>
          {rendertitle('Alert', <NotificationImportantIcon style={styles.icon} />)}
          {renderUsers(customer.alert)}
        </Grid>
        <Grid item>
          {rendertitle('Billing Viewer', <CreditCardIcon style={styles.icon} />)}
          {renderUsers(customer.filters)}
        </Grid>
      </Grid>
    </Box>
  );

  const renderBillingAccounts = (value) => {
    if (value && value.billing_account_id) {
      const accounts = value.billing_account_id.split(',');

      if (accounts.length === 1) {
        return value.billing_account_id;
      }

      return (
        <ul>
          {accounts.map((account) => (
            <li
              key={`id:${account}`}
              style={{
                listStyleType: 'square',
                padding: 2
              }}
            >
              {account}
            </li>
          ))}
        </ul>
      );
    }

    return (<span style={{ color: 'red' }}>Not Set</span>);
  };

  return (
    <Table
      title='Customers'
      data={customers}
      columns={[
        {
          title: 'Name',
          field: 'customer_name',
          width: '100%',
          removable: false
        },
        {
          field: 'customer_id',
          title: 'Id',
          removable: false
        },
        {
          field: 'entity',
          title: 'Entity',
          removable: false,
          lookup: entitiesFilter(),
          render: (value) => {
            const { entity } = value;

            if (entities[entity] && entities[entity].name) {
              return entities[value.entity].name;
            }

            return (<span style={{ color: 'red' }}>Not Set</span>);
          },
        },
        {
          field: 'billing_account_id',
          title: 'Billing Account Id',
          removable: false,
          render: (value) => renderBillingAccounts(value),
        },
        {
          field: 'billing_currency',
          title: 'Currency',
          align: 'right',
          lookup: {
            EUR: 'EUR',
            GBP: 'GBP',
            USD: 'USD',
            DKK: 'DKK',
          }
        },
        {
          field: 'support_request',
          title: 'Rapid Architecture Ticket',
          align: 'right',
          filtering: false,
          type: 'boolean'
        }
      ]}
      actions={[
        {
          icon: (() => <AddBox title='New' />),
          onClick: () => newCustomer(),
          isFreeAction: true
        }
      ]}
      options={{
        filtering: true,
        columnsButton: true
      }}
      onRowClick={(evt, rowData) => updateCustomer(rowData.customer_id)}
      detailPanel={[{
        tooltip: 'Show Details',
        render: (rowData) => costumerDetails(rowData)
      }]}
    />
  );
};

CustomersTable.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  entities: PropTypes.objectOf(PropTypes.any).isRequired
};

export default CustomersTable;
