import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Context } from '../ContextProvider';
import log from '../../helpers/log';
import FallBack from './FallBack';
import logging from '../../firebase/logging';

export default class ErrorBoundary extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    log.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { user } = this.context;
    // You can also log the error to an error reporting service
    logging(user.claims, errorInfo.componentStack, error.message);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <FallBack />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};
