import React from 'react';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer, List, ListItem, ListItemText, ListItemIcon, Typography, Divider, Tooltip,
} from '@material-ui/core';
import colors from '../../../../helpers/colors';
import Controls from './Controls';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%'
  },
  settingsTitle: {
    color: colors.poppy,
    padding: 4
  },
  list: {
    width: 340,
    height: '100%'
  },
  divider: {
    backgroundColor: 'whitesmoke'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: colors.poppy,
    cursor: 'pointer'
  },
  icon: {
    cursor: 'pointer'
  }
}));

const Settings = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (value: boolean) => (event: Object) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(value);
  };

  return (
    <div className={classes.container}>
      <Tooltip title='Settings'>
        <TuneIcon
          className={classes.icon}
          onClick={toggleDrawer(true)}
        />
      </Tooltip>

      <Drawer
        anchor='right'
        open={open}
        onClose={toggleDrawer(false)}
      >
        <div
          className={classes.list}
          role='presentation'
        >
          <List>
            <ListItem>
              <ListItemText
                primary={(
                  <Typography variant='h5' className={classes.settingsTitle}>
                    Settings
                  </Typography>
                )}
              />
              <ListItemIcon
                className={classes.closeIcon}
                onClick={toggleDrawer(false)}
              >
                <CloseIcon />
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.divider} />
          </List>
          <Controls close={() => setOpen(false)} />
        </div>
      </Drawer>
    </div>
  );
};

export default Settings;
