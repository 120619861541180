import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import colors from '../../helpers/colors';

const Back = ({
  path,
  text,
}) => {
  const history = useHistory();

  return (
    <Button
      onClick={() => history.push(path)}
      variant='outlined'
      component='span'
      size='small'
      startIcon={<ArrowBackIcon />}
      style={{
        color: colors.neutralGray,
        borderColor: colors.neutralGray
      }}
    >
      {text}
    </Button>
  );
};

Back.defaultProps = {
  text: 'back',
};

Back.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default Back;
