import axios from 'axios';
import { headers } from './apiRequest';

/**
 * Save File to given url
 */
export const saveFile = (
  urlAttachment: string,
  file: Object,
) => {
  const formData = new FormData();
  formData.append('file', file.file);

  return axios.post(urlAttachment, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

/**
 * Save File to given url
 */
export const saveAttachmentToCloudStorage = (
  urlAttachment: string,
  file: Object,
) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(urlAttachment, formData, {
    headers: {
      'Content-Type': file.type
    }
  });
};

/**
 * Get File from url
 */
export const getFile = (url: string) => axios.get(url, {
  headers: headers(),
  responseType: 'blob',
});

/**
 * Get File from url
 */
export const deleteFile = (url: string) => axios.delete(url, {
  headers: headers()
});
