import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Grid, Paper, Divider, FormControl, InputLabel,
  IconButton, OutlinedInput, InputAdornment, Typography
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '../../components/Button';
import colors from '../../helpers/colors';
import Table from '../../components/Table/SimpleTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20
  },
  paper: {
    height: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttons: {
    width: '100%',
  },
  textField: {
    width: '100%',
    marginBottom: 20
  },
  divider: {
    padding: 10,
    backgroundColor: 'white'
  },
  table: {
    width: '100%',
  },
  title: {
    color: colors.neutralGray,
    width: '100%',
    textAlign: 'left',
    marginBottom: 10
  },
  subTitle: {
    marginBottom: 0
  },
  service: {
    marginBottom: 10,
    color: colors.mediumGray
  }
}));

const Grafana = ({
  showInfo,
  data,
  initDashboard,
  rebuildDashboard
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const { customer, projects, password } = data;
  const url = customer.grafana_external_link || customer.grafana_link;

  const initForm = () => (
    <>
      <FormControl className={classes.textField} variant='outlined'>
        <InputLabel htmlFor='outlined-adornment-password'>Grafana user ID</InputLabel>
        <OutlinedInput
          disabled
          value={customer.customer_name}
          label='Grafana user Id'
          endAdornment={(
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                edge='end'
              >
                <AccountCircleIcon />
              </IconButton>
            </InputAdornment>
          )}
          labelWidth={70}
        />
      </FormControl>

      <FormControl className={classes.textField} variant='outlined'>
        <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
        <OutlinedInput
          disabled
          type={showPassword ? 'text' : 'password'}
          label='Password'
          value={password}
          endAdornment={(
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword(!showPassword)}
                edge='end'
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
          labelWidth={70}
        />
      </FormControl>

      <Box className={classes.buttons}>
        <Button
          backgroundColor={colors.neutralGray}
          onClick={initDashboard}
        >
          Initialize Dashboard
        </Button>

        <Divider className={classes.divider} />

        <Button
          backgroundColor={colors.poppy}
          onClick={rebuildDashboard}
        >
          Rebuild Dashboard
        </Button>
      </Box>
    </>
  );

  const projectsTable = () => (
    <Box className={classes.table}>
      <Table
        data={projects}
        columns={[
          {
            title: 'Project Name',
            field: 'project_name',
          },
          {
            title: 'Project Id',
            field: 'project_id',
          }
        ]}
      />
    </Box>
  );

  return (
    <>
      {
        showInfo || !url ? (
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} styles={{ height: '100%' }}>
                <Paper elevation={0} className={classes.paper}>
                  {initForm()}
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper elevation={0} className={clsx(classes.paper, classes.tableContainer)}>
                  <Typography className={clsx(classes.title, classes.subTitle)}>
                    Service Account:
                  </Typography>
                  <Typography className={clsx(classes.title, classes.service)}>
                    {customer.service_account_email}
                  </Typography>
                  <Typography className={classes.title}>
                    Access to GCP Projects
                  </Typography>
                  {projectsTable()}
                </Paper>
              </Grid>
            </Grid>
          </div>
        ) : null
      }
      <Box width={1} height={710}>
        <iframe
          scrolling='auto'
          frameBorder='0'
          height='100%'
          width='100%'
          title='grafana'
          src={`${url}&kiosk=true`}
        />
      </Box>
    </>
  );
};

Grafana.propTypes = {
  showInfo: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  initDashboard: PropTypes.func.isRequired,
  rebuildDashboard: PropTypes.func.isRequired
};

export default Grafana;
