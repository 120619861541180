import React from 'react';
import colors from './colors';

/**
 * Accepted Files type
 */
export const acceptedFiles = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'text/plain',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

/**
 * Status List
 */
export const statusList = [
  'Open',
  'In Progress',
  'Resolved',
  'Closed',
  'Cancel'
];

/**
 * satisfaction List
 */
export const satisfactionList = {
  1: 'Very disappointed',
  2: 'Disappointed',
  3: 'Neutral',
  4: 'Satisfied',
  5: 'Very satisfied'
};

/**
 * Satisfaction Levels
 */
export const satisfactionLevel = [
  {
    value: 1,
    name: 'Very disappointed'
  },
  {
    value: 2,
    name: 'Disappointed'
  },
  {
    value: 3,
    name: 'Neutral'
  },
  {
    value: 4,
    name: 'Satisfied'
  },
  {
    value: 5,
    name: 'Very satisfied'
  },
];

/**
 * satisfaction Filter
 */
export const satisfactionFilter = {
  '(1) Very disappointed': 'Very disappointed',
  '(2) Disappointed': 'Disappointed',
  '(3) Neutral': 'Neutral',
  '(4) Satisfied': 'Satisfied',
  '(5) Very satisfied': 'Very satisfied',
  'To be provided': 'To be provided'
};

/**
 * priority List
 */
export const priorityFilter = {
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4'
};

/**
 * priority Filter
 */
export const statusFilter = {
  Open: 'Open',
  'In Progress': 'In Progress',
  Resolved: 'Resolved',
  Closed: 'Closed',
  Cancel: 'Cancel',
};

/**
 * Jira Limits
 */
export const jiraLimits = {
  comment: 30000,
  description: 30000,
  summary: 100
};

/**
 * Priority List
 */
export const prioritylist = {
  priorities: [
    'P1',
    'P2',
    'P3',
    'P4'
  ],
  detailed_priorities: [
    {
      name: 'P1',
      description: 'P1 (End user-facing production service is entirely down)'
    },
    {
      name: 'P2',
      description: 'P2 (Production service is partially broken or development is blocked)'
    },
    {
      name: 'P3',
      description: 'P3 (Development is partially blocked or investigating cause of a previous production issue)'
    },
    {
      name: 'P4',
      description: 'P4 (Feature requests or questions about how to use GCP services)'
    }
  ]
};

/**
 * Support Request Colors
 */
export const supportColors = {
  Open: 'red',
  Resolved: 'green',
  Closed: 'green',
  Cancel: 'grey',
};

/**
 * Support Color
 */
export const supportColor = (status) => {
  const color = supportColors[status];
  return color || colors.neutralGray;
};

export const diffHours = (dt1, dt2) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
};

export const diffMinutes = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);

  const diff = dt2.getTime() - dt1.getTime();
  return diff / 60000;
};

/**
 * Support Tikets List
 */
export const tickets = (data: Object) => data.map((ticket) => {
  let firstResponse = ticket.first_response_date;
  let stime = null;
  let statusColor = '';
  let satisfactionColor = '';

  const score = Number(ticket.customer_satisfaction_score);

  const time = diffMinutes(ticket.creation_date, ticket.first_response_date);

  if (time < 60) stime = '< 1h';
  else if (time < 240) stime = '< 4h';
  else if (time < 1440) stime = '< 24h';
  else if (time < 2880) stime = '< 48h';
  else {
    const hour = Math.round(time / 60);
    stime = `${hour}h`;
  }

  if (ticket.status === 'Open') {
    statusColor = '#ff0000';
  } else if (ticket.status === 'Closed') {
    statusColor = '#009965';
  }

  if (score === 1 || score === 2) {
    satisfactionColor = '#ff0000';
  } else if (score > 3) {
    satisfactionColor = '#009965';
  }

  if (firstResponse) {
    firstResponse = stime;
  } else {
    firstResponse = '';
  }

  let satisfaction = satisfactionList[score];
  if (satisfaction) {
    satisfaction = `(${ticket.customer_satisfaction_score}) ${satisfaction}`;
  } else if (ticket.status === 'Closed') {
    satisfaction = 'To be provided';
  }

  let lastModified = '';
  const dtc = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
  };
  if (ticket.update_date) {
    lastModified = new Date(ticket.update_date)
      .toLocaleString('en-GB', dtc)
      .replace(',', '');
  } else {
    lastModified = new Date(ticket.creation_date)
      .toLocaleString('en-GB', dtc)
      .replace(',', '');
  }

  return {
    id: parseInt(ticket.jira_issue_key, 10) || ticket.jira_issue_key,
    summary: ticket.summary,
    priority: ticket.priority,
    status: ticket.status,
    assignee: ticket.assignee,
    lastModified,
    firstResponse,
    satisfaction,
    statusColor,
    satisfactionColor
  };
});

/**
 * First Response calc
 */
export const firstResponseCalc = (
  created: string,
  firstResponse: string
) => {
  if (firstResponse) {
    const time = diffMinutes(created, firstResponse);

    let stime = '';
    if (time < 60) stime = '< 1h';
    else if (time < 240) stime = '< 4h';
    else if (time < 1440) stime = '< 24h';
    else if (time < 2880) stime = '< 48h';
    else {
      const hour = Math.round(time / 60);
      stime = `${hour}h`;
    }

    return stime;
  }

  return '-';
};

/**
 * Satisfaction Score
 */
export const satisfactionScore = (score: number) => {
  let color = 'red';

  if (score >= 4) {
    color = 'green';
  } else if (score >= 3 && score < 4) {
    color = '#FFCA33';
  }

  return (
    <span style={{ color }}>
      {`(${score}) ${satisfactionList[score]}`}
    </span>
  );
};
