import storage from './storage';

/**
 * Set Storage Settings value
 */
export const setSelectedCustomer = (value: Object) => {
  storage.set('selectedCustomer', value);
};

/**
 * Get Storage selectedCustomer value
 */
export const selectedCustomer = () => storage.get('selectedCustomer');

export const customersMap = (customers) => {
  const map = {};
  customers.forEach((customer) => {
    map[customer.customer_id] = customer;
  });

  return map;
};

/**
 * Find Selected Customer Data
 */
export const customerData = (
  customers: Object,
  selected: string,
  search: string = 'customer_id'
) => customers.find(
  (element) => element[search] === selected
);

/**
 * Customer Accounts
 * @param {string} billingAccount
 * @return Object
 */
export const customerAccounts = (billingAccount) => {
  const billingAccounts = billingAccount.split(',');

  return {
    billingAccounts,
    billingAccountId: billingAccounts[0]
  };
};

/**
 * Costumers Helper
 */
export const selectedCustomers = (customers) => {
  const data = {
    selectedCustomer: '',
    billingAccountId: '',
    customerData: null,
    billingAccounts: null,
  };

  if (customers && customers.length > 0) {
    const storedCostumer = selectedCustomer();
    const storedCostumerData = customerData(customers, storedCostumer);
    if (storedCostumer && storedCostumerData) {
      data.customerData = storedCostumerData;
      data.selectedCustomer = storedCostumer;
    } else {
      const select = customers[0];
      data.customerData = select;
      data.selectedCustomer = select.customer_id;
      setSelectedCustomer(select.customer_id);
    }

    if (data.customerData.billing_account_id) {
      const {
        billingAccountId,
        billingAccounts
      } = customerAccounts(data.customerData.billing_account_id);

      data.billingAccounts = billingAccounts;
      data.billingAccountId = billingAccountId;
    }
  }
  return data;
};

/**
 * User Customers
 */
export const userCustomers = (
  email: string,
  customers: Array
) => {
  const list = [];

  customers.forEach((customer) => {
    if (customer.users && customer.users.includes(email)) {
      list.push({
        id: customer.id,
        name: customer.customer_name,
        entity: customer.entity
      });
    }
  });

  return list;
};

/**
 * Validate if user has customers
 */
export const validateCustomers = (customers) => {
  if (customers.length === 0) {
    return false;
  }

  return true;
};

/**
 * Validate Drive Id
 */
export const validateDriveId = (driveId) => driveId.replace(
  /https:\/\/drive.google.com\/drive\/folders\//g,
  ''
);

/**
 * Selected users table data
 */
export const tableData = (selected, users) => {
  const table = {
    columns: [
      {
        title: 'Email',
        field: 'id',
      },
      {
        title: 'Role',
        field: 'role',
        align: 'right'
      },
    ],
    data: []
  };

  if (selected) {
    selected.sort().map((user) => {
      const userValue = users.find((search) => (search.id === user));

      if (userValue) {
        return table.data.push(userValue);
      }

      return table.data.push({
        id: user,
        role: 'user not found'
      });
    });
  }

  return table;
};

/**
 * Selected users filters table data
 */
export const tableFiltersData = (selected, users) => {
  const table = {
    data: []
  };

  if (selected) {
    selected.sort().map((user) => {
      const userValue = users.find((search) => (search.id === user));

      if (userValue) {
        return table.data.push(userValue);
      }

      return table.data.push({
        id: user,
        role: 'user not found'
      });
    });
  }

  return table;
};

/**
 * Customer users options data for access and notification
 */
export const customerUsers = (userList, users) => {
  const usersArray = [];

  if (users) {
    Object.keys(users).sort().forEach((user) => {
      if (!userList || !userList.includes(users[user].id)) {
        usersArray.push(users[user]);
      }
    });
  }

  return usersArray;
};

export const entityCustomers = (entities, customers) => customers.map((option) => ({
  entityName: option.entity ? entities[option.entity].name : 'No Entity',
  ...option
}));

export const currencies = ['EUR', 'GBP', 'USD', 'DKK'];
