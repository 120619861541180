import moment from 'moment';
import 'moment-timezone';

const format = 'YYYY-MM-DD';

export const timeZone = 'US/Pacific';

export const currentTimeZone = () => moment.tz(new Date(), timeZone).format('YYYY/MM/DD HH:mm');

/**
 * Date time now formated
 */
export const dateNowMoment = (formatString = format) => moment().format(formatString);

/**
 * Date formated
 * @param {string} date
 */
export const dateMoment = (date: string, formated: string = 'YYYY') => moment(date).format(formated);

/**
 * Date formated Now add days
 * @param {string} date
 */
export const dateMomentAddDays = (days: number) => moment().add(days, 'days').format(format);

/**
 * Date formated Now subtract days
 * @param {string} date
 */
export const dateMomentSubtractDays = (
  subtract: number,
  formatString: string = format
) => moment().subtract(subtract, 'days').format(formatString);

/**
 * Date formated Now subtract months
 * @param {string} date
 */
export const dateMomentSubtractMounth = (
  subtract: number,
  formatString: string = format
) => moment().subtract(subtract, 'months').format(formatString);

/**
 * Date Range based on settings
 * Billing data from_to
 */
export const dateRange = (settings: Object) => {
  if (settings.selectedDate === '7days') {
    return `${dateMomentSubtractDays(7)}_to_${dateMomentSubtractDays(1)}`;
  }

  if (settings.selectedDate === '30days') {
    return `${dateMomentSubtractDays(30)}_to_${dateMomentSubtractDays(1)}`;
  }

  if (settings.selectedDate === '12months') {
    return `${dateMomentSubtractMounth(11, 'YYYY-MM')}_to_${dateMomentSubtractDays(1, 'YYYY-MM')}`;
  }

  return `${settings.startDate}_to_${settings.endDate}`;
};
