import React, {
  useEffect, useState, useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TitleBar from '../../components/TitleBar';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import Grids from './Grids';
import firebaseApp from '../../firebase/Base';
import { form } from '../../helpers/styles';

const useStyles = makeStyles(() => (form));

const Inventory = () => {
  const history = useHistory();
  const classes = useStyles();

  const { selectedCustomer, customerData } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [cloudInventory, setCloudInventory] = useState([]);
  const [recommendations, setRecommendations] = useState([]);

  const visualize = () => {
    history.push('/inventory/view');
  };

  const validateAccess = () => {
    if (!isLoading) {
      setIsLoading(true);
    }

    firebaseApp.firestore().collection('asset-inventory')
      .where('customer_id', '==', selectedCustomer)
      .get()
      .catch(() => {
        setCloudInventory([]);
        setRecommendations([]);
        setIsLoading(false);
      });
  };

  const gcpInventoryData = () => firebaseApp.firestore().collection('asset-inventory')
    .where('customer_id', '==', selectedCustomer)
    .orderBy('asset_name')
    .get()
    .then((response) => {
      const data = response.docs.map((doc) => {
        const d = doc.data();

        return [
          d.asset_type,
          d.asset_name,
          d.project_id,
          d.region, d.violation_rule,
          doc.id,
        ];
      });

      setCloudInventory(data);
      setIsLoading(false);
    });

  const recomendationsData = () => firebaseApp.firestore().collection('recommendation')
    .where('customer_id', '==', selectedCustomer)
    .orderBy('project_id')
    .get()
    .then((response) => {
      const data = response.docs.map((doc) => {
        const d = doc.data();

        return [
          d.primary_impact_category,
          d.recommender_subtype,
          d.project_id,
          d.location,
          d.target_resources,
          doc.id,
        ];
      });

      setRecommendations(data);
      setIsLoading(false);
    });

  useEffect(() => {
    if (customerData.inventory) {
      validateAccess();
      gcpInventoryData();
      recomendationsData();
    }
  }, [selectedCustomer]);

  return (
    customerData.inventory ? (
      <>
        <TitleBar title='Inventory'>
          <Button
            className={classes.button}
            onClick={() => visualize()}
            variant='outlined'
            component='span'
            size='small'
            startIcon={<BlurOnIcon />}
          >
            Visualize Inventory
          </Button>
        </TitleBar>
        {
          isLoading ? <LoadingIndicator /> : (
            <Grids
              cloudInventory={cloudInventory}
              recommendations={recommendations}
            />
          )
        }
      </>
    ) : (
      <TitleBar
        title='Inventory'
        subTitle={`for ${customerData.customer_name} is disabled`}
      />
    )
  );
};

export default Inventory;
