import React from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon, Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { DropzoneDialog } from 'material-ui-dropzone';
import { AttachFile } from '@material-ui/icons';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { saveAs } from 'file-saver';
import { acceptedFiles } from '../../helpers/support';
import colors from '../../helpers/colors';
import { getFile, saveAttachmentToCloudStorage } from '../../api/files';
import { addAttachementTicket, deleteAttachment, signedUrlAttachment } from '../../api/support';
import log from '../../helpers/log';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiListItem-root': {
      padding: 0,
      paddingTop: 2
    }
  },
  item: {
    cursor: 'pointer',
  },
  button: {
    marginTop: 10,
    color: colors.poppy,
    borderColor: colors.poppy
  }
}));

const Attachments = ({
  data,
  updateIndicator,
  setUpdateIndicator,
  reload
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const downloadAttachment = (attachment) => {
    getFile(attachment.url).then((file) => {
      saveAs(file.data, attachment.name);
    }).catch(() => {
      log.error('Error downloading file');
    });
  };

  const removeAttachmentFromTicket = (attachment) => {
    if (confirm('Are you sure you want to delete this attachment?')) {
      setUpdateIndicator(true);

      deleteAttachment(data, attachment).finally(() => {
        reload('Attachment deleted');
      });
    }
  };

  const addAttachmentToTicket = (files) => {
    const attachmentFiles = [];

    files.forEach((file) => {
      attachmentFiles.push(
        new Promise((resolveFile) => {
          signedUrlAttachment(file.name).then((uploadedFile) => {
            saveAttachmentToCloudStorage(uploadedFile.signed_url, file);
            resolveFile(uploadedFile.generated_name);
          });
        })
      );
    });

    Promise.all(attachmentFiles).then((attachments) => {
      attachments.forEach((name) => {
        addAttachementTicket(data.jira_issue_key, { name });
      });
    }).then(() => {
      setTimeout(() => {
        reload('Attachment saved');
      }, 3000);
    });
  };

  const saveFiles = (files) => {
    setOpen(false);
    setUpdateIndicator(true);
    addAttachmentToTicket(files);
  };

  return (
    <div className={classes.root}>
      <List component='nav'>
        {data.attachments.map((attachment) => (
          <ListItem key={attachment.url} button>
            <ListItemIcon>
              <AttachmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={attachment.name}
              onClick={() => downloadAttachment(attachment)}
              className={classes.item}
              key={attachment.name}
            />
            {
              data.status !== 'Closed' && data.status !== 'Cancel' ? (
                <ListItemSecondaryAction>
                  <IconButton
                    disabled={updateIndicator}
                    edge='end'
                    aria-label='delete'
                    onClick={() => removeAttachmentFromTicket(attachment)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null
            }
          </ListItem>
        ))}
      </List>
      {
        data.status !== 'Closed' && data.status !== 'Cancel' ? (
          <div>
            <Button
              disabled={updateIndicator}
              className={classes.button}
              variant='outlined'
              component='span'
              size='small'
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
            >
              Add file
            </Button>
            <div>
              <DropzoneDialog
                open={open}
                onSave={saveFiles}
                acceptedFiles={acceptedFiles}
                showPreviews={false}
                maxFileSize={5000000}
                onClose={() => setOpen(false)}
                filesLimit={10}
                Icon={AttachFile}
                showPreviewsInDropzone
                showFileNames
                alertSnackbarProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  }
                }}
              />
            </div>
          </div>
        ) : null
      }

    </div>
  );
};

Attachments.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired,
  updateIndicator: PropTypes.bool.isRequired,
  setUpdateIndicator: PropTypes.func.isRequired
};

export default Attachments;
