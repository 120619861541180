import apiRequest from './apiRequest';
import { dateMomentSubtractDays } from '../helpers/date';

/**
 * Get Billing Anomalies List
 * @param {string} customerId
 * @param {string} billingAccountId
 * @param {int} latestDailySpend
 */
const getAnomalies = async (customerId,
  billingAccountId,
  minimumAnomalyThreshold,
  minimumValueToggleAnomaly) => {
  // subtract two days from date, the billing data is only eventually consistent
  const twoDaysAgo = dateMomentSubtractDays(2, 'YYYY-MM-DD');
  const options = {
    url: `/v1/anomalies/customer_id/${customerId}/billing_account_id/${billingAccountId}/date/${twoDaysAgo}/minimum_anomaly_threshold/${minimumAnomalyThreshold}/minimum_value_toggled_anomaly/${minimumValueToggleAnomaly}`,
    method: 'GET',
  };

  return apiRequest(options, false);
};

export default getAnomalies;
