export const checkParseNum = (toBeInteger) => {
  if (toBeInteger !== undefined && typeof toBeInteger === 'string') {
    if (toBeInteger.match(/^\d+$/)) { // If string can be converted to Integer
      return true;
    }
  }
  if (typeof toBeInteger === 'number') {
    return true;
  }
  return false; // No
};

export const checkError = (input) => {
  if (!checkParseNum(input)) {
    return true;
  }
  if ((checkParseNum(input) && input <= 0) || (!checkParseNum(input) && input <= 0)) {
    return true;
  }
  return false;
};

export const errorText = (input) => {
  let errorMsgTxt;
  if (!checkParseNum(input)) {
    errorMsgTxt = 'Invalid input';
    return errorMsgTxt;
  }
  if ((checkParseNum(input) && input <= 0) || (!checkParseNum(input) && input <= 0)) {
    errorMsgTxt = 'Must be above 0';
    return errorMsgTxt;
  }
  return '';
};
