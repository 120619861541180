import { numberFormat } from './utils';

/**
 * Tier Table data
 */
export const tierTable = (customer: Object) => {
  const table = {
    tiers: customer.billing_dashboard_type === 'Tier Discount',
    columns: [],
    data: [],
  };

  if (table.tiers) {
    table.columns = [
      {
        title: 'Spend',
        field: 'spend',
        with: '50px'
      },
      {
        title: '%',
        field: 'discount',
        type: 'numeric',
      },
    ];

    customer.billing_dashboard_tier_discount.forEach((tier) => {
      table.data.push({
        spend: tier.threshold_spend,
        discount: `${tier.percentage}%`,
      });
    });
  }

  return table;
};

/**
 * Table data
 * 7 and 30 days
 */
export const tableDataDays = (data: Object) => {
  const month = data.month.split('&')[0];

  const table = {
    columns: [],
    data: []
  };

  table.columns = [
    {
      title: data.discount ? `Discount (${data.discount}%)` : `Tier Discount (${data.reached_tier}%)`,
      field: 'key'
    },
    {
      title: month,
      field: 'month',
      type: 'numeric'
    },
    {
      title: `${month} Forecast`,
      field: 'forecast',
      type: 'numeric'
    }
  ];

  table.data = [
    {
      key: 'Actual Spend',
      month: numberFormat(data.monthly_spend),
      forecast: numberFormat(data.forecasted_monthly_spend),
    },
    {
      key: 'Invoice (with discount)',
      month: numberFormat(data.discounted_spend),
      forecast: numberFormat(data.forecasted_discounted_spend),
    }
  ];

  return table;
};

/**
 * Table Data 12 months
 */
export const tableDataMonths = (data: Array) => {
  const cellStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  const table = {
    columns: [{
      title: '',
      field: 'key',
      cellStyle
    }],
    data: [
      { key: 'Actual Spend' },
      { key: 'Discount' },
      { key: 'Invoice (with discount)' },
    ]
  };

  data.forEach((row) => {
    const forecast = row.month.search('forecast') !== -1;
    const discount = row.discount || row.reached_tier || row.forecasted_reached_tier;

    table.columns.push({
      title: row.month,
      field: row.month,
      type: 'numeric',
      headerStyle: cellStyle
    });

    table.data[0][row.month] = `${numberFormat(row.monthly_spend)}${
      forecast && row.monthly_spend ? ` (${numberFormat(row.forecasted_monthly_spend)})` : ''
    }`;
    table.data[1][row.month] = `${discount} %`;
    table.data[2][row.month] = `${numberFormat(row.discounted_spend)}${
      forecast && row.forecasted_discounted_spend ? ` (${numberFormat(row.forecasted_discounted_spend)})` : ''
    }`;
  });

  return table;
};
