import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper
} from '@material-ui/core';
import { form } from '../../helpers/styles';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import TitleBar from '../../components/TitleBar';
import { createEntity } from '../../firebase/requests';
import SubmitGroup from '../../components/Button/SubmitGroup';

const useStyles = makeStyles((theme) => ({
  ...form,
  advanced: {
    marginTop: 20
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    cursor: 'pointer'
  }
}));

const New = () => {
  const classes = useStyles();
  const history = useHistory();

  const [snack, setSnack] = useState(defaultSnackState);
  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email_domain_allowed: '',
    admins: [],
  });

  const back = () => {
    history.push('/entitymanagement');
  };

  const handleChange = (key, value) => {
    const newValues = {
      ...values,
      [key]: value
    };

    setValues(newValues);
  };

  const handleSubmit = () => {
    setUpdateIndicator(true);

    createEntity(values).then(() => {
      setSnack({
        open: true,
        message: `${values.name} successfully saved`,
        onClose: () => back()
      });
    }).catch((err) => {
      setUpdateIndicator(false);

      setSnack({
        open: true,
        message: err.data.detail || 'Error saving Entity',
        severity: 'error',
        onClose: () => setSnack({
          open: false,
          severity: 'error',
        })
      });
    });
  };

  const renderForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h6'>
              Entity
            </Typography>
          </Box>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Name'
            variant='outlined'
            onChange={(event) => handleChange('name', event.target.value)}
            value={values.name}
            validators={['required']}
            errorMessages={['This field is required']}
          />

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Email domain allowed'
            variant='outlined'
            onChange={(event) => handleChange('email_domain_allowed', event.target.value)}
            value={values.email_domain_allowed}
            multiline
            rows={3}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  const renderButtons = () => (
    <SubmitGroup
      cancel={() => back()}
      updateIndicator={updateIndicator}
    />
  );

  const renderSnack = () => (
    <SnackBar
      open={snack.open}
      message={snack.message}
      severity={snack.severity}
      onClose={snack.onClose}
    />
  );

  return (
    <>
      <TitleBar
        title='Entity Management'
        subTitle='New'
        updateIndicator={updateIndicator}
      />

      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />

      <ValidatorForm onSubmit={() => handleSubmit()}>

        {renderForm()}
        {renderButtons()}
        {renderSnack()}

      </ValidatorForm>
    </>
  );
};

export default New;
