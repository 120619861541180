import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Paper, Checkbox, TextField, Grid, Typography
} from '@material-ui/core';
import colors from '../../helpers/colors';
import { sortArray } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: colors.poppy,
    marginBottom: 10
  }
}));

const AddUser = ({
  title,
  options,
  value,
  setValue
}) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={1}
      style={{
        padding: 20,
        marginTop: 20
      }}
    >
      <Typography className={classes.title}>
        {title}
      </Typography>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id={`${title}-box`}
            options={sortArray(options, 'id')}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.id} (${option.role})`}
            value={value}
            onChange={(evt, newValue) => {
              setValue(newValue);
            }}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option.id} (${option.role})`}
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant='outlined'
                label='Users'
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

AddUser.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired
};

export default AddUser;
