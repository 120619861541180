import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Table from '../../../components/Table';
import AddFilter from './AddFilter';
import { customerUsers, tableFiltersData } from '../../../helpers/customers';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import ConfirmFilterDialog from './ConfirmFilterDialog';
import Dialog from '../../../components/Dialog';
import { dialogInitalState } from '../../../helpers/users';
import SaveReset from '../../../components/Button/SaveReset';

const defaultFilter = {
  users: [],
  label_key: '',
  label_value: '',
};

const Filters = ({
  customer,
  settings,
  users,
  updateIndicator,
  updateUserFilter,
}) => {
  const [addFilter, setAddFilter] = useState(defaultFilter);
  const [addDialog, setAddDialog] = useState(dialogInitalState);
  const [removeFilter, setRemoveFilter] = useState([]);
  const [removeDialog, setRemoveDialog] = useState(dialogInitalState);

  const usersData = tableFiltersData(customer.filters, users);
  const usersOptions = customerUsers(customer.filters, users);

  const renderTable = () => (
    <Table
      title='Manage Viewers list'
      columns={[
        {
          title: 'Email',
          field: 'id',
        },
        {
          title: 'Project label:value',
          field: 'filter',
          align: 'right',
          render: (rowData) => {
            if (rowData && rowData.filters) {
              const filters = rowData.filters.find((o) => o.customer === customer.customer_id);

              if (filters && filters.project_label) {
                return `${filters.project_label[0].label_key}: ${filters.project_label[0].label_value}`;
              }
            }
            return '';
          }
        },
      ]}
      data={usersData.data}
      actions={[
        {
          tooltip: 'Remove filter from selected users',
          icon: 'delete',
          onClick: (evt, newValue) => {
            setRemoveFilter(newValue);

            setRemoveDialog({
              open: true,
              message: 'User(s) with the following email(s) will be remove from filter list',
            });
          }
        }
      ]}
      options={{
        selection: true,
        rowStyle: (rowData) => ({
          color: (rowData.role === 'user not found') ? 'red' : ''
        })
      }}
    />
  );

  const renderFilterForm = () => (
    <>
      <AddFilter
        title='Add User Filter'
        options={usersOptions}
        value={addFilter}
        setValue={setAddFilter}
        settings={settings}
      />

      <SaveReset
        updateIndicator={
          updateIndicator
          || addFilter.users.length < 1
          || !addFilter.label_key
          || !addFilter.label_value
        }
        save={() => setAddDialog({
          open: true,
          message: 'User(s) with the following email(s) will be added to filter list',
        })}
        reset={() => setAddFilter(defaultFilter)}
      />
    </>
  );

  const renderDialogs = () => (
    <>
      <Dialog
        title='Please confirm'
        open={addDialog.open}
        onClose={() => setAddDialog(dialogInitalState)}
        actionButtons={(
          <>
            <Button
              color='primary'
              onClick={() => {
                setAddDialog(dialogInitalState);
                updateUserFilter(addFilter, true);
                setAddFilter(defaultFilter);
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => setAddDialog(dialogInitalState)}
              color='default'
            >
              Cancel
            </Button>
          </>
        )}
      >
        {addDialog.open && (
          <ConfirmFilterDialog
            message={addDialog.message}
            filter={addFilter}
          />
        )}
      </Dialog>

      <Dialog
        title='Please confirm'
        open={removeDialog.open}
        onClose={() => setRemoveDialog(dialogInitalState)}
        actionButtons={(
          <>
            <Button
              color='primary'
              onClick={() => {
                setRemoveDialog(dialogInitalState);
                updateUserFilter(removeFilter, false);
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => setRemoveDialog(dialogInitalState)}
              color='default'
            >
              Cancel
            </Button>
          </>
        )}
      >
        {removeDialog.open && (
          <ConfirmDialog
            message={removeDialog.message}
            users={removeFilter}
          />
        )}
      </Dialog>
    </>
  );

  return (
    <>
      {renderTable()}
      {renderFilterForm()}
      {renderDialogs()}
    </>
  );
};

Filters.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  settings: PropTypes.objectOf(PropTypes.any).isRequired,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  updateIndicator: PropTypes.bool.isRequired,
  updateUserFilter: PropTypes.func.isRequired,
};

export default Filters;
