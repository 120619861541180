import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper, MenuItem, FormControlLabel, Checkbox
} from '@material-ui/core';
import firebaseApp from '../../firebase/Base';
import { form } from '../../helpers/styles';
import TierDiscount from './TierDiscount';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import { saveNewCustomer } from '../../api/customers';
import TitleBar from '../../components/TitleBar';
import LoadingIndicator from '../../components/LoadingIndicator';
import { currencies } from '../../helpers/customers';
import SubmitGroup from '../../components/Button/SubmitGroup';

const useStyles = makeStyles((theme) => ({
  ...form,
  advanced: {
    marginTop: 20
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    cursor: 'pointer'
  }
}));

const New = () => {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [snack, setSnack] = useState(defaultSnackState);
  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [values, setValues] = useState({
    customer_id: '',
    customer_name: '',
    entity: '',
    grafana_external_link: '',
    drive_id: '',
    billing_account_id: '',
    billing_dashboard_type: 'Standard',
    billing_dashboard_set_discount: 0,
    billing_dashboard_tier_discount: [],
    billing_currency: 'EUR',
    billing_viewer: false,
    support_request: true,
    gcp_service_account: false,
    system_health_dashboard: false,
    documentation: false,
    workspace_license: false,
    inventory: false,
    anomaly_detection: false,
  });

  const back = () => {
    history.push('/customermanagement');
  };

  const handleChange = (key, value) => {
    const newValues = {
      ...values,
      [key]: value
    };

    setValues(newValues);
  };

  const handleNameChange = (value) => {
    let id = values.customer_id;

    if (value.match('^[a-zA-Z- ]*$')) {
      id = value.toLowerCase().replaceAll(' ', '');
    }

    const newValues = {
      ...values,
      customer_name: value,
      customer_id: id
    };

    setValues(newValues);
  };

  const shouldFieldsRender = !!(values.system_health_dashboard
    || values.documentation || values.workspace_license);

  const entitiesData = () => {
    firebaseApp.firestore().collection('entities')
      .orderBy('name', 'asc')
      .get()
      .then((response) => {
        const entities = [];

        response.forEach((doc) => {
          entities.push({ ...doc.data(), id: doc.id });
        });

        setData(entities);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    entitiesData();
  }, []);

  const handleSubmit = () => {
    setUpdateIndicator(true);

    saveNewCustomer(values).then(() => {
      setSnack({
        open: true,
        message: `${values.customer_name} successfully saved`,
        onClose: () => back()
      });
    }).catch((err) => {
      setUpdateIndicator(false);

      setSnack({
        open: true,
        message: err.data.detail || 'Error saving Customer',
        severity: 'error',
        onClose: () => setSnack({
          open: false,
          severity: 'error',
        })
      });
    });
  };

  const renderForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h6'>
              Customer
            </Typography>
          </Box>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Name'
            variant='outlined'
            onChange={(event) => handleNameChange(event.target.value)}
            value={values.customer_name}
            validators={['required']}
            errorMessages={['This field is required']}
          />

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Id'
            variant='outlined'
            onChange={(event) => {
              const id = event.target.value;
              if (id.match('^[a-zA-Z- ]*$')) {
                handleChange('customer_id', id.toLowerCase().replace(' ', ''));
              }
            }}
            value={values.customer_id}
            validators={['required', 'minStringLength: 6']}
            errorMessages={[
              'This field is required',
              'Must have at least 6 characters'
            ]}
          />

          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Entity'
            variant='outlined'
            onChange={(event) => handleChange('entity', event.target.value)}
            value={values.entity}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {data.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextValidator>

          <Box mb={2}>
            <Typography variant='h6'>
              Billing dashboard
            </Typography>
          </Box>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Billing account Id'
            variant='outlined'
            onChange={(event) => handleChange('billing_account_id', event.target.value)}
            value={values.billing_account_id}
            helperText='If customer has more than one Billing Id, separate with comma'
            validators={['required']}
            errorMessages={['This field is required']}
          />

          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Currency'
            variant='outlined'
            onChange={(event) => handleChange('billing_currency', event.target.value)}
            value={values.billing_currency}
          >
            {
              currencies.map((currency, i) => (
                <MenuItem value={currency} key={`cur${i}`}>
                  {currency}
                </MenuItem>
              ))
            }
          </TextValidator>

          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Dashboard type'
            variant='outlined'
            onChange={(event) => handleChange('billing_dashboard_type', event.target.value)}
            value={values.billing_dashboard_type}
          >
            <MenuItem value='Standard'>
              Standard (default)
            </MenuItem>
            <MenuItem value='Discount'>
              Set discount
            </MenuItem>
            <MenuItem value='Tier Discount'>
              Tier discount
            </MenuItem>
          </TextValidator>
          {
            values.billing_dashboard_type === 'Discount' ? (
              <TextValidator
                className={classes.textField}
                fullWidth
                label='Discount (%)'
                variant='outlined'
                onChange={(event) => handleChange('billing_dashboard_set_discount', event.target.value)}
                value={values.billing_dashboard_set_discount}
              />
            ) : null
          }
          {
            values.billing_dashboard_type === 'Tier Discount' ? (
              <TierDiscount
                rows={values.billing_dashboard_tier_discount}
                setRows={(tiers) => handleChange('billing_dashboard_tier_discount', tiers)}
              />
            ) : null
          }

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.support_request}
                onChange={(event) => handleChange('support_request', event.target.checked)}
              />
            )}
            label='Rapid Architecture Ticket'
          />

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.billing_viewer}
                onChange={(event) => handleChange('billing_viewer', event.target.checked)}
              />
            )}
            label='Billing Viewer'
          />

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.gcp_service_account}
                onChange={(event) => {
                  const newValues = {
                    ...values,
                    gcp_service_account: event.target.checked,
                    inventory: false,
                    system_health_dashboard: false
                  };

                  setValues(newValues);
                }}
              />
            )}
            label='Service Account'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled={!values.gcp_service_account}
                className={classes.checkBox}
                checked={values.inventory}
                onChange={(event) => handleChange('inventory', event.target.checked)}
              />
            )}
            label='Inventory'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled={!values.gcp_service_account}
                className={classes.checkBox}
                checked={values.system_health_dashboard}
                onChange={(event) => handleChange('system_health_dashboard', event.target.checked)}
              />
            )}
            label='System health'
          />
          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.workspace_license}
                onChange={(event) => handleChange('workspace_license', event.target.checked)}
              />
            )}
            label='Workspace License'
          />

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.documentation}
                onChange={(event) => handleChange('documentation', event.target.checked)}
              />
            )}
            label='Documentation'
          />

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.anomaly_detection}
                onChange={(event) => handleChange('anomaly_detection', event.target.checked)}
              />
            )}
            label='Anomaly Detection'
          />

        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        {
          shouldFieldsRender && (
            <Paper elevation={0} className={classes.paper}>
              {
                values.system_health_dashboard ? (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        System health dashboard
                      </Typography>
                    </Box>

                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      label='Customized Grafana dashboard'
                      variant='outlined'
                      onChange={(event) => handleChange('grafana_external_link', event.target.value)}
                      value={values.grafana_external_link}
                      helperText='If blank Built-in (default) will be used'
                    />
                  </>
                ) : null
              }

              {
                values.workspace_license ? (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        Domain name
                      </Typography>
                    </Box>

                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      label='Identifier for Workspace license'
                      variant='outlined'
                      onChange={(event) => handleChange('domain_name', event.target.value)}
                      value={values.domain_name}
                      helperText='Only used for Workspace license'
                    />
                  </>
                ) : null
              }

              {
                values.documentation ? (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        Documentation
                      </Typography>
                    </Box>

                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      label='Customized Google Drive folder'
                      variant='outlined'
                      onChange={(event) => handleChange('drive_id', event.target.value)}
                      value={values.drive_id}
                      helperText='If blank Built-in (default) will be used'
                    />
                  </>
                ) : null
              }
            </Paper>
          )
        }
      </Grid>
    </Grid>
  );

  const renderButtons = () => (
    <SubmitGroup
      cancel={() => back()}
      updateIndicator={updateIndicator}
    />
  );

  const renderSnack = () => (
    <SnackBar
      open={snack.open}
      message={snack.message}
      severity={snack.severity}
      onClose={snack.onClose}
    />
  );

  return (
    <>
      <TitleBar
        title='Customer Management'
        subTitle='New'
        updateIndicator={updateIndicator}
      />

      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />

      {
        isLoading ? <LoadingIndicator /> : (
          <ValidatorForm onSubmit={() => handleSubmit()}>

            {renderForm()}
            {renderButtons()}
            {renderSnack()}

          </ValidatorForm>
        )
      }
    </>
  );
};

export default New;
