import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar, Grid, Box, TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '../Dialog';
import { Context } from '../ContextProvider';
import firebase from '../../firebase/Base';
import Button from '../Button';
import colors from '../../helpers/colors';
import { customerAccounts, entityCustomers, setSelectedCustomer } from '../../helpers/customers';
import { defaultSelected, defaultSettings } from '../../helpers/settings';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    color: colors.lightGray,
  },
  autocomplete: {
    borderRadius: 2,
    border: '1px solid',
    '& input': {
      color: colors.lightGray,
      fontSize: 14,
    },
    '& .MuiIconButton-label': {
      color: colors.poppy
    }
  },
  dialog: {
    paddingBottom: 14,
    alignItems: 'center'
  }
}));
const RightToolbar = () => {
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const {
    user,
    customers,
    entities,
    updateContext,
    customerData,
    selectedCustomer,
    clearContex,
    billingAccounts,
    billingAccountId,
  } = useContext(Context);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Disable customer selection for admin pages since it is unnecessary their
  const prefixes = ['/customermanagement', '/entitymanagement', '/usermanagement'];
  const stringToCheck = location.pathname;

  const startsWithPrefix = prefixes.some((prefix) => stringToCheck.startsWith(prefix));

  const handleSelectChange = (selected) => {
    const accounts = customerAccounts(selected.billing_account_id);
    setSelectedCustomer(selected.customer_id);
    updateContext({
      billingAccounts: accounts.billingAccounts,
      billingAccountId: accounts.billingAccountId,
      selectedCustomer: selected.customer_id,
      customerData: selected,
      settings: defaultSettings,
      selected: defaultSelected,
      billing: null,
    });
  };

  const handleLogOutClick = () => {
    firebase.auth().signOut().then(() => {
      history.push('/');
      clearContex();
    });
  };

  const options = entityCustomers(entities, customers);

  const displayCustomerBillingId = () => (
    <>
      <Grid item>
        {
          user.claims.role !== 'customerViewer'
          && billingAccounts
          && billingAccounts.length > 1 && (
            <Autocomplete
              className={classes.autocomplete}
              blurOnSelect
              disableClearable
              size='small'
              value={billingAccountId}
              options={billingAccounts}
              onChange={(event, newValue) => {
                updateContext({
                  billingAccountId: newValue,
                  settings: defaultSettings,
                  billing: null
                });
              }}
              getOptionSelected={(option) => option === billingAccountId}
              style={{ width: 280 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                />
              )}
            />
          )
        }
      </Grid>
      <Grid item>
        {
          customers && selectedCustomer && (
            <Autocomplete
              disabled={customers.length <= 1}
              className={classes.autocomplete}
              blurOnSelect
              disableClearable
              size='small'
              value={customerData}
              options={options.sort((a, b) => a.entityName.localeCompare(b.entityName))}
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              groupBy={(option) => option.entityName}
              getOptionLabel={(option) => option.customer_name}
              getOptionSelected={(option) => option.customer_id === selectedCustomer}
              renderOption={(option) => option.customer_name}
              style={{ width: 280 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                />
              )}
            />
          )
        }
      </Grid>
    </>
  );

  return (
    <Box>
      <Grid container className={classes.root} spacing={2}>
        {startsWithPrefix
          ? null : displayCustomerBillingId()}
        <Grid item>
          <Avatar
            style={{ cursor: 'pointer' }}
            src={user.claims.picture}
            onClick={handleClickOpen}
          />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        title={user.claims.email}
      >
        <Grid
          container
          direction='column'
          className={classes.dialog}
        >
          <Grid item>
            <Button onClick={handleLogOutClick}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default RightToolbar;
