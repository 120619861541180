import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid, Paper, TextField, Button
} from '@material-ui/core';
import Table from '../../components/Table';
import AddBox from '../../components/Table/AddBox';
import CheckBox from '../../components/Table/CheckBox';
import Dialog from '../../components/Dialog';
import ConfirmProjectDialog from '../../components/Dialog/ConfirmProjectDialog';

import { dialogInitalState } from '../../helpers/users';
import { form } from '../../helpers/styles';

const useStyles = makeStyles((theme) => ({
  ...form,
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    cursor: 'pointer'
  }
}));

const ProjectsTable = ({ projects }) => {
  const history = useHistory();
  const classes = useStyles();
  const [approveDialog, setApproveDialog] = useState(dialogInitalState);

  const newProject = () => {
    history.push('/projectprovisioning/new');
  };
  const approveProjects = (project) => {
    console.log(project);
    history.push('/projectprovisioning/approve');
  };

  const renderApproveDialog = () => (
    <Dialog
      title={approveDialog.title}
      open={approveDialog.open}
      onClose={() => setApproveDialog(dialogInitalState)}
      actionButtons={(
        <>
          <Button
            color='primary'
            onClick={() => approveProjects(approveDialog.value)}
          >
            Submit
          </Button>
          <Button
            onClick={() => setApproveDialog(dialogInitalState)}
            color='default'
          >
            Cancel
          </Button>
        </>
      )}
    >
      {
        approveDialog.open && (
          <ConfirmProjectDialog projects={approveDialog.value} />
        )
      }
    </Dialog>
  );

  /**
   * Project Template
   */
  const renderTemplate = (value) => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%', width: '100%' }}
        >
          <TextField
            className={classes.textField}
            fullWidth
            label='Budget'
            variant='outlined'
            value={value.budget}
            disabled
          />
          <TextField
            className={classes.textField}
            fullWidth
            label='Default Admin'
            variant='outlined'
            value={value.default_admin}
            disabled
          />
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Table
        title='Projects'
        data={projects}
        columns={[
          {
            title: 'Project Name',
            field: 'project_name',
            removable: false
          },
          {
            title: 'Project State',
            field: 'state',
            removable: false
          },
          {
            title: 'Project Type',
            field: 'project_type',
            removable: false
          },
          {
            title: 'Folder Name',
            field: 'folder_name',
            removable: false
          },
          {
            field: 'project_template',
            title: 'Project template',
            width: '100%',
            filtering: false,
            sorting: false,
            render: (row) => renderTemplate(row.project_template),
            removable: false
          }
        ]}
        actions={[
          {
            icon: (() => <AddBox title='New' />),
            onClick: () => newProject(),
            isFreeAction: true
          },
          {
            tooltip: 'Approve All Selected Projects',
            icon: (() => <CheckBox title='Approve' />),
            onClick: (evt, value) => setApproveDialog({
              open: true,
              title: `Please confirm to approve the following ${value.length} projects`,
              value,
            })
          }
        ]}
        options={{
          filtering: true,
          selection: true,
          columnsButton: true
        }}
      />
      {renderApproveDialog()}
    </>

  );
};

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProjectsTable;
