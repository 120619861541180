import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Router from './Routes';
import Layout from './Layout';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentHeader: {
    padding: 30,
  },
}));

const Navigation = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Layout />
      <main
        className={classes.content}
      >
        <div className={classes.contentHeader} />
        <Router />
      </main>
    </div>
  );
};

export default Navigation;
