import { useContext } from 'react';
import { Context } from '../components/ContextProvider';

/**
 * RBAC validation based on perm|action
 * @param {string} action
 * @returns boolean
 */
const rbac = (action) => {
  const { user } = useContext(Context);
  const { perms } = user.claims;

  if (perms && perms.includes(action)) {
    return true;
  }

  return false;
};

/**
 * Has Access
 * @param {string} action
 * @returns boolean
 */
export const hasAccess = (action) => {
  const { user, customerData } = useContext(Context);

  const roleActions = {
    support: (
      rbac('supportrequest_read')
      && customerData && customerData.support_request
    ) || user.claims.role === 'admin',
    documentation: rbac('playbook_*') && customerData.documentation,
    inventory: rbac('inventory_*') && customerData.inventory,
    systemHealth: rbac('systemhealth_*') && customerData.system_health_dashboard,
    workspaceLicense: customerData.workspace_license,
    anomalyDetection: rbac('billing_*') && customerData.anomaly_detection,
    projectProvisioning: rbac('projectprovisioning_create')

  };

  return roleActions[action];
};

export default rbac;
