import React, {
  useEffect, useState, useContext
} from 'react';
import { Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import TitleBar from '../../components/TitleBar';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  getCustomerProject, getCustomerPassword
} from '../../api/customers';
import {
  initMonitoring, rebuildMonitoring
} from '../../api/monitoring';
import Grafana from './Grafana';

const SystemHealth = () => {
  const { selectedCustomer, customerData } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const SystemHealthData = () => {
    if (customerData.system_health_dashboard) {
      getCustomerProject(selectedCustomer).then((response) => {
        getCustomerPassword(selectedCustomer).then((result) => {
          setData({
            projects: response,
            customer: customerData,
            password: result ? result.grafana_pass : null
          });

          setError(false);
        }).catch(() => {
          setError(true);
        }).finally(() => setIsLoading(false));
      });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    SystemHealthData();
  }, [selectedCustomer]);

  const initDashboard = () => {
    setIsLoading(true);

    initMonitoring(selectedCustomer).then(() => {
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      alert(err.data.detail);
    });
  };

  const rebuildDashboard = () => {
    setIsLoading(true);

    rebuildMonitoring(selectedCustomer).then(() => {
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      alert(err.data.detail);
    });
  };

  if (error) {
    return (
      <TitleBar
        title='System Health'
        subTitle='Monitoring not implemented for this customer'
      />
    );
  }

  return (
    customerData.system_health_dashboard ? (
      <>
        <TitleBar
          title='System Health'
        >
          <Box onClick={() => setShowInfo(!showInfo)}>
            {showInfo ? <CloseIcon /> : <InfoIcon />}
          </Box>
        </TitleBar>
        {
          isLoading ? <LoadingIndicator /> : (
            <Grafana
              showInfo={showInfo}
              data={data}
              initDashboard={initDashboard}
              rebuildDashboard={rebuildDashboard}
            />
          )
        }
      </>
    ) : (
      <TitleBar
        title='System Health'
        subTitle={`for ${customerData.customer_name} is disabled`}
      />
    )
  );
};

export default SystemHealth;
