import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';
import colors from '../../helpers/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiButtonBase-root': {
      paddingRight: 0,
    },
    '& .MuiToolbar-root': {
      display: 'none'
    },
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }
}));

const SimpleTable = ({
  data,
  columns,
  options,
}) => {
  const classes = useStyles();

  const styles = {
    headerStyle: {
      fontWeight: 'bold',
      color: colors.gray,
      backgroundColor: colors.pink
    },
    rowStyle: (x) => (
      x.tableData.id % 2 ? { backgroundColor: colors.background } : {}
    )
  };

  const tableOptions = {
    tableLayout: 'auto',
    showTitle: false,
    search: true,
    actionsColumnIndex: -1,
    sorting: false,
    draggable: false,
    exportButton: false,
    paging: true,
    ...styles,
    ...options

  };

  return (
    <Box className={classes.root} width={1}>
      <MaterialTable
        data={data}
        columns={columns.map((c) => ({ ...c, tableData: undefined }))}
        options={tableOptions}
        components={{
          Container: (props) => <Paper {...props} elevation={1} />
        }}
      />
    </Box>
  );
};

SimpleTable.defaultProps = {
  options: {},
};

SimpleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.objectOf(PropTypes.any)
};

export default SimpleTable;
