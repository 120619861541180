import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Typography, Box, Grid, Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { form } from '../../helpers/styles';
import SubmitGroup from '../../components/Button/SubmitGroup';

const useStyles = makeStyles((theme) => ({
  ...form,
  advanced: {
    marginTop: 20
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    cursor: 'pointer'
  }
}));

function Form({
  entity,
  handleSubmit,
  updateIndicator,
  back
}) {
  const classes = useStyles();

  const [values, setValues] = useState(entity);

  const handleChange = (key, value) => {
    const newValues = {
      ...values,
      [key]: value
    };

    setValues(newValues);
  };

  const renderForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h6'>
              Entity
            </Typography>
          </Box>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Name'
            variant='outlined'
            onChange={(event) => handleChange('name', event.target.value)}
            value={values.name}
            validators={['required']}
            errorMessages={['This field is required']}
          />

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Email domain allowed'
            variant='outlined'
            onChange={(event) => handleChange('email_domain_allowed', event.target.value)}
            value={values.email_domain_allowed}
            multiline
            rows={3}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  const renderButtons = () => (
    <SubmitGroup
      cancel={() => back()}
      updateIndicator={updateIndicator}
    />
  );

  return (
    <ValidatorForm onSubmit={() => handleSubmit(values)}>

      {renderForm()}
      {renderButtons()}

    </ValidatorForm>
  );
}

Form.propTypes = {
  entity: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  updateIndicator: PropTypes.bool.isRequired
};

export default Form;
