export const MODAL_TYPE = {
  UPDATE_LICENSE: 'update_license',
  PLACE_ORDER: 'place_order',
  VIEW_ORDER: 'view_order',
  MANAGE_ORDER: 'manage_order',
};

export const VIEW_MODE = {
  VIEW_LICENSE: 'view_license',
  VIEW_ORDER: 'view_order',
};
