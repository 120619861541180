import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import TitleBar from '../../components/TitleBar';
import { Context } from '../../components/ContextProvider';
import { setClaims } from '../../api/users';
import Dashboard from './Dashboard';
import ErrorBoundary from '../../components/ErrorBoundary';

const renderError = (text, updateIndicator = true) => (
  <>
    <TitleBar
      title='Billing Dashboard'
      updateIndicator={updateIndicator}
    />
    <Typography>
      {text}
    </Typography>
  </>
);

const Billing = () => {
  const { user, customerData } = useContext(Context);

  if (!customerData.billing_account_id) {
    return renderError('Missing Billing Account. Please contact your system administrator.', false);
  }

  /**
   * Required validation for new user
   */
  if (!user.claims.perms) {
    setClaims();

    setInterval(() => location.reload(), 6000);
    return renderError(
      'Your user account is being initialised. You should be automatically taken into CommandCenter within one minute.'
    );
  }

  return (
    <ErrorBoundary>
      <Dashboard />
    </ErrorBoundary>
  );
};

export default Billing;
