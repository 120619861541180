export const columns = [
  {
    name: 'attribute',
    label: 'Attribute',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'value',
    label: 'Value',
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const cloudColumns = [
  {
    name: 'assettype',
    label: 'Asset Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'assetname',
    label: 'Asset Name',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'projectid',
    label: 'Project Id',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'region',
    label: 'Region',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'violation_rule',
    label: 'Violation Rule',
    options: {
      filter: true,
      sort: true
    }
  },
];

export const recommendationsColumns = [
  {
    name: 'primary_impact_category',
    label: 'Impact Category',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'recommender_subtype',
    label: 'Recommender Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'project_id',
    label: 'Project Id',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'location',
    label: 'Region',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'target_resources',
    label: 'Target Resources',
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const newCameraPosition = (node) => {
  const distance = 40;
  const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

  return {
    x: node.x * distRatio,
    y: node.y * distRatio,
    z: node.z * distRatio
  };
};
