import {
  Button, Paper, Typography
} from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSpring, animated } from 'react-spring';
import useWorkspaceLicenseStyles, { NumberValidationField } from '../../styles';
import Switch from '../../../../components/Switch';
import { modifyLicense } from '../../../../api/license';
import { Context } from '../../../../components/ContextProvider';
import SnackBar, { defaultErrorSnackState, defaultSnackState } from '../../../../components/SnackBar';

function ViewLicenseCard({
  license, orderInProgress, slideDistance, onOrderClick
}) {
  const [orderThresholdValue, setOrderThresholdValue] = useState(license.threshold);
  const [autoOrder, setAutoOrder] = useState(license.auto_order);
  const [newOrderValue, setNewOrderValue] = useState(license.new_order);
  const [snack, setSnack] = useState(defaultSnackState);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

  const [style, animate] = useSpring(() => ({ x: 0, zIndex: 10 }));
  const { customerData } = useContext(Context);

  useEffect(() => {
    if (orderInProgress) {
      animate({ x: -slideDistance, delay: 100 });
    } else {
      animate({ x: 0, delay: 100 });
    }
  }, [orderInProgress]);

  useEffect(() => {
    const parsedNewOrderValue = parseInt(newOrderValue, 10);
    const parsedOrderThresholdValue = parseInt(orderThresholdValue, 10);

    if (parsedOrderThresholdValue !== license.threshold
      || autoOrder !== license.auto_order
      || parsedNewOrderValue !== license.new_order) {
      setIsUpdateDisabled(false);
    } else {
      setIsUpdateDisabled(true);
    }
  }, [autoOrder, orderThresholdValue, newOrderValue]);

  const classes = useWorkspaceLicenseStyles();

  const onAutoOrderChange = () => setAutoOrder(!autoOrder);

  const onOrderThresholdChange = (event) => {
    const threshold = event.target.value;
    setOrderThresholdValue(threshold);
  };

  const onNewOrderChange = (event) => {
    const newOrder = event.target.value;
    setNewOrderValue(newOrder);
  };

  const handleSubmit = () => {
    const updateData = {
      auto_order: autoOrder,
      threshold: parseInt(orderThresholdValue, 10),
      new_order: parseInt(newOrderValue, 10),
    };

    modifyLicense(customerData.domain_name, license.sku_id, updateData).then(() => {
      setSnack({ message: 'License updated', open: true, onClose: () => setSnack(defaultSnackState) });
    }).catch(() => {
      setSnack({
        message: 'Could not update', open: true, severity: 'error', onClose: () => setSnack(defaultErrorSnackState)
      });
    });
  };

  const handleError = () => {
    setSnack({
      message: 'Incorrect input', severity: 'error', open: true, onClose: () => setSnack(defaultErrorSnackState)
    });
  };

  return (
    <>
      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />
      <animated.div style={style} className={classes.animatedContainer}>
        <Paper
          square
          elevation={1}
          className={classes.paper}
        >
          <div className={classes.cardHeader}>
            <Typography id='modal-modal-title' variant='h4' component='h2'>
              {license.sku_name}
            </Typography>
          </div>
          <ValidatorForm
            className={classes.form}
            onSubmit={handleSubmit}
            onError={handleError}
          >
            <div className={classes.licenseInformation}>
              <div className={classes.titleContainer}>
                <div className={classes.skuContainer}>
                  <Typography id='modal-modal-description'>
                    SKU
                  </Typography>
                  <Typography className={classes.skuNumber}>
                    {license.sku_id}
                  </Typography>
                </div>
                <Typography id='modal-modal-description'>
                  {license.volume_available}
                  {' '}
                  /
                  {' '}
                  {license.volume_total}
                  {' '}
                  Available
                </Typography>
              </div>
              <NumberValidationField
                label='Order Threshold'
                name='threshold'
                value={orderThresholdValue}
                onChange={onOrderThresholdChange}
                validators={['matchRegexp:^\\d+$', 'minNumber:0', `maxNumber:${license.volume_total}`]}
                errorMessages={['must be a number', 'number must be 0 or greater', `number cant be over ${license.volume_total}`]}
              />
              <div className={classes.licenseField}>
                <Typography id='modal-modal-description'>
                  Auto Order
                </Typography>
                <Switch name='auto_order' checked={autoOrder} onChange={onAutoOrderChange} />
              </div>
              { autoOrder && (
                <NumberValidationField
                  label='New Order'
                  name='new_order'
                  value={newOrderValue}
                  onChange={onNewOrderChange}
                  validators={['matchRegexp:^\\d+$', 'minNumber:1', `maxNumber:${license.volume_total}`]}
                  errorMessages={['must be a number', 'number must be greater than 0', `number cant be over ${license.volume_total}`]}
                />
              )}

              <footer className={classes.twoButtonContainer}>
                <Button disabled={isUpdateDisabled} type='submit' variant='contained' className={classes.updateButton}>
                  Update
                </Button>
                <Button onClick={onOrderClick} variant='contained' className={classes.orderButton}>
                  Order
                </Button>
              </footer>
            </div>
          </ValidatorForm>
        </Paper>
      </animated.div>
    </>
  );
}

ViewLicenseCard.propTypes = {
  license: PropTypes.instanceOf(Object).isRequired,
  onOrderClick: PropTypes.func.isRequired,
  orderInProgress: PropTypes.bool.isRequired,
  slideDistance: PropTypes.number.isRequired
};

export default ViewLicenseCard;
