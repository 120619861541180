import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, Checkbox, TextField } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const Projects = ({
  options,
  selectedOptions,
  handleSelected
}) => {
  const [value, setValue] = useState(selectedOptions);

  return (
    <FormControl variant='outlined' style={{ width: '100%' }}>
      <Autocomplete
        id='project-select-label'
        multiple
        openOnFocus
        disableCloseOnSelect
        includeInputInList
        value={value}
        onChange={(evt, newValue, reason) => {
          setValue(newValue);

          if (reason === 'remove-option' || reason === 'clear') {
            handleSelected(newValue);
          }
        }}
        onClose={() => handleSelected(value)}
        options={options}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant='outlined'
            label='Projects'
            placeholder={value.length === 0 ? 'All Selected' : ''}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </FormControl>
  );
};

Projects.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelected: PropTypes.func.isRequired
};

export default Projects;
