import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Hidden, Typography, Box
} from '@material-ui/core';
import devoteamlogo from '../../assets/images/logo.svg';
import background from '../../assets/images/background.jpg';
import Login from './Login';
import colors from '../../helpers/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  spaced: {
    marginBottom: 10
  },
  dobleSpaced: {
    marginBottom: 20
  },
  backgroundImage: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  loginBox: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    minHeight: '100vh'
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageStyle: {
    marginBottom: 10,
    height: 60,
    width: null,
  },
  team: {
    fontWeight: 'bold',
    color: colors.darkGray,
  },
  cc: {
    fontSize: 14,
    color: colors.poppy,
    fontWeight: 'bolder'
  },
  quote: {
    color: colors.neutralGray,
  },
  box: {
    maxWidth: 360
  }
}));

const SignIn = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>

      <Hidden xsDown>
        <Grid
          item
          sm={7}
          className={classes.backgroundImage}
        />
      </Hidden>

      <Grid
        className={classes.loginBox}
        item
        sm={5}
        xs={12}
      >
        <Box className={classes.box}>
          <Grid className={classes.centerBox}>
            <img src={devoteamlogo} className={classes.imageStyle} alt='Logo' />
          </Grid>

          <Grid className={classes.centerBox}>
            <Typography variant='h5' className={classes.team}>
              Devoteam Cloud Services
            </Typography>
          </Grid>

          <Grid className={clsx(classes.centerBox, classes.dobleSpaced)}>
            <Typography variant='h5' className={classes.team}>
              MSP Command Center
            </Typography>
          </Grid>

          <Hidden xsDown>
            <Grid className={classes.spaced}>
              <Typography className={classes.quote}>
                Gain control over your cloud costs, secure your environment and access
                expert Devoteam engineering expertise from the
                {' '}
                <span className={classes.cc}>
                  MSP Command Center.
                </span>
              </Typography>
            </Grid>
          </Hidden>

          <Login />
        </Box>

      </Grid>

    </Grid>
  );
};

export default SignIn;
