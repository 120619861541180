import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { makeStyles } from '@material-ui/core';
import { loadingStyles } from '../../helpers/styles';
import { numberFormat } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold'
  },
  label: {
    fill: 'rgb(51, 51, 51)',
    fontFamily: 'Montserrat',
    fontWeight: 100,
    strokeWidth: 0
  }
}));

const Chart = ({
  title,
  keys,
  colors,
  chartData,
  maxValue,
}) => {
  const classes = useStyles();
  const indexBy = 'date';
  const labelSkipWidth = 18;

  const totalLabels = ({ bars, yScale }) => {
    const labelMargin = 18;

    return bars.map(({ data: { data, indexValue }, x, width }, i) => {
      const { total } = data;

      if (
        Object.keys(data).length === 2
        || width < labelSkipWidth
        || !total
        || total < 1
      ) {
        return null;
      }

      return (
        <g
          transform={`translate(${x}, ${yScale(total) - labelMargin}), rotate(-45)`}
          style={{ opacity: 0.2 }}
          key={`${indexValue}-${i}`}
        >
          <text
            x={width / 2}
            y={labelMargin / 2}
            textAnchor='middle'
            dominantBaseline='central'
            fontSize={10}
            className={classes.label}
          >
            {numberFormat(total)}
          </text>
        </g>
      );
    });
  };

  const titleBar = () => (
    title ? (
      <text
        className={classes.title}
        x={-22}
        y={-16}
      >
        {title}
      </text>
    ) : null
  );

  return (
    <div
      key={`chart-key-${title}`}
      style={loadingStyles}
    >
      <ResponsiveBar
        keys={keys}
        data={chartData}
        maxValue={maxValue}
        colors={(key) => colors[key.id] || '#D4D4D4'}
        indexBy={indexBy}
        margin={{
          top: 30,
          right: 180,
          bottom: 95,
          left: 60
        }}
        padding={0.3}
        borderColor='#737373'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 6,
          tickRotation: -90,
          legend: 'Date',
          legendPosition: 'start',
          legendOffset: 90,
        }}
        axisLeft={{
          format: (value) => numberFormat(value, 0),
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Cost',
          legendPosition: 'start',
          legendOffset: -55,
        }}
        valueFormat={(value) => numberFormat(value)}
        labelSkipWidth={28}
        labelSkipHeight={12}
        labelTextColor='#737373'
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 1,
            itemWidth: 100,
            itemHeight: 17,
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 17,
            effects: [
              {
                on: 'hover',
                style: { itemOpacity: 0.5 },
              },
            ],
          },
        ]}
        animate
        motionStiffness={90}
        motionDamping={15}
        motionConfig={{
          mass: 1,
          tension: 500,
          friction: 30,
          clamp: false,
          precision: 0.01,
          velocity: 0
        }}
        theme={{
          background: '#ffffff',
          fontFamily: 'Montserrat',
          tooltip: {
            container: {
              fill: 'rgb(51, 51, 51)',
              fontFamily: 'Montserrat',
              fontSize: 12,
              fontWeight: 100,
              strokeWidth: 0
            },
          },
        }}
        layers={[
          'grid',
          'axes',
          'bars',
          'markers',
          'legends',
          totalLabels,
          titleBar
        ]}
      />
    </div>
  );
};

Chart.defaultProps = {
  title: ''
};

Chart.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.objectOf(PropTypes.string).isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxValue: PropTypes.number.isRequired,
  title: PropTypes.string
};

export default memo(Chart);
