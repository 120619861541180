import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import colors from '../../helpers/colors';
import { form } from '../../helpers/styles';
import Button from '.';

const useStyles = makeStyles(() => (form));

const ButtonGroup = ({
  updateIndicator,
  submit,
  cancel,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.buttons}>
      <Button
        onClick={() => submit()}
        disabled={updateIndicator}
      >
        Save
      </Button>
      <Divider className={classes.divider} />
      <Button
        backgroundColor={colors.darkGray}
        onClick={() => cancel()}
        disabled={updateIndicator}
      >
        Cancel
      </Button>
    </Box>
  );
};

ButtonGroup.propTypes = {
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  updateIndicator: PropTypes.bool.isRequired,
};

export default ButtonGroup;
