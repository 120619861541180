import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import firebaseApp from '../../firebase/Base';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import TitleBar from '../../components/TitleBar';
import { addUserToEntityAdmin, removeUserFromEntityAdmin, updateEntity } from '../../firebase/requests';
import LoadingIndicator from '../../components/LoadingIndicator';
import Tabs from '../../components/Tabs';
import Form from './Form';
import Admin from './Admin';

const Update = () => {
  const { id } = useParams();
  const history = useHistory();

  const [entity, setEntity] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snack, setSnack] = useState(defaultSnackState);
  const [updateIndicator, setUpdateIndicator] = useState(false);

  const back = () => {
    history.push('/entitymanagement');
  };

  const entityData = () => {
    firebaseApp.firestore()
      .collection('entities')
      .doc(id)
      .get()
      .then((response) => {
        setEntity(response.data());
        setIsLoading(false);
      });
  };

  const usersData = () => {
    firebaseApp.firestore().collection('users').get().then((response) => {
      const usersList = [];
      response.forEach((doc) => {
        const data = doc.data();
        usersList.push({
          id: doc.id,
          role: data.role
        });
      });

      setUsers(usersList);
    });
  };

  const handleSubmit = (values) => {
    setUpdateIndicator(true);

    updateEntity(id, values).then(() => {
      setSnack({
        open: true,
        message: `${values.name} successfully updated`,
        onClose: () => setSnack(defaultSnackState)
      });
    }).catch((err) => {
      setSnack({
        open: true,
        message: err.data.detail || 'Error updating Entity',
        severity: 'error',
        onClose: () => setSnack({
          open: false,
          severity: 'error',
        })
      });
    }).finally(() => setUpdateIndicator(false));
  };

  const updateAdmins = async (value, access) => {
    setUpdateIndicator(true);

    if (access) {
      await Promise.all(value.map((user) => addUserToEntityAdmin(id, user.id)));
    } else {
      await Promise.all(value.map((user) => removeUserFromEntityAdmin(id, user.id)));
    }

    entityData(id);
    setUpdateIndicator(false);
  };

  useEffect(() => {
    usersData();
    entityData();
  }, [id]);

  const entityForm = () => (
    <Form
      entity={entity}
      updateIndicator={updateIndicator}
      back={() => back()}
      handleSubmit={(values) => {
        setEntity(values);
        handleSubmit(values);
      }}
    />
  );

  const admins = () => (
    <Admin
      entity={entity}
      users={users}
      updateIndicator={updateIndicator}
      back={() => back()}
      updateAdmins={(value, access) => updateAdmins(value, access)}
    />
  );

  return (
    <>
      <TitleBar
        title='Entity Management'
        subTitle={`Update${entity ? `: ${entity.name}` : ''}`}
        updateIndicator={updateIndicator}
      />

      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />

      {
        isLoading ? <LoadingIndicator /> : (
          <Tabs
            transparent
            labels={[
              'Entity Info',
              'Entity Admin',
            ]}
            content={[
              entityForm(),
              admins()
            ]}
          />
        )
      }

    </>
  );
};

export default Update;
