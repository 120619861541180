import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import colors from '../../helpers/colors';

const useStyles = makeStyles({
  table: {
    width: '100%'
  },
  root: {
    border: '1px solid whitesmoke',
    marginBottom: 10
  },
  icon: {
    cursor: 'pointer'
  }
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: colors.pink,
    color: colors.darkGray,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: colors.background,
    },
  },
}))(TableRow);

const TierDiscount = ({
  rows,
  setRows
}) => {
  const classes = useStyles();

  const addRow = () => {
    const updateRows = rows;
    updateRows.push({
      percentage: '',
      threshold_spend: ''
    });

    setRows(updateRows);
  };

  const updateRow = (row, index, key, value) => {
    const updateRows = rows;
    updateRows[index] = {
      ...row,
      [key]: Number(value)
    };

    setRows(updateRows);
  };

  const removeRow = (index) => {
    const updateRows = rows;
    updateRows.splice(index, 1);

    setRows(updateRows);
  };

  const renderRow = (row, index) => (
    <StyledTableRow key={index}>
      <StyledTableCell>{index + 1}</StyledTableCell>
      <StyledTableCell>
        <TextField
          type='number'
          value={row.threshold_spend}
          onChange={(e) => updateRow(row, index, 'threshold_spend', e.target.value)}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          type='number'
          value={row.percentage}
          onChange={(e) => updateRow(row, index, 'percentage', e.target.value)}
        />
      </StyledTableCell>

      <StyledTableCell align='right'>
        <IndeterminateCheckBoxIcon
          className={classes.icon}
          onClick={() => removeRow(index)}
        />
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <div className={classes.root}>
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label='tier table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Tier</StyledTableCell>
              <StyledTableCell>Monthly Spend</StyledTableCell>
              <StyledTableCell>Discount (%)</StyledTableCell>
              <StyledTableCell align='right'>
                <AddBoxIcon
                  className={classes.icon}
                  onClick={() => addRow()}
                />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.length > 0 ? rows.map((row, index) => (renderRow(row, index))) : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

TierDiscount.defaultProps = {
  rows: [],
};

TierDiscount.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  setRows: PropTypes.func.isRequired
};

export default TierDiscount;
