import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper, MenuItem
} from '@material-ui/core';
import { form } from '../../helpers/styles';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import TitleBar from '../../components/TitleBar';
import { projectCreate } from '../../api/inventory';
import { Context } from '../../components/ContextProvider';
import SubmitGroup from '../../components/Button/SubmitGroup';
import LoadingIndicator from '../../components/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  ...form,
  advanced: {
    marginTop: 20
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    cursor: 'pointer'
  }
}));

const New = () => {
  const classes = useStyles();
  const history = useHistory();

  const { customerData, selectedCustomer } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [selectedCustomer]);

  const [snack, setSnack] = useState(defaultSnackState);
  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [values, setValues] = useState({
    bu: '',
    product: '',
    env: '',
    ownervalue: '',
    template_index: 0
  });

  const back = () => {
    history.push('/projectprovisioning');
  };

  const calculateProjectId = () => {
    if (customerData.project_provisioning.length > 0) {
      const id = customerData.project_provisioning[values.template_index].project_template.project_name.replace('<bu>', values.bu).replace('<product>', values.product).replace('<env>', values.env);
      return id;
    }
    return '';
  };
  const calculateProjectLabels = () => {
    if (customerData.project_provisioning.length > 0) {
      const label = `${customerData.project_provisioning[values.template_index].project_template.owner_label}=${values.ownervalue}`;
      return label;
    }
    return '';
  };
  const calculateQuotas = () => {
    if (customerData.project_provisioning.length > 0) {
      let quota = '';
      const quotas = customerData.project_provisioning[
        values.template_index].project_template.quota;
      const keys = Object.keys(quotas);

      for (let i = 0; i < keys.length; i += 1) {
        quota = `${quota}${keys[i]}=${quotas[keys[i]]} <br> `;
      }
      return quota;
    }
    return '';
  };
  const calculateBudget = () => {
    if (customerData.project_provisioning.length > 0) {
      const id = customerData.project_provisioning[values.template_index].project_template.budget;
      return id;
    }
    return '';
  };
  const getFolder = () => {
    if (customerData.project_provisioning.length > 0) {
      const id = customerData.project_provisioning[
        values.template_index].folder_name;
      return id;
    }
    return '';
  };

  const handleChange = (key, value) => {
    const newValues = {
      ...values,
      [key]: value
    };

    setValues(newValues);
  };

  const handleSubmit = () => {
    setUpdateIndicator(true);

    const pp = customerData.project_provisioning[values.template_index];

    projectCreate(
      selectedCustomer, values.bu,
      values.product, values.env,
      values.ownervalue, pp.template_name,
      pp.folder_name, pp.folder_number,
      pp.project_template.project_type, pp.project_template.project_type,
      pp.project_template.default_admin, pp.project_template.budget,
      pp.project_template.owner_label, pp.project_template.project_name,
      pp.project_template.quota.bigquery_analysis,
      pp.project_template.quota.compute_engine_cpus
    ).then(() => {
      setSnack({
        open: true,
        message: 'Project successfully created',
        onClose: () => back()
      });
    }).catch((err) => {
      setUpdateIndicator(false);

      setSnack({
        open: true,
        message: `Error creating Project ${err}`,
        severity: 'error',
        onClose: () => setSnack({
          open: false,
          severity: 'error',
        })
      });
    });
  };

  const renderForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h5'>
              Project Information
            </Typography>
          </Box>

          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Business Unit'
            variant='outlined'
            onChange={(event) => handleChange('bu', event.target.value)}
            value={values.bu}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {customerData.business_units.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextValidator>
          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Product'
            variant='outlined'
            onChange={(event) => handleChange('product', event.target.value)}
            value={values.product}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {customerData.products.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextValidator>
          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Environment'
            variant='outlined'
            onChange={(event) => handleChange('env', event.target.value)}
            value={values.env}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {customerData.env.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextValidator>
          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Cost Owner'
            variant='outlined'
            onChange={(event) => handleChange('ownervalue', event.target.value)}
            value={values.ownervalue}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {customerData.owner_values.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextValidator>
          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Project Template'
            variant='outlined'
            onChange={(event) => handleChange('template_index', event.target.value)}
            value={values.template_index}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {customerData.project_provisioning.map((option, index) => (
              <MenuItem key={option.template_name} value={index}>
                {option.template_name}
              </MenuItem>
            ))}
          </TextValidator>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h5'>
              Generated Project Details
            </Typography>
            <br />
            <Typography variant='h6'>
              { 'Project Id: ' }
              { values.bu && values.product && values.env ? calculateProjectId() : '' }
            </Typography>
            <br />
            <br />
            <Typography variant='h6'>
              Project labels to be applied
            </Typography>
            <p dangerouslySetInnerHTML={{ __html: calculateProjectLabels() }} />
            <br />
            <Typography variant='h6'>
              Quotas
            </Typography>
            <p dangerouslySetInnerHTML={{ __html: calculateQuotas() }} />
            <br />
            <Typography variant='h6'>
              Budget
            </Typography>
            <p>{calculateBudget()}</p>
            <br />
            <Typography variant='h6'>
              Folder
            </Typography>
            <p>{getFolder()}</p>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderButtons = () => (
    <SubmitGroup
      cancel={() => back()}
      updateIndicator={updateIndicator}
    />
  );

  const renderSnack = () => (
    <SnackBar
      open={snack.open}
      message={snack.message}
      severity={snack.severity}
      onClose={snack.onClose}
    />
  );

  return (
    <>
      <TitleBar
        title='New Project'
        subTitle='New'
        updateIndicator={updateIndicator}
      />

      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />

      {
        isLoading ? <LoadingIndicator /> : (
          <ValidatorForm onSubmit={() => handleSubmit()}>

            {renderForm()}
            {renderButtons()}
            {renderSnack()}

          </ValidatorForm>
        )
      }
    </>
  );
};

export default New;
