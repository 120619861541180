import {
  Button, Paper, TextField, Typography
} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import useWorkspaceLicenseStyles, { NumberValidationField } from '../../styles';
import { Context } from '../../../../components/ContextProvider';
import { createOrder } from '../../../../api/license';
import SnackBar, { defaultErrorSnackState, defaultSnackState } from '../../../../components/SnackBar';

function PlaceOrderCard({
  license, orderInProgress, slideDistance
}) {
  const [orderAmount, setOrderAmount] = useState(0);
  const [commentValue, setCommentValue] = useState('');
  const [style, animate] = useSpring(() => ({ opacity: 0, x: 0, zIndex: 5 }));
  const [snack, setSnack] = useState(defaultSnackState);

  const classes = useWorkspaceLicenseStyles();
  const { selectedCustomer, customerData, user } = useContext(Context);
  const userEmail = user.claims.email;

  const onOrderAmountChange = (event) => setOrderAmount(event.target.value);

  const { id, tableData, ...licenseDataWithoutExcess } = license;

  useEffect(() => {
    if (orderInProgress) {
      animate({ opacity: 1, x: slideDistance, delay: 100 });
    } else {
      animate({ opacity: 0, x: 0, delay: 100 });
    }
  }, [orderInProgress]);

  const onCommentValueChange = (event) => setCommentValue(event.target.value);

  const onOrderClick = () => {
    const order = {
      ...licenseDataWithoutExcess,
      order_amount: orderAmount,
      comment: commentValue,
      customer_id: selectedCustomer,
      order_by: userEmail
    };

    createOrder(order, customerData.domain_name).then(() => {
      setSnack({ message: 'Order placed', open: true, onClose: () => setSnack(defaultSnackState) });
    }).catch(() => {
      setSnack({
        message: 'Error placing order', severity: 'error', open: true, onClose: () => setSnack(defaultErrorSnackState)
      });
    });
  };

  const handleError = () => {
    setSnack({
      message: 'Error occurred', severity: 'error', open: true, onClose: () => setSnack(defaultErrorSnackState)
    });
  };

  return (
    <>
      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />
      <animated.div style={style} className={classes.animatedContainer}>
        <Paper
          square
          elevation={1}
          className={classes.paper}
        >
          <div className={classes.orderHeader}>
            <Typography id='modal-modal-title' variant='h4' component='h2'>
              Place Order
            </Typography>
          </div>
          <ValidatorForm
            className={classes.form}
            onSubmit={onOrderClick}
            onError={handleError}
          >
            <div className={classes.licenseInformation}>
              <div className={classes.licenseField}>
                <NumberValidationField
                  label='Order amount'
                  value={orderAmount}
                  onChange={onOrderAmountChange}
                  validators={['matchRegexp:^\\d+$', 'minNumber:1',
                    `maxNumber:${license.volume_total}`]}
                  errorMessages={['must be a number', 'number must be greater than 0',
                    `number cant be over ${license.volume_total}`]}
                />
              </div>
              <div className={classes.licenseField}>
                <Typography id='modal-modal-description'>
                  Comment
                </Typography>
              </div>
              <TextField
                value={commentValue}
                onChange={onCommentValueChange}
                className={classes.licenseField}
                variant='filled'
                multiline
                rows={3}
              />
              <footer className={classes.oneButtonContainer}>
                <Button type='submit' variant='contained' className={classes.updateButton}>
                  Finish
                </Button>
              </footer>
            </div>
          </ValidatorForm>
        </Paper>
      </animated.div>
    </>
  );
}

PlaceOrderCard.propTypes = {
  license: PropTypes.instanceOf(Object).isRequired,
  orderInProgress: PropTypes.bool.isRequired,
  slideDistance: PropTypes.number.isRequired
};

export default PlaceOrderCard;
