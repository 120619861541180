import React from 'react';
import { Box } from '@material-ui/core';
import { DotLoader as Loader } from 'react-spinners';
import colors from '../../helpers/colors';

/**
 * Centered Loading based on DotLoader
 */
const DotLoader = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    minHeight='100%'
  >
    <Loader color={colors.poppy} loading size={80} />
  </Box>
);

export default DotLoader;
