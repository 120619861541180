import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Table from '../../components/Table';
import AddUser from './AddUser';
import ButtonGroup from '../../components/Button/ButtonGroup';
import { customerUsers, tableData } from '../../helpers/customers';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import Dialog from '../../components/Dialog';
import { dialogInitalState } from '../../helpers/users';

const UserAccess = ({
  customer,
  users,
  updateIndicator,
  back,
  updateUserAccess,
}) => {
  const [addAccess, setAddAccess] = useState([]);
  const [addDialog, setAddDialog] = useState(dialogInitalState);
  const [removeAccess, setRemoveAccess] = useState([]);
  const [removeDialog, setRemoveDialog] = useState(dialogInitalState);

  const usersData = tableData(customer.users, users);
  const usersOptions = customerUsers(customer.users, users);

  const renderTable = () => (
    <Table
      title='User Access list'
      columns={usersData.columns}
      data={usersData.data}
      actions={[
        {
          tooltip: 'Remove access to selected users',
          icon: 'delete',
          onClick: (evt, newValue) => {
            setRemoveAccess(newValue);

            setRemoveDialog({
              open: true,
              message: 'User(s) with the following email(s) will be remove from access list',
            });
          }
        }
      ]}
      options={{
        selection: true,
        rowStyle: (rowData) => ({
          color: (rowData.role === 'user not found') ? 'red' : ''
        })
      }}
    />
  );

  const renderAccessForm = () => (
    <>
      <AddUser
        title='Add User'
        options={usersOptions}
        value={addAccess}
        setValue={setAddAccess}
      />

      <ButtonGroup
        updateIndicator={updateIndicator || addAccess.length < 1}
        submit={() => setAddDialog({
          open: true,
          message: 'User(s) with the following email(s) will be added to access list',
        })}
        cancel={() => back()}
      />
    </>
  );

  const renderDialogs = () => (
    <>
      <Dialog
        title='Please confirm'
        open={addDialog.open}
        onClose={() => setAddDialog(dialogInitalState)}
        actionButtons={(
          <>
            <Button
              color='primary'
              onClick={() => {
                setAddDialog(dialogInitalState);
                updateUserAccess(addAccess, true);
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => setAddDialog(dialogInitalState)}
              color='default'
            >
              Cancel
            </Button>
          </>
        )}
      >
        {addDialog.open && (
          <ConfirmDialog
            message={addDialog.message}
            users={addAccess}
          />
        )}
      </Dialog>

      <Dialog
        title='Please confirm'
        open={removeDialog.open}
        onClose={() => setRemoveDialog(dialogInitalState)}
        actionButtons={(
          <>
            <Button
              color='primary'
              onClick={() => {
                setRemoveDialog(dialogInitalState);
                updateUserAccess(removeAccess, false);
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => setRemoveDialog(dialogInitalState)}
              color='default'
            >
              Cancel
            </Button>
          </>
        )}
      >
        {removeDialog.open && (
          <ConfirmDialog
            message={removeDialog.message}
            users={removeAccess}
          />
        )}
      </Dialog>
    </>
  );

  return (
    <>
      {renderTable()}
      {renderAccessForm()}
      {renderDialogs()}
    </>
  );
};

UserAccess.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  updateIndicator: PropTypes.bool.isRequired,
  back: PropTypes.func.isRequired,
  updateUserAccess: PropTypes.func.isRequired,
};

export default UserAccess;
