import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemText, Button, TextField, Typography, Grid, Box, Divider
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';
import NewIcon from '@material-ui/icons/AddBox';
import colors from '../../helpers/colors';
import Dialog from '../../components/Dialog';
import { dateMoment } from '../../helpers/date';
import { saveSatisfaction } from '../../api/support';
import { Context } from '../../components/ContextProvider';
import { jiraLimits, satisfactionLevel, satisfactionScore } from '../../helpers/support';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiListItem-root': {
      padding: 0
    }
  },
  grid: {
    padding: 10
  },
  button: {
    marginTop: 10,
    color: colors.poppy,
    borderColor: colors.poppy
  },
  comment: {
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  commentTitle: {
    color: colors.neutralGray,
    fontSize: 14
  },
  level: {
    fontSize: '11pt',
    paddingTop: 2
  },
  dividerGroup: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'whitesmoke'
  },
  levelIcon: {
    fontSize: 40,
    textAlign: 'center',
    cursor: 'pointer',
  },
  levelLabel: {
    fontSize: 11
  },
  levelBox: {
    width: '20%'
  },
  scoreError: {
    color: 'red',
    paddingBottom: 20
  }
}));

const Satisfaction = ({
  openFeedBack,
  data,
  updateIndicator,
  setUpdateIndicator,
  reload
}) => {
  const classes = useStyles();
  const { user } = useContext(Context);

  const [open, setOpen] = useState(openFeedBack);
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    score: 0,
    comment: '',
  });
  const handleStateChange = (key, value) => {
    const newState = {
      ...state,
      [key]: value
    };

    setError(false);
    setState(newState);
  };

  const saveSatisfactionComment = () => {
    const newComment = {
      score: state.score,
      message: state.comment.trim(),
      author: user.claims.email
    };

    saveSatisfaction(data.jira_issue_key, newComment).then(() => {
      reload();
    });
  };

  const handleSubmit = () => {
    if (!state.comment || !state.score) {
      setError(true);
    } else {
      setUpdateIndicator(true);
      setOpen(false);
      saveSatisfactionComment();
    }
  };

  const renderSatisfactionComment = () => (
    <Grid container spacing={2} alignContent='center'>
      <Grid item xs={8}>
        <Typography align='left' className={classes.commentTitle}>
          {`${data.customer_satisfaction_comment.author}, ${dateMoment(data.customer_satisfaction_comment.date, 'YYYY/MM/DD HH:mm')}`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          align='right'
          className={classes.level}
        >
          {satisfactionScore(data.customer_satisfaction_score)}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderSatisfactionLevel = () => (
    <Grid
      className={classes.grid}
      container
      spacing={2}
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      {satisfactionLevel.map((level, i) => (
        <Grid
          key={`score-${i}`}
          className={classes.levelBox}
          style={{ textAlign: 'center' }}
        >
          <StarIcon
            className={classes.levelIcon}
            style={{ color: i + 1 <= state.score ? colors.poppy : 'grey' }}
            onClick={() => handleStateChange('score', i + 1)}
          />
          <Typography className={classes.levelLabel}>
            {level.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div className={classes.root}>
      {
        data.customer_satisfaction_comment ? (
          <List>
            <ListItem key='scoreComment'>
              <ListItemText
                className={classes.comment}
                primary={renderSatisfactionComment()}
                secondary={data.customer_satisfaction_comment.message}
                secondaryTypographyProps={{
                  style: {
                    fontSize: '11pt',
                    color: colors.darkGray
                  }
                }}
              />
            </ListItem>
          </List>
        ) : null
      }

      {
        !data.customer_satisfaction_comment && !data.customer_satisfaction_comment ? (
          <>
            <Button
              disabled={updateIndicator || user.claims.admin}
              className={classes.button}
              variant='outlined'
              component='span'
              size='small'
              startIcon={<NewIcon />}
              onClick={() => setOpen(true)}
            >
              Provide Feedback
            </Button>

            <Dialog
              title='Your satisfaction level on this request'
              open={open && !user.claims.admin}
              onClose={() => setOpen(false)}
              actionButtons={(
                <>
                  <Button
                    onClick={() => setOpen(false)}
                    color='primary'
                  >
                    Cancel
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </>
              )}
            >
              {
                error && !state.score ? (
                  <Typography className={classes.scoreError}>
                    Please, select satisfaction score
                  </Typography>
                ) : null
              }

              {renderSatisfactionLevel()}

              <Divider className={classes.dividerGroup} />

              <TextField
                className={classes.textField}
                fullWidth
                label='Any comment on your satisfaction level'
                variant='outlined'
                onChange={(event) => handleStateChange('comment', event.target.value)}
                multiline
                rows={6}
                value={state.comment}
                error={error && !state.comment}
                helperText={
                  error && !state.comment
                    ? 'This field is required'
                    : `The comment cannot be longer than ${jiraLimits.comment} characters.`
                }
                inputProps={{
                  maxLength: jiraLimits.comment,
                }}
              />
              <Box mt={2}>
                <Typography>
                  You are welcome to provide any other feedback to:
                </Typography>
                <Typography>
                  customer-feedback@msp.devoteam.com
                </Typography>
              </Box>

            </Dialog>

          </>
        ) : null
      }
    </div>
  );
};

Satisfaction.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired,
  openFeedBack: PropTypes.bool.isRequired,
  updateIndicator: PropTypes.bool.isRequired,
  setUpdateIndicator: PropTypes.func.isRequired,
};

export default Satisfaction;
