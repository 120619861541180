import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Table from '../../components/Table';
import { priorityFilter, satisfactionFilter, statusFilter } from '../../helpers/support';
import AddBox from '../../components/Table/AddBox';
import Refresh from '../../components/Table/Refresh';
import rbac, { hasAccess } from '../../helpers/rbac';

const RequestsTable = ({
  reload,
  data
}) => {
  const history = useHistory();

  const newSupportRequest = () => {
    history.push('/supportrequest/new');
  };

  const viewSupportRequest = (id) => {
    history.push(`/supportrequest/view/${id}`);
  };

  const renderFirstResponde = ({ firstResponse }) => (
    <Typography>
      {firstResponse}
    </Typography>
  );

  const renderStatus = ({ status, statusColor }) => (
    <Typography style={{ color: statusColor }}>
      {status}
    </Typography>
  );

  const renderSatisfaction = ({ satisfaction, satisfactionColor }) => (
    <Typography style={{ color: satisfactionColor }}>
      {satisfaction}
    </Typography>
  );

  const actions = () => {
    const roleActions = [
      {
        icon: (() => <Refresh />),
        onClick: () => reload(),
        isFreeAction: true
      },
    ];

    if (hasAccess('support') && rbac('supportrequest_create')) {
      roleActions.push({
        icon: (() => <AddBox title='New' />),
        onClick: () => newSupportRequest(),
        isFreeAction: true
      });
    }

    return roleActions;
  };

  return (
    <Table
      title=''
      data={data}
      options={{
        search: false,
        filtering: true,
        columnsButton: false,
        rowStyle: {
          overflowWrap: 'break-word'
        }
      }}
      actions={actions()}
      columns={[
        {
          title: 'Id',
          field: 'id',
          removable: false,
          cellStyle: {
            width: 120
          }
        },
        {
          title: 'Summary',
          field: 'summary',
          removable: false,
          cellStyle: {
            width: '40%'
          },
          filterCellStyle: {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
          headerStyle: {
            width: 'auto',
            whiteSpace: 'nowrap'
          },
        },
        {
          title: 'Status',
          field: 'status',
          removable: false,
          lookup: statusFilter,
          filtering: true,
          render: (value) => renderStatus(value)
        },
        {
          title: 'Priority',
          field: 'priority',
          lookup: priorityFilter,
        },
        {
          title: 'Last Modified',
          field: 'lastModified',
          filtering: false
        },
        {
          title: 'First Response',
          field: 'firstResponse',
          filtering: false,
          render: (value) => renderFirstResponde(value)
        },
        {
          title: 'Customer Satisfaction',
          field: 'satisfaction',
          lookup: satisfactionFilter,
          render: (value) => renderSatisfaction(value)
        },
      ]}
      onRowClick={(evt, rowData) => viewSupportRequest(rowData.id)}
    />
  );
};

RequestsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired,
};

export default RequestsTable;
