import React, { useContext } from 'react';
import { Context } from '../../../../components/ContextProvider';
import Tabs from '../../../../components/Tabs';
import ChartTotalProjectLabels from './ChartTotalProjectLabels';
import ChartProjectLabels from './ChartProjectLabels';

const ProjectLabel = () => {
  const { billing } = useContext(Context);

  return billing && billing.project_labels && (
    <Tabs
      transparent
      labels={[
        'Total (with Project Label)',
        'Project Labels (with Project)',
      ]}
      content={[
        <ChartTotalProjectLabels />,
        <ChartProjectLabels />,
      ]}
    />
  );
};

export default ProjectLabel;
