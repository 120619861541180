import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Paper, Checkbox, TextField, Grid, Typography, MenuItem
} from '@material-ui/core';
import colors from '../../../helpers/colors';
import { sortArray } from '../../../helpers/utils';
import { roleUsers } from '../../../helpers/users';
import { defaultSettings } from '../../../api/billing';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: colors.poppy,
    marginBottom: 10
  }
}));

const AddFilter = ({
  title,
  options,
  value,
  setValue,
  settings
}) => {
  const classes = useStyles();

  const users = roleUsers(options, 'customerViewer');

  const handleChange = (k, v) => {
    const newValues = {
      ...value,
      [k]: v || ''
    };

    setValue(newValues);
  };

  return (
    <Paper
      elevation={1}
      style={{
        padding: 20,
        marginTop: 20
      }}
    >
      <Typography className={classes.title}>
        {title}
      </Typography>

      {
        settings !== defaultSettings && (
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id={`${title}-box`}
                options={sortArray(users, 'id')}
                disableCloseOnSelect
                getOptionLabel={(option) => `${option.id} (${option.role})`}
                value={value.users}
                onChange={(evt, newValue) => setValue({
                  users: newValue,
                  label_key: '',
                  label_value: ''
                })}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {`${option.id} (${option.role})`}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    label='Users'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                disabled={value.users.length < 1}
                fullWidth
                select
                label='Project Label Key'
                variant='outlined'
                value={value.label_key}
                onChange={(e) => setValue({
                  ...value,
                  label_key: e.target.value,
                  label_value: ''
                })}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {
                  Object.keys(settings.project_label).map((label, i) => (
                    <MenuItem key={i} value={label}>{label}</MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={value.users.length < 1 || !value.label_key}
                fullWidth
                select
                label='Label Value'
                className={classes.input}
                variant='outlined'
                value={value.label_value}
                onChange={(e) => handleChange('label_value', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {
                  value.label_key && settings.project_label[value.label_key]
                    ? settings.project_label[value.label_key].map((label, i) => (
                      <MenuItem key={i} value={label}>{label}</MenuItem>
                    )) : (
                      <MenuItem value='' disabled />
                    )
                }
              </TextField>
            </Grid>

          </Grid>
        )
      }
    </Paper>
  );
};

AddFilter.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  settings: PropTypes.objectOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired
};

export default AddFilter;
