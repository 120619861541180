import axios from 'axios';
import log from '../helpers/log';
import storage from '../helpers/storage';

/**
 * Token headers
 */
export const headers = () => {
  const token = storage.get('token');

  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, POST, DELETE, OPTIONS',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
  };
};

/**
 * Request Wrapper with default success/error and logs
 */
const apiRequest = (options: Object, payload: boolean = true) => {
  // log.data('options', options);
  const { REACT_APP_API_URL, NODE_ENV } = process.env;

  const request = axios.create({
    baseURL: REACT_APP_API_URL,
    // timeout: 15000,
    headers: headers()
  });

  if (NODE_ENV === 'development') {
    request.interceptors.request.use((config) => {
      const newConfig = config;
      newConfig.headers['request-startTime'] = Date.now();
      return newConfig;
    });

    request.interceptors.response.use((response) => {
      const start = response.config.headers['request-startTime'];
      const end = Date.now();
      const seconds = Math.floor((end - start) / 1000);
      const milliseconds = Math.floor((end - start) % 1000);
      response.headers['request-duration'] = `${seconds}.${milliseconds} seconds`;

      return response;
    });
  }

  /**
   * onSuccess
   * Return response payload
   */
  const onSuccess = (response: Object) => {
    log.data('Request Successful!', response);
    const { data } = response;

    return payload ? data.payload : data;
  };

  /**
   * onError
   */
  const onError = (error: Object) => {
    log.data('Request Failed:', error, true);

    return Promise.reject(error.response || error.message);
  };

  return request(options).then(onSuccess).catch(onError);
};

export default apiRequest;
