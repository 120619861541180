import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Typography, Box } from '@material-ui/core';

/**
 * Overrides Refresh Button Box
 */
const Refresh = () => (
  <Box
    component='span'
    style={{
      backgroundColor: 'green',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 5,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 4,
      boxShadow: '0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%'
    }}
  >
    <RefreshIcon style={{
      color: 'white',
      fontSize: '20px',
    }}
    />
    <Typography style={{
      color: 'white',
      fontSize: '12px',
      paddingTop: 2,
      paddingLeft: 6,
      textTransform: 'uppercase'
    }}
    >
      Refresh
    </Typography>
  </Box>
);

export default Refresh;
