import React, { useState, useContext, useEffect } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper, MenuItem, TextField
} from '@material-ui/core';
import { Context } from '../../../components/ContextProvider';
import { form } from '../../../helpers/styles';
import TitleBar from '../../../components/TitleBar';
import SnackBar, { defaultErrorSnackState, defaultSnackState } from '../../../components/SnackBar';
import { deleteAlerts, getAlerts, setGroupByAlert } from '../../../api/alerts';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { settingsServicesAndLabels, defaultSettings } from '../../../api/billing';
import Table from '../../../components/Table';
import Back from '../../../components/Button/Back';
import SaveReset from '../../../components/Button/SaveReset';

const useStyles = makeStyles(() => ({
  ...form,
  textField: {
    marginTop: 0
  }
}));

const groups = {
  service: 'Total',
  project_label: 'Project Label',
  resource_label: 'Resource Label',
};

const defaultAlert = {
  group_by: 'service',
  label_key: '',
  label_value: '',
  quota: ''
};

const Alerts = () => {
  const classes = useStyles();

  const { selectedCustomer, billingAccountId, billingAccounts } = useContext(Context);

  const [alerts, setAlerts] = useState([]);
  const [alert, setAlert] = useState(defaultAlert);
  const [settings, setSettings] = useState(defaultSettings);
  const [isLoading, setIsLoading] = useState(true);
  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [snack, setSnack] = useState(defaultSnackState);

  const billingSettings = () => settingsServicesAndLabels(
    selectedCustomer,
    billingAccountId
  ).then((response) => {
    setSettings({
      project_label: response.project_labels,
      resource_label: response.resource_labels,
    });
  });

  const alertData = () => getAlerts(selectedCustomer).then((response) => {
    if (response) {
      setAlerts(response);
    }
  });

  const handleChange = (key, value) => {
    const newValues = {
      ...alert,
      [key]: value || ''
    };

    setAlert(newValues);
  };

  const handleSubmit = () => {
    setUpdateIndicator(true);

    setGroupByAlert(selectedCustomer, alert).then(() => {
      setAlert(defaultAlert);

      setSnack({
        open: true,
        message: 'Alert successfully saved',
        onClose: () => setSnack(defaultSnackState)
      });
    }).catch(() => {
      setSnack({
        open: true,
        message: 'Error saving Alert',
        severity: 'error',
        onClose: () => setSnack(defaultErrorSnackState)
      });
    }).finally(() => {
      alertData();
      setUpdateIndicator(false);
    });
  };

  const handleDelete = (data) => {
    setUpdateIndicator(true);

    deleteAlerts(selectedCustomer, data).then(() => {
      setSnack({
        open: true,
        message: 'Alert(s) successfully deleted',
        onClose: () => setSnack(defaultSnackState)
      });
    }).catch(() => {
      setSnack({
        open: true,
        message: 'Error deleting Alert',
        severity: 'error',
        onClose: () => setSnack(defaultErrorSnackState)
      });
    }).finally(() => {
      alertData();
      setUpdateIndicator(false);
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    billingSettings()
      .then(() => alertData())
      .finally(() => setIsLoading(false));
  }, [selectedCustomer, billingAccountId]);

  const renderLabels = () => (alert.group_by === 'service' ? (
    <>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          label='Label Key'
          className={classes.input}
          variant='outlined'
          value=''
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled
          fullWidth
          label='Label Value'
          className={classes.input}
          variant='outlined'
          value=''
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={12} md={2}>
        <TextValidator
          fullWidth
          select
          label='Label Key'
          className={classes.input}
          variant='outlined'
          value={alert.label_key}
          onChange={(e) => setAlert({
            ...alert,
            label_key: e.target.value,
            label_value: ''
          })}
          InputLabelProps={{
            shrink: true,
          }}
          validators={['required']}
          errorMessages={['This field is required']}
        >
          {
            Object.keys(settings[alert.group_by]).map((label, i) => (
              <MenuItem key={i} value={label}>{label}</MenuItem>
            ))
          }
        </TextValidator>
      </Grid>
      <Grid item xs={12} md={2}>
        <TextValidator
          disabled={!alert.label_key}
          fullWidth
          select
          label='Label Value'
          className={classes.input}
          variant='outlined'
          value={alert.label_value}
          onChange={(e) => handleChange('label_value', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          validators={['required']}
          errorMessages={['This field is required']}
        >
          {
            alert.label_key && settings[alert.group_by][alert.label_key]
              ? settings[alert.group_by][alert.label_key].map((label, i) => (
                <MenuItem key={i} value={label}>{label}</MenuItem>
              )) : (
                <MenuItem value='' disabled />
              )
          }
        </TextValidator>
      </Grid>
    </>
  ));

  const renderForm = () => (
    <Paper
      elevation={0}
      className={classes.paper}
    >
      <Grid
        container
        spacing={2}
        alignItems='center'
      >
        <Grid item xs={12} md={4}>
          <Typography>
            Alert when Month-To-Date spend exceeds:
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextValidator
            fullWidth
            select
            label='Group By'
            className={classes.input}
            variant='outlined'
            value={alert.group_by}
            onChange={(e) => setAlert({
              ...defaultAlert,
              group_by: e.target.value
            })}
            InputLabelProps={{
              shrink: true,
            }}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            <MenuItem value='service'>Total</MenuItem>
            <MenuItem value='project_label'>Project Label</MenuItem>
            <MenuItem value='resource_label'>Resource Label</MenuItem>
          </TextValidator>
        </Grid>
        {renderLabels()}
        <Grid item xs={12} md={2}>
          <TextValidator
            className={classes.textField}
            fullWidth
            label='quota'
            variant='outlined'
            type='number'
            onChange={(e) => handleChange('quota', Number(e.target.value))}
            inputProps={{ min: 0 }}
            value={alert.quota}
            validators={['required']}
            errorMessages={['This field is required']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  const renderTable = () => (
    <Table
      title=''
      columns={[
        {
          title: 'Group By',
          field: 'group_by',
          render: (rowData) => groups[rowData.group_by],
          customFilterAndSearch: (term, rowData) => (
            groups[rowData.group_by].toLowerCase()
          ).indexOf(term.toLowerCase()) !== -1
        },
        {
          title: 'Label Key',
          field: 'label_key'
        },
        {
          title: 'Label Value',
          field: 'label_value'
        },
        {
          title: 'Quota',
          field: 'quota',
          type: 'numeric'
        },
      ]}
      data={alerts}
      actions={[
        {
          tooltip: 'Remove selected alerts',
          icon: 'delete',
          onClick: (evt, newValue) => {
            handleDelete(newValue);
          }
        }
      ]}
      options={{
        selection: true
      }}
    />
  );

  /**
   * Remove this validation when billing_account_id is added to Alerts, Filters and Export
   */
  if (billingAccountId !== billingAccounts[0]) {
    return (
      <>
        <TitleBar title='Billing Alerts'>
          <Back path='/' />
        </TitleBar>
        <Typography>
          Alerts is not implemented for this billing account
        </Typography>
      </>
    );
  }

  return (
    isLoading ? <LoadingIndicator /> : (
      <>
        <TitleBar
          title='Billing Alerts'
          updateIndicator={updateIndicator}
        >
          <Back path='/' />
        </TitleBar>

        <Box mb={2}>
          {renderTable()}
        </Box>

        <Box mb={2}>
          <ValidatorForm onSubmit={() => handleSubmit()}>
            {renderForm()}
            <SaveReset
              updateIndicator={updateIndicator}
              reset={() => setAlert(defaultAlert)}
            />
          </ValidatorForm>
        </Box>

        <SnackBar
          open={snack.open}
          message={snack.message}
          severity={snack.severity}
          onClose={snack.onClose}
        />

      </>
    )
  );
};

export default Alerts;
