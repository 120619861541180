import React, { useEffect, useState } from 'react';
import TitleBar from '../../components/TitleBar';
import LoadingIndicator from '../../components/LoadingIndicator';
import firebaseApp from '../../firebase/Base';
import CustomersTable from './CustomersTable';
import { getCustomerList } from '../../api/customers';

const CustomerManagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [entities, setEntities] = useState(null);
  const [data, setData] = useState(null);

  let listener = null;

  const entitiesData = () => {
    firebaseApp.firestore()
      .collection('entities')
      .orderBy('name', 'asc')
      .get()
      .then((response) => {
        const entitiesArray = {};

        response.forEach((doc) => {
          entitiesArray[doc.id] = {
            id: doc.id,
            ...doc.data()
          };
        });

        setEntities(entitiesArray);
      });
  };

  const customersData = () => {
    listener = firebaseApp.firestore().collection('users').onSnapshot((response) => {
      const users = [];
      response.forEach((doc) => {
        users[doc.id] = doc.data();
      });

      getCustomerList().then((customers) => {
        setData({
          users,
          customers
        });
        setIsLoading(false);
      });
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    entitiesData();
    customersData();

    return () => listener();
  }, []);

  return (
    <>
      <TitleBar title='Customer Management' />
      {
        isLoading ? <LoadingIndicator /> : (
          <CustomersTable
            data={data}
            entities={entities}
          />
        )
      }
    </>
  );
};

export default CustomerManagement;
