import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@material-ui/core';

const Top = ({
  selected,
  handleSelected
}) => (
  <TextField
    select
    label='Display'
    variant='outlined'
    value={selected}
    onChange={(e) => handleSelected(e.target.value)}
    style={{
      width: 150,
      marginRight: 20
    }}
    InputLabelProps={{
      shrink: true,
    }}
  >
    <MenuItem value={5}>Top 5</MenuItem>
    <MenuItem value={10}>Top 10</MenuItem>
    <MenuItem value={15}>Top 15</MenuItem>
    <MenuItem value={0}>All</MenuItem>
  </TextField>
);

Top.propTypes = {
  selected: PropTypes.number.isRequired,
  handleSelected: PropTypes.func.isRequired
};

export default Top;
