import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper, TableContainer, Typography, TableCell, TableRow, TableBody, TableHead, Table, Grid
} from '@material-ui/core';

const ConfirmDialog = ({
  update,
  users,
  role,
  customers
}) => (
  <Grid container spacing={2} direction='column'>
    <Grid item>
      <Typography variant='subtitle2'>
        {
          update
            ? 'Confirm the updated data for the current user:'
            : 'Users with the following email addresses are invited to Command Center:'
        }
      </Typography>
    </Grid>
    <Grid item>
      <TableContainer component={Paper}>
        <Table aria-label='Invited users email'>
          <TableHead>
            <TableRow>
              <TableCell>
                {`Email${update ? '' : 's'}`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user}>
                <TableCell>{user}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    <Grid container item direction='column'>
      <Grid item>
        <Typography variant='subtitle2'>
          with
          {' '}
          <span style={{ fontWeight: 'bold' }}>{`Role ${role}`}</span>
        </Typography>
        <Typography variant='subtitle2'>
          with access to the following
          {' '}
          <span style={{ fontWeight: 'bold' }}>Customers</span>
        </Typography>
      </Grid>
    </Grid>
    <Grid container item direction='column'>
      <Grid item>
        <TableContainer component={Paper}>
          <Table aria-label='Customers Access'>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>{customer.id}</TableCell>
                  <TableCell>{customer.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Grid>
);

ConfirmDialog.defaultProps = {
  update: false,
};

ConfirmDialog.propTypes = {
  update: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  role: PropTypes.string.isRequired,
  customers: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ConfirmDialog;
