import React, { useState, useEffect, useContext } from 'react';
import Chart from '../Chart';
import { Context } from '../../../../components/ContextProvider';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { totalResourceLabels } from '../../../../api/billing';
import { cacheName } from '../../../../helpers/utils';

const ChartTotalResourceLabels = () => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    selectedCustomer, settings, chartTotalResourceLabels, updateContext, billingAccountId
  } = useContext(Context);

  const cache = cacheName(`${selectedCustomer}-${billingAccountId}`, settings);

  const getTotalData = () => {
    totalResourceLabels(
      selectedCustomer,
      billingAccountId,
      settings
    ).then((response) => {
      updateContext({
        chartTotalResourceLabels: {
          ...chartTotalResourceLabels,
          resourceLabel: settings.resourceLabel,
          [cache]: response
        }
      });
    });
  };

  useEffect(() => {
    if (
      chartTotalResourceLabels
      && chartTotalResourceLabels[cache]
      && chartTotalResourceLabels.resourceLabel === settings.resourceLabel
    ) {
      setIsLoading(false);
    } else {
      getTotalData();
    }
  }, [billingAccountId]);

  return (
    isLoading ? <LoadingIndicator /> : (
      <Chart
        title={settings.resourceLabel}
        data={chartTotalResourceLabels[cache]}
      />
    )
  );
};

export default ChartTotalResourceLabels;
