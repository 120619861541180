import { dateNowMoment, dateMomentSubtractDays } from './date';
import storage from './storage';

/**
 * Settings cache List
 */
export const settingsDateList = {
  '7days': 'Last 7 days',
  '30days': 'Last 30 days',
  '12months': 'Last 12 months',
  CustomizedPeriod: 'Customized Period'
};

export const groupByOptions = {
  serviceAndProject: 'Service',
  projectLabel: 'Project Label',
  resourceLabel: 'Resource Label'
};

/**
 * Set Storage Settings value
 */
export const set = (key:string, value: Object) => {
  storage.set([key], value);
};

/**
 * Default Settings
 */
export const defaultSettings = {
  groupBy: 'serviceAndProject',
  selectedDate: '12months',
  startDate: dateMomentSubtractDays(10),
  endDate: dateNowMoment(),
  provider: 'gcp',
  projectLabel: '',
  resourceLabel: '',
  services: null
};

/**
 * Default Selected
 */
export const defaultSelected = {
  showSpendSum: true,
  historicalData: 'hideData',
  top: 10
};

/**
 * Providers Labels
 */
export const providersLabels = {
  gcp: 'GCP',
  aws: 'AWS',
  azure: 'Azure',
  all: 'All'
};

/**
 * Storage Settings
 */
export const settings = () => (storage.get('settings') || defaultSettings);

/**
 * Storage selected
 */
export const selected = () => (storage.get('selected') || defaultSelected);
