import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';
import firebase from '../../firebase/Base';
import { Context } from '../../components/ContextProvider';

export default function Logout() {
  const history = useHistory();
  const { clearContex } = useContext(Context);

  const logoutActions = () => {
    firebase.auth().signOut().then(() => {
      clearContex();
      history.push('/');
    });
  };

  useEffect(() => {
    logoutActions();
  }, []);

  return <LoadingIndicator />;
}
