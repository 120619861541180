import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import Anomalies from '../../pages/Anomalies';
import Billing from '../../pages/Billing';
import ProjectProvisioning from '../../pages/ProjectProvisioning';
import ProjectProvisioningNew from '../../pages/ProjectProvisioning/New';
import SystemHealth from '../../pages/SystemHealth';
import SupportRequest from '../../pages/SupportRequest';
import NewSupportRequest from '../../pages/SupportRequest/New';
import ViewSupportRequest from '../../pages/SupportRequest/View';
import Inventory from '../../pages/Inventory';
import CustomerManagement from '../../pages/CustomerManagement';
import CustomerManagementView from '../../pages/CustomerManagement/View';
import CustomerManagementNew from '../../pages/CustomerManagement/New';
import CustomerManagementUpdate from '../../pages/CustomerManagement/Update';
import EntityManagement from '../../pages/EntityManagement';
import EntityManagementNew from '../../pages/EntityManagement/New';
import EntityManagementUpdate from '../../pages/EntityManagement/Update';
import ViewInventory from '../../pages/Inventory/View';
import Playbook from '../../pages/Playbook';
import Alerts from '../../pages/Billing/Alerts';
import Viewers from '../../pages/Billing/Viewers';
import UserManagement from '../../pages/UserManagement';
import UserManagementNew from '../../pages/UserManagement/New';
import UserManagementUpdate from '../../pages/UserManagement/Update';
import WorkspaceLicense from '../../pages/WorkspaceLicense';
import Logout from '../../pages/SignIn/Logout';
import NotFound from '../../pages/NotFound';
import rbac from '../../helpers/rbac';
import { Context } from '../ContextProvider';
import ViewLicensePage from '../../pages/WorkspaceLicense/pages/ViewLicensePage';
import ViewOrderPage from '../../pages/WorkspaceLicense/pages/ViewOrderPage';

const Router = () => {
  const { user } = useContext(Context);

  return (
    <Switch>
      {
        // Billing
      }
      <Route exact path='/' render={() => <Billing />} />
      {
        // System Health
      }
      {
        rbac('systemhealth_*') && (
          <Route path='/systemhealth' render={() => <SystemHealth />} />
        )
      }
      {
        rbac('billing_*') && (
          <Route path='/anomalydetection' render={() => <Anomalies />} />
        )
      }
      {
        // rbac('workspacelicense_*') && (
        <Route exact path='/workspacelicense' render={() => <WorkspaceLicense />} />
        // )
      }
      {
        // View license
        <Route exact path='/workspacelicense/license' render={() => <ViewLicensePage />} />
        //
      }
      {
        // View order
        <Route exact path='/workspacelicense/order' render={() => <ViewOrderPage />} />
      }

      {
        rbac('projectprovisioning_create') && (
          <Route exact path='/projectprovisioning' render={() => <ProjectProvisioning />} />

        )
      }
      {
        rbac('projectprovisioning_create') && (
          <Route exact path='/projectprovisioning/new' render={() => <ProjectProvisioningNew />} />
        )
      }
      {
        // rbac('workspacelicense_*') && (
        true && (
          <Route path='/workspacelicense' render={() => <WorkspaceLicense />} />
        )
      }
      {
        // Inventory
      }
      {
        rbac('inventory_*') && (
          <Route exact path='/inventory/view' render={() => <ViewInventory />} />
        )
      }
      {
        rbac('inventory_*') && (
          <Route exact path='/inventory' render={() => <Inventory />} />
        )
      }
      {
        // Support Request - Rapid Architecture Ticket
      }
      {
        rbac('supportrequest_create') && (
          <Route exact path='/supportrequest/new' render={() => <NewSupportRequest />} />
        )
      }
      {
        rbac('supportrequest_read') && (
          <Route exact path='/supportrequest/view/:id' render={() => <ViewSupportRequest />} />
        )
      }
      {
        rbac('supportrequest_read') && (
          <Route exact path='/supportrequest' render={() => <SupportRequest />} />
        )
      }
      {
        // Playbook
      }
      {
        rbac('playbook_*') && (
          <Route path='/playbook' render={() => <Playbook />} />
        )
      }
      {
        // Entity
      }
      {
        rbac('entitymanagement_read') && (
          <Route exact path='/entitymanagement' render={() => <EntityManagement />} />
        )
      }
      {
        rbac('entitymanagement_create') && (
          <Route exact path='/entitymanagement/new' render={() => <EntityManagementNew />} />
        )
      }
      {
        rbac('entitymanagement_update') && (
          <Route exact path='/entitymanagement/update/:id' render={() => <EntityManagementUpdate />} />
        )
      }
      {
        // Customer
      }
      {
        rbac('customermanagement_create') && (
          <Route exact path='/customermanagement/new' render={() => <CustomerManagementNew />} />
        )
      }
      {
        rbac('customermanagement_update') && (
          <Route exact path='/customermanagement/update/:id' render={() => <CustomerManagementUpdate />} />
        )
      }
      {
        (rbac('customermanagement_read') && user.claims.role === 'admin') && (
          <Route exact path='/customermanagement' render={() => <CustomerManagement />} />
        )
      }
      {
        (rbac('customermanagement_read') && user.claims.role === 'customer') && (
          <Route exact path='/customermanagement/view' render={() => <CustomerManagementView />} />
        )
      }
      {
        // User Management
      }
      {
        rbac('usermanagement_read') && (
          <Route exact path='/usermanagement' render={() => <UserManagement />} />
        )
      }
      {
        rbac('usermanagement_create') && (
          <Route path='/usermanagement/new' render={() => <UserManagementNew />} />
        )
      }
      {
        rbac('usermanagement_update') && (
          <Route path='/usermanagement/update/:id' render={() => <UserManagementUpdate />} />
        )
      }
      {
        // Billing Alerts
      }
      {
        rbac('alerts_*') && (
          <Route exact path='/billing/alerts' render={() => <Alerts />} />
        )
      }
      {
        // Billing Viewers
      }
      {
        rbac('alerts_*') && (
          <Route exact path='/billing/viewers' render={() => <Viewers />} />
        )
      }

      <Route path='/logout' render={() => <Logout />} />
      <Route path='*' render={() => <NotFound />} />
    </Switch>
  );
};

export default Router;
