import { validateDriveId } from '../helpers/customers';
import apiRequest from './apiRequest';

/**
 * Save New Customer
 */
export const saveNewCustomer = (customer: Object) => {
  const data = {
    ...customer,
    drive_id: validateDriveId(customer.drive_id)
  };

  const options = {
    url: '/v1/customers/',
    method: 'POST',
    data
  };

  return apiRequest(options);
};

/**
 * Update Customer
 */
export const updateCustomer = (customer: Object) => {
  const data = {
    ...customer,
    drive_id: validateDriveId(customer.drive_id)
  };
  const options = {
    url: `/v1/customers/${data.customer_id}/`,
    method: 'PUT',
    data
  };

  return apiRequest(options);
};

/**
 * Get Customer
 */
export const getCustomer = (id: string) => {
  const options = {
    url: `/v1/customers/${id}/`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Customer List
 */
export const getCustomerList = () => {
  const options = {
    url: '/v1/customers/',
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Customer Project Access
 */
export const getCustomerProject = (id: string) => {
  const options = {
    url: `/v1/customers/${id}/service/access/`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Customer Password
 */
export const getCustomerPassword = (id: string) => {
  const options = {
    url: `/v1/monitoring/${id}/password`,
    method: 'POST',
    payload: false
  };

  return apiRequest(options, false);
};

/**
 * Customer Service Script
 */
export const getServiceScript = (id: string, data: Array) => {
  const options = {
    url: `/v1/customers/${id}/service/script/`,
    method: 'POST',
    data
  };

  return apiRequest(options);
};
