/* eslint-disable import/prefer-default-export */
import apiRequest from './apiRequest';

/**
 * Customer Folder data
 */
export const customerFolder = (customerId: string, folderId: string) => {
  const options = {
    url: `/v1/gdrive/customers/${customerId}/folders/${folderId}/`,
    method: 'GET'
  };

  return apiRequest(options);
};
