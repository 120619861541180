import apiRequest from './apiRequest';

/**
 * Customer inventory data
 */
export const inventoryVisualization = (customerId: string) => {
  const options = {
    url: `/v1/asset-inventory/${customerId}/visualize/`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Customer node data
 */
export const inventoryNode = (customerId: string, nodeId: string) => {
  const options = {
    url: `/v1/asset-inventory/${customerId}/node/${nodeId.replace(/\//g, '*')}`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Project create TODO: Move to own api call in future
 */
export const projectCreate = (
  customerId: String, bu: String, product: String, env: String,
  ownerValue: String, templateName: String, folderName: String,
  folderNumber: String, projectType: String, ptProjectType: String,
  ptDefaultAdmin: String, ptBudget: Number, ptOwnerLabel: String,
  ptProjectName: String, qBigqueryAnalysis: String,
  qComputeEngineCpus: String
) => {
  const data = {
    customer_id: customerId,
    bu,
    product,
    env,
    owner_value: ownerValue,
    template_name: templateName,
    folder_name: folderName,
    folder_number: folderNumber,
    project_type: projectType,
    project_template: {
      project_type: ptProjectType,
      default_admin: ptDefaultAdmin,
      budget: ptBudget,
      owner_label: ptOwnerLabel,
      project_name: ptProjectName,
      quota: {
        bigquery_analysis: qBigqueryAnalysis,
        compute_engine_cpus: qComputeEngineCpus
      }
    }
  };
  console.log(data);
  const options = {
    url: '/v1/asset-inventory/project/create',
    method: 'POST',
    data
  };
  return apiRequest(options);
};
