import apiRequest from './apiRequest';

/**
 * MOVE TO MANAGEMENT API
 */

/**
 * Set User Claims
 */
export const setClaims = () => {
  const options = {
    url: '/v1/customers/set_claims/',
    method: 'POST'
  };

  return apiRequest(options);
};

/**
 * Update User Claims
 */
export const updateClaims = (email) => {
  const options = {
    url: '/v1/customers/update_claims/',
    method: 'POST',
    data: {
      user_id: email
    }
  };

  return apiRequest(options);
};
