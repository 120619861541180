import React, {
  useEffect, useState, useContext
} from 'react';
import { Paper, Box } from '@material-ui/core';
import TitleBar from '../../components/TitleBar';
import Table from '../../components/Table';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import { customerFolder } from '../../api/playbook';

const Playbook = () => {
  const { customerData, selectedCustomer } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const playbookData = () => {
    if (customerData.documentation) {
      customerFolder(selectedCustomer, 'playbooks').then((response) => {
        setData(response);
        setIsLoading(false);
      }).catch(() => {
        setData([]);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    playbookData();
  }, [selectedCustomer]);

  return (
    customerData.documentation ? (
      <>
        <TitleBar
          title='Playbook'
        />
        {
          isLoading ? <LoadingIndicator /> : (
            <>
              <Table
                title=''
                data={data}
                columns={[
                  {
                    title: 'Name',
                    field: 'name',
                  }
                ]}
                onRowClick={(evt, rowData) => {
                  const url = `https://docs.google.com/document/d/${rowData.id}/preview`;
                  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                  if (newWindow) newWindow.opener = null;
                }}
              />
              <Paper>
                <Box fontWeight='fontWeightBold' p={2} mt={2}>
                  {`Google Drive Folder: ${customerData.drive_id}`}
                </Box>
              </Paper>
            </>
          )
        }
      </>
    ) : (
      <TitleBar
        title='Playbook'
        subTitle={`for ${customerData.customer_name} is disabled`}
      />
    )
  );
};

export default Playbook;
