import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper, MenuItem, Button
} from '@material-ui/core';
import firebaseApp from '../../firebase/Base';
import roles from '../../helpers/roles';
import { emailIsValid } from '../../helpers/utils';
import { form } from '../../helpers/styles';
import LoadingIndicator from '../../components/LoadingIndicator';
import TitleBar from '../../components/TitleBar';
import { customerData } from '../../helpers/customers';
import { emailSplit, dialogInitalState, userExists } from '../../helpers/users';
import Dialog from '../../components/Dialog';
import { addUserToCustomer, createUser } from '../../firebase/requests';
import ConfirmDialog from './ConfirmDialog';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import { Context } from '../../components/ContextProvider';
import CustomersAccess from './CustomersAccess';
import SubmitGroup from '../../components/Button/SubmitGroup';

const useStyles = makeStyles(() => (form));

const New = () => {
  const classes = useStyles();
  const history = useHistory();

  const { entities } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [email, setEmail] = useState();
  const [role, setRole] = useState('');
  const [access, setAccess] = useState([]);
  const [errorDialog, setErrorDialog] = useState(dialogInitalState);
  const [confirmDialog, setConfirmDialog] = useState(dialogInitalState);
  const [snack, setSnack] = useState(defaultSnackState);
  const [updateIndicator, setUpdateIndicator] = useState(false);

  const back = () => {
    history.push('/usermanagement');
  };

  const successSnack = (count) => {
    setSnack({
      open: true,
      message: `${count} user(s) invited with success`
    });
  };

  const saveUsers = async () => {
    const emails = emailSplit(email);
    setUpdateIndicator(true);

    try {
      await Promise.all(
        emails.map(async (user) => {
          await createUser(user, role);

          return Promise.all(
            access.map(async (customer) => addUserToCustomer(customer.id, user)),
          ).then(() => {
            successSnack(emails.length);
          });
        })
      );
    } catch (err) {
      setUpdateIndicator(false);

      setErrorDialog({
        open: true,
        title: 'Firebase error',
        description: err.message,
      });
    }
  };

  const validateUsers = () => {
    const emails = emailSplit(email);

    const errorList = [];

    access.forEach((customer) => {
      const customerUsers = customerData(data, customer.id, 'id').users;

      emails.forEach((userEmail) => {
        if (userExists(userEmail, customerUsers) && !errorList.includes(userEmail)) {
          errorList.push(userEmail);
        }
      });
    });

    if (errorList.length > 0) {
      setErrorDialog({
        open: true,
        title: 'E-mail already exists',
        description: `${errorList.toString().replaceAll(',', ', ')} already exists, please provide a different email`,
      });
    } else {
      setConfirmDialog({
        open: true,
        title: 'Please confirm',
      });
    }
  };

  const handleSubmit = () => {
    if (access.length === 0) {
      setErrorDialog({
        open: true,
        title: 'User Access',
        description: 'Please grant access to at least one customer',
      });
    } else {
      validateUsers();
    }
  };

  const customersData = () => {
    firebaseApp.firestore().collection('customers').get().then((customersSnap) => {
      const customersArray = [];

      customersSnap.forEach((doc) => {
        customersArray.push({
          ...doc.data(),
          id: doc.id
        });
      });

      setData(customersArray);
      setIsLoading(false);
    });
  };

  const emailValidator = () => {
    ValidatorForm.addValidationRule('multipleEmails', (value) => {
      if (value !== email) {
        const emails = emailSplit(value);

        if (!emails.map((user) => emailIsValid(user)).every(Boolean)) {
          return false;
        }
      }

      return true;
    });
  };

  useEffect(() => {
    customersData();
    emailValidator(true);
  }, []);

  const renderForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h6'>
              User
            </Typography>
          </Box>
          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='role'
            variant='outlined'
            onChange={(event) => setRole(event.target.value)}
            name='role'
            value={role}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {roles.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextValidator>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Email'
            variant='outlined'
            onChange={(event) => setEmail(event.target.value.toLowerCase().trim())}
            name='email'
            value={email}
            validators={['required', 'multipleEmails']}
            errorMessages={[
              'This field is required',
              'Email list is not valid'
            ]}
            multiline
            rows={5}
            helperText={
              `To invite multiple users, enter emails separated by comma e.g.
              email@email.com, email@email1.com, email@email2.com.`
            }
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper elevation={0} className={classes.paper}>
          <CustomersAccess
            entities={entities}
            access={access}
            setAccess={(value) => setAccess(value)}
            options={data}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  const renderDialogs = () => (
    <>
      <Dialog
        title={errorDialog.title}
        open={errorDialog.open}
        onClose={() => setErrorDialog(dialogInitalState)}
      >
        <Typography gutterBottom>
          {errorDialog.description}
        </Typography>
      </Dialog>

      <Dialog
        title={confirmDialog.title}
        open={confirmDialog.open}
        onClose={() => setConfirmDialog(dialogInitalState)}
        actionButtons={(
          <>
            <Button
              color='primary'
              onClick={() => {
                setConfirmDialog(dialogInitalState);
                saveUsers();
              }}
              disabled={updateIndicator}
            >
              Submit
            </Button>
            <Button
              onClick={() => setConfirmDialog(dialogInitalState)}
              color='default'
              disabled={updateIndicator}
            >
              Cancel
            </Button>
          </>
        )}
      >
        <Box pb={2}>
          {confirmDialog.open && (
            <ConfirmDialog
              users={email.split(',')}
              role={role}
              customers={access}
            />
          )}
        </Box>
      </Dialog>
    </>
  );

  const renderButtons = () => (
    <SubmitGroup
      cancel={() => back()}
      updateIndicator={updateIndicator}
    />
  );

  const renderSnack = () => (
    <SnackBar
      open={snack.open}
      message={snack.message}
      onClose={() => back()}
    />
  );

  return (
    <>
      <TitleBar
        title='User Management'
        subTitle='New'
        updateIndicator={updateIndicator}
      />
      {
        isLoading ? <LoadingIndicator /> : (
          <ValidatorForm onSubmit={() => handleSubmit()}>

            {renderForm()}
            {renderButtons()}
            {renderDialogs()}
            {renderSnack()}

          </ValidatorForm>
        )
      }
    </>
  );
};

export default New;
