import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { dateMoment } from '../../helpers/date';
import colors from '../../helpers/colors';
import packageInfo from '../../../package.json';

const useStyles = makeStyles(() => ({
  root: {
    width: '290px',
    position: 'fixed',
    textAlign: 'center',
    bottom: 0,
    paddingBottom: 10,
  },
  link: {
    color: colors.poppy
  },
  version: {
    fontSize: '10px'
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant='body1'
        align='center'
      >
        &copy;
        {dateMoment()}
        {' '}
        <span className={classes.link}>
          <Link
            component='a'
            href='https://gcloud.devoteam.com'
            target='_blank'
            color='inherit'
          >
            Devoteam GCloud
          </Link>
        </span>
        {' '}
        <span className={classes.version}>
          {` v${packageInfo.version}`}
        </span>
      </Typography>
    </div>
  );
};

export default Footer;
