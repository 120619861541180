import apiRequest from './apiRequest';
import { deleteFile } from './files';

const { REACT_APP_API_URL } = process.env;

/**
 * Customer Folder data
 */
export const jiraTicketsList = (customerId: string) => {
  const options = {
    url: `/v1/jira/msp_tickets/${customerId}/list`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Get Jira ticket by Id
 */
export const jiraTicketById = (id: Number) => {
  const options = {
    url: `/v1/jira/msp_tickets/${id}`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Get Jira ticket by Id
 */
export const avaiableStatusList = (role: string, state: string) => {
  const options = {
    url: `/v1/jira/msp_tickets/${role}/{state}/transitions?status=${state}`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Get Jira ticket by Id
 */
export const ticketComments = (id: Number) => {
  const options = {
    url: `/v1/jira/msp_tickets/${id}/comment`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Jira Priority List
 */
export const signedUrlAttachment = (filename) => {
  const options = {
    url: `/v1/jira/signed_url?filename=${filename}`,
    method: 'GET'
  };

  return apiRequest(options);
};

/**
 * Save New Ticket
 */
export const saveNewTicket = (data: Object) => {
  const options = {
    url: '/v1/jira/msp_tickets',
    method: 'POST',
    data
  };

  return apiRequest(options);
};

/**
 * Save New Ticket
 */
export const deleteAttachment = (data: Object, attachement: Object) => {
  const url = `${REACT_APP_API_URL}/v1/jira/msp_tickets/${data.jira_issue_key}/attachment/${attachement.id}`;
  return deleteFile(url);
};

export const addAttachementTicket = (jiraId: string, data: Object) => {
  const options = {
    url: `/v1/jira/msp_tickets/${jiraId}/attachment`,
    method: 'PATCH',
    data
  };

  return apiRequest(options);
};

/**
 * Save New Comment
 */
export const saveNewComment = (jiraId: string, data: string) => {
  const options = {
    url: `/v1/jira/msp_tickets/${jiraId}/comment`,
    method: 'POST',
    data
  };

  return apiRequest(options);
};

/**
 * Save Satisfaction feedback
 */
export const saveSatisfaction = (jiraId: string, data: string) => {
  const options = {
    url: `/v1/jira/msp_tickets/${jiraId}/satisfaction`,
    method: 'patch',
    data
  };

  return apiRequest(options);
};

/**
 * Update Priority
 */
export const updateTicketPriority = (jiraId: string, data: Object) => {
  const options = {
    url: `/v1/jira/msp_tickets/${jiraId}/priority`,
    method: 'PATCH',
    data
  };

  return apiRequest(options);
};

/**
 * Update Status
 */
export const updateTicketStatus = (jiraId: string, data: string) => {
  const options = {
    url: `/v1/jira/msp_tickets/${jiraId}/status`,
    method: 'PATCH',
    data
  };

  return apiRequest(options);
};
