import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItemText, Box, ListItem, List, Paper,
  TextField, Typography, ListSubheader, ButtonGroup, Button, Modal, Switch, InputAdornment
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { viewLicenseStyle } from '../../helpers/styles';
import { createLicense } from '../../api/license';
import { Context } from '../../components/ContextProvider';
import { checkError, errorText } from '../../helpers/workspaceTextField';
import Dialog from '../../components/Dialog';

const CreateLicense = ({ setDoCreateLicense, doCreateLicense, setDoUpdateTable }) => {
  const [licenseData, setLicenseData] = useState({
    sku_id: '0',
    threshold: '0',
    auto_order: false,
    new_order: '1'

  });
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    title: '',
    description: ''
  });
  const listTitlesMap = new Map();
  listTitlesMap.set('sku_id', 'SKU');
  listTitlesMap.set('threshold', 'Threshold');
  listTitlesMap.set('auto_order', 'Auto Order');
  listTitlesMap.set('new_order', 'New Order');
  const {
    selectedCustomer
  } = useContext(Context);
  const useStyles = makeStyles(() => (viewLicenseStyle));
  const classes = useStyles();
  const createLicenseConfig = () => {
    createLicense(licenseData, selectedCustomer).then(() => {
      setErrorDialog({
        ...errorDialog, open: true, title: 'License was successfully created!', description: ''
      });
      setDoUpdateTable(true);
      setDoCreateLicense(false);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      setErrorDialog({
        ...errorDialog, open: true, title: 'Unable to create license', description: err.data.detail
      });
    });
  };

  const disabledButton = () => {
    if (checkError(licenseData.threshold)) {
      return true;
    }
    if (checkError(licenseData.new_order)) {
      return true;
    }
    if (checkError(licenseData.sku_id)) {
      return true;
    }
    return false;
  };

  const RenderTextField = (key) => {
    switch (key) {
    case 'new_order':
      return (
        licenseData.auto_order
          ? (
            <TextField
              id='standard-basic'
              defaultValue={`${licenseData[key]}`}
              onChange={(e) => setLicenseData({ ...licenseData, [key]: e.target.value })}
              error={checkError(licenseData[key])}
              helperText={errorText(licenseData[key])}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <ReceiptIcon />
                  </InputAdornment>
                ),
              }}
              focused
            />
          )
          : (
            <Typography
              component='span'
              variant='body2'
              className={classes.secondaryText}
            >
              Auto-Order needs to be activated
            </Typography>
          )
      );

    case 'auto_order':
      return (
        <span className={classes.secondaryText}>
          Off
          <Switch
            edge='end'
            checked={licenseData.auto_order}
            onChange={() => setLicenseData({
              ...licenseData,
              auto_order:
              !licenseData.auto_order,
              new_order: 1
            })}
          />
          On
        </span>
      );

    default:
      return (
        <TextField
          id='standard-basic'
          defaultValue='0'
          onChange={(e) => setLicenseData({ ...licenseData, [key]: e.target.value })}
          error={checkError(licenseData[key])}
          helperText={errorText(licenseData[key])}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <ReceiptIcon />
              </InputAdornment>
            ),
          }}
          focused
        />
      );
    }
  };

  const RenderListItem = (key) => (
    <ListItem key={key}>
      <ListItemText
        primary={(
          <Typography
            component='span'
            variant='body1'
          >
            {listTitlesMap.get(key)}
          </Typography>
        )}
      />
      {RenderTextField(key)}
    </ListItem>
  );

  return (
    <>
      <Modal
        open={doCreateLicense}
      >
        <Box mt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper elevation={1} className={classes.paper}>
            <List subheader={(
              <ListSubheader className={classes.headerTwo}>
                Create License Configuration
              </ListSubheader>
            )}
            >
              {
                Object.entries(licenseData).map(([key, value]) => (
                  RenderListItem(key, value)
                ))
              }
            </List>
            <ButtonGroup className={classes.orderButtonGroup}>
              <Button variant='contained' onClick={() => createLicenseConfig()} disabled={disabledButton()} className={classes.licenseButtonSave}>Create</Button>
              <Button variant='contained' onClick={() => setDoCreateLicense(false)} className={classes.licenseButtons}>Cancel</Button>
            </ButtonGroup>
          </Paper>
        </Box>
      </Modal>
      <Dialog
        title={errorDialog.title}
        open={errorDialog.open}
        onClose={() => setErrorDialog(false)}
      >
        <Typography gutterBottom>
          {errorDialog.description}
        </Typography>
      </Dialog>
    </>

  );
};

CreateLicense.propTypes = {
  doCreateLicense: PropTypes.bool.isRequired,
  setDoCreateLicense: PropTypes.func.isRequired,
  setDoUpdateTable: PropTypes.func.isRequired
};

export default CreateLicense;
