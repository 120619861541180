/**
 * App Default Colors
 */
const colors = {
  grey: '#E2E2E2',
  darkGreen: '#2e8b57',
  backgroundColor: 'whitesmoke',
  navigation: '#937949',

  darkGray: '#3C3C3A',
  mediumGray: '#B8B8B8',
  neutralGray: '#6B6B6B',
  gray: '#6B6B6B',
  lightGray: '#EEEEEE',
  poppy: '#FA4860',
  pink: '#FFF7F8',
  backgroundGray: '#F7F7F7',
  background: '#FCFCFC',
};

export default colors;
