import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Back from '../../../../components/Button/Back';
import TitleBar from '../../../../components/TitleBar';
import ManageOrderCard from '../../components/ManageOrderCard/ManageOrderCard';
import ViewOrderCard from '../../components/ViewOrderCard/ViewOrderCard';
import useWorkspaceLicenseStyles from '../../styles';

function ViewOrderPage() {
  const [managingOrder, setManagingOrder] = useState(false);
  const location = useLocation();
  const classes = useWorkspaceLicenseStyles();
  const slideDistance = window.innerWidth / 6;

  const { state: license } = location;

  const onManageOrderClick = () => setManagingOrder(!managingOrder);

  return (
    <>
      <TitleBar subTitle={`Manage order: ${license.sku_name}`} title='Workspace License'>
        <Back path='/workspacelicense' />
      </TitleBar>
      <div className={classes.orderContainer}>
        <ViewOrderCard
          license={license}
          managingOrder={managingOrder}
          slideDistance={slideDistance}
          onManageOrderClick={onManageOrderClick}
        />
        <ManageOrderCard
          license={license}
          managingOrder={managingOrder}
          slideDistance={slideDistance}
          onManageOrderClick={onManageOrderClick}
        />
      </div>
    </>
  );
}

export default ViewOrderPage;
