import React from 'react';
import { styled, Switch as MuiSwitch } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomizedSwitch = styled(MuiSwitch)(() => ({
  '& .MuiSwitch-track': {
    height: '7px',
    borderRadius: '1px',
    alignSelf: 'center'
  },
  '& .MuiSwitch-switchBase': {
    '& .MuiSwitch-thumb': {
      borderRadius: '5px',
    },
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#E75764',
      width: '50px'

    },
  },
  '&:hover .MuiSwitch-switchBase': {
    backgroundColor: 'unset',
    color: '#E75764',
  }
}));

export const Switch = ({ checked, onChange }) => (
  <CustomizedSwitch checked={checked} onChange={onChange} />
);

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Switch;
