import React, {
  useState, useEffect, useContext
} from 'react';
import { Context } from '../../../../components/ContextProvider';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { detailsResourceLabels } from '../../../../api/billing';
import { cacheName } from '../../../../helpers/utils';
import ChartMap from '../ChartMap';

const ChartResourceLabels = () => {
  const {
    selectedCustomer, settings, chartResourceLabels, updateContext, billingAccountId
  } = useContext(Context);

  const cache = cacheName(`${selectedCustomer}-${billingAccountId}`, settings);

  const [isLoading, setIsLoading] = useState(true);

  const getTotalData = () => {
    detailsResourceLabels(
      selectedCustomer,
      billingAccountId,
      settings,
    ).then((response) => {
      updateContext({
        chartResourceLabels: {
          ...chartResourceLabels,
          resourceLabel: settings.resourceLabel,
          [cache]: response
        }
      });
    });
  };

  useEffect(() => {
    if (
      chartResourceLabels
      && chartResourceLabels[cache]
      && chartResourceLabels.resourceLabel === settings.resourceLabel
    ) {
      setIsLoading(false);
    } else {
      getTotalData();
    }
  }, [billingAccountId]);

  return (
    isLoading ? <LoadingIndicator /> : (
      <ChartMap
        data={chartResourceLabels[cache]}
        title={settings.resourceLabel}
      />
    )
  );
};

export default ChartResourceLabels;
