/* eslint-disable no-console */
const { NODE_ENV } = process.env;

const log = {
  css: {
    data: 'color: blue; background-color: silver',
    debug: 'color: yellow; background-color: black',
    error: 'color: red; background-color: black',
    default: 'color: black; background-color: transparent'
  },

  /**
   * log error text
   */
  error: (text: string, error: any = '') => {
    console.error(`%c [ERROR] %c ${text}`, log.css.error, log.css.default, error);
  },

  /**
   * log debug
   */
  debug: (text: string) => {
    if (NODE_ENV === 'development') {
      console.log(`%c [DEBUG] %c ${text}`, log.css.debug, log.css.default);
    }
  },

  /**
   * log data
   */
  data: (text: string, msg: any, error: boolean = false) => {
    if (NODE_ENV === 'development') {
      console.log(
        `%c [${text}] %c`,
        error ? log.css.error : log.css.data,
        log.css.default,
        msg
      );
    }
  }
};

export default log;
