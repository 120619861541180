import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import ContextProvider from './components/ContextProvider';
import './index.css';
import App from './App';
import '@fontsource/montserrat';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ContextProvider>
      <App />
    </ContextProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
