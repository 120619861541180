import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Box, Grid, Paper, MenuItem, Accordion,
  AccordionSummary, AccordionDetails, InputAdornment, FormControlLabel, Checkbox
} from '@material-ui/core';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { form } from '../../helpers/styles';
import { getServiceScript } from '../../api/customers';
import Table from '../../components/Table/SimpleTable';
import TierDiscount from './TierDiscount';
import { currencies } from '../../helpers/customers';
import SubmitGroup from '../../components/Button/SubmitGroup';

const useStyles = makeStyles((theme) => ({
  ...form,
  advanced: {
    marginTop: 0
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    cursor: 'pointer'
  }
}));

const Form = ({
  entities,
  projects,
  customer,
  updateForm,
  updateIndicator,
  back
}) => {
  const classes = useStyles();

  const [values, setValues] = useState(customer);
  const [commands, setCommands] = useState({
    grantInput: '',
    revokeInput: '',
    grant: '',
    revoke: '',
  });

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    });
  };

  const handleCommandsChange = (key, value) => {
    setCommands({
      ...commands,
      [key]: value.trim()
    });
  };

  const shouldFieldsRender = !!(values.gcp_service_account || values.system_health_dashboard
    || values.documentation || values.workspace_license);

  const setScript = (action, ids) => {
    if (!ids) {
      return handleCommandsChange(action, '');
    }

    return getServiceScript(values.customer_id, {
      action,
      project_ids: ids.split(',')
    }).then(
      (response) => handleCommandsChange(action, response.script)
    );
  };

  const copyScript = (key) => {
    navigator.clipboard.writeText(commands[key]);
  };

  const renderAdvancedSettings = () => values.gcp_service_account && (
    <div className={classes.advanced}>
      <Accordion elevation={0} square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography className={classes.heading}>
            Advanced Settings
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.details}>
          <Box mb={2}>
            <Typography variant='h6'>
              Granted Access to customer GCP Projects
            </Typography>
          </Box>
          <Table
            data={projects}
            columns={[
              {
                title: 'Project Id',
                field: 'project_id',
              },
              {
                title: 'Project Name',
                field: 'project_name',
              }
            ]}
          />

          <Box mb={2} mt={2}>
            <Typography variant='h6'>
              Cloud Shell commands
            </Typography>
          </Box>
          <TextValidator
            className={classes.textField}
            fullWidth
            label='Grant access'
            variant='outlined'
            onChange={(event) => handleCommandsChange('grantInput', event.target.value)}
            value={commands.grantInput}
            multiline
            rows={1}
            InputProps={{
              spellCheck: 'false',
              endAdornment: (
                <InputAdornment
                  className={classes.icon}
                  position='end'
                  onClick={() => setScript('grant', commands.grantInput)}
                >
                  <RefreshRoundedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            helperText='Enter project ids in comma separated e.g. project1, project2, project3.'
          />

          {
            commands.grant ? (
              <TextValidator
                className={classes.textField}
                readOnly
                fullWidth
                name='accessCommand'
                variant='outlined'
                value={commands.grant}
                multiline
                rows={6}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={classes.icon}
                      position='end'
                      onClick={() => copyScript('grant')}
                    >
                      <FileCopyOutlinedIcon />
                    </InputAdornment>
                  )
                }}
              />
            ) : null
          }

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Revoke access'
            variant='outlined'
            onChange={(event) => handleCommandsChange('revokeInput', event.target.value)}
            value={commands.revokeInput}
            InputProps={{
              spellCheck: 'false',
              endAdornment: (
                <InputAdornment
                  className={classes.icon}
                  position='end'
                  onClick={() => setScript('revoke', commands.revokeInput)}
                >
                  <RefreshRoundedIcon />
                </InputAdornment>
              )
            }}
          />

          {
            commands.revoke ? (
              <TextValidator
                className={classes.textField}
                readOnly
                fullWidth
                name='revokeCommand'
                variant='outlined'
                value={commands.revoke}
                multiline
                rows={6}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={classes.icon}
                      position='end'
                      onClick={() => copyScript('revoke')}
                    >
                      <FileCopyOutlinedIcon />
                    </InputAdornment>
                  )
                }}
              />
            ) : null
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );

  const renderForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '100%' }}
        >
          <Box mb={2}>
            <Typography variant='h6'>
              Basic Information
            </Typography>
          </Box>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Name'
            variant='outlined'
            onChange={(event) => handleChange('customer_name', event.target.value)}
            value={values.customer_name}
            validators={['required']}
            errorMessages={['This field is required']}
          />

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Id'
            variant='outlined'
            value={values.customer_id}
            disabled
          />

          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Entity'
            variant='outlined'
            onChange={(event) => handleChange('entity', event.target.value)}
            value={values.entity}
            validators={['required']}
            errorMessages={['This field is required']}
          >
            {entities.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextValidator>

          <Box mb={2}>
            <Typography variant='h6'>
              Billing dashboard
            </Typography>
          </Box>

          <TextValidator
            className={classes.textField}
            fullWidth
            label='Billing account Id'
            variant='outlined'
            onChange={(event) => handleChange('billing_account_id', event.target.value.replaceAll(' ', ''))}
            value={values.billing_account_id}
            helperText='If customer has more than one Billing Id, separate with comma'
            validators={['required']}
            errorMessages={['This field is required']}
          />

          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Currency'
            variant='outlined'
            onChange={(event) => handleChange('billing_currency', event.target.value)}
            value={values.billing_currency || 'EUR'}
          >
            {
              currencies.map((currency, i) => (
                <MenuItem value={currency} key={`cur${i}`}>
                  {currency}
                </MenuItem>
              ))
            }
          </TextValidator>

          <TextValidator
            className={classes.textField}
            select
            fullWidth
            label='Dashboard type'
            variant='outlined'
            onChange={(event) => handleChange('billing_dashboard_type', event.target.value)}
            value={values.billing_dashboard_type}
          >
            <MenuItem value='Standard'>
              Standard (default)
            </MenuItem>
            <MenuItem value='Set Discount'>
              Set discount
            </MenuItem>
            <MenuItem value='Tier Discount'>
              Tier discount
            </MenuItem>
          </TextValidator>
          {
            values.billing_dashboard_type === 'Set Discount' ? (
              <TextValidator
                className={classes.textField}
                fullWidth
                label='Discount (%)'
                variant='outlined'
                onChange={(event) => handleChange('billing_dashboard_set_discount', event.target.value)}
                value={values.billing_dashboard_set_discount}
              />
            ) : null
          }
          {
            values.billing_dashboard_type === 'Tier Discount' ? (
              <TierDiscount
                rows={values.billing_dashboard_tier_discount || []}
                setRows={(tiers) => handleChange('billing_dashboard_tier_discount', tiers)}
              />
            ) : null
          }

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.support_request}
                onChange={(event) => handleChange('support_request', event.target.checked)}
              />
            )}
            label='Rapid Architecture Ticket'
          />

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.billing_viewer}
                onChange={(event) => handleChange('billing_viewer', event.target.checked)}
              />
            )}
            label='Billing Viewer'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled
                className={classes.checkBox}
                checked={values.gcp_service_account}
              />
            )}
            label='Service Account'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled={!values.gcp_service_account}
                className={classes.checkBox}
                checked={values.inventory}
                onChange={(event) => handleChange('inventory', event.target.checked)}
              />
            )}
            label='Inventory'
          />

          <FormControlLabel
            control={(
              <Checkbox
                disabled={!values.gcp_service_account}
                className={classes.checkBox}
                checked={values.system_health_dashboard}
                onChange={(event) => handleChange('system_health_dashboard', event.target.checked)}
              />
            )}
            label='System health'
          />

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.documentation}
                onChange={(event) => handleChange('documentation', event.target.checked)}
              />
            )}
            label='Documentation'
          />

          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.workspace_license}
                onChange={(event) => handleChange('workspace_license', event.target.checked)}
              />
            )}
            label='Workspace License'
          />
          <Box mb={2}>
            <Typography variant='h6'>
              Anomaly Detection
            </Typography>
          </Box>
          <FormControlLabel
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={values.anomaly_detection}
                onChange={(event) => handleChange('anomaly_detection', event.target.checked)}
              />
            )}
            label='Enable'
          />

          <TextValidator
            className={classes.textField}
            fullWidth
            disabled={!values.anomaly_detection}
            type='Number'
            inputProps={{ inputMode: 'numeric', pattern: '[0-100]*' }}
            label='Minimum Threshold'
            variant='outlined'
            onChange={(event) => handleChange('minimum_anomaly_threshold', Number(event.target.value))}
            value={values.minimum_anomaly_threshold}
            helperText='Minimum percentage increase that needs to be toogled'
          />
          <TextValidator
            className={classes.textField}
            fullWidth
            disabled={!values.anomaly_detection}
            type='Number'
            inputProps={{ inputMode: 'numeric' }}
            label='Minimum Require Change Amount'
            variant='outlined'
            value={values.minimum_value_toggled_anomaly}
            onChange={(event) => handleChange('minimum_value_toggled_anomaly', event.target.value)}
          />

        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        {
          shouldFieldsRender && (
            <Paper elevation={0} className={classes.paper} style={{ marginBottom: 20 }}>
              {
                values.gcp_service_account && (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        GCP Service Account
                      </Typography>
                    </Box>

                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      disabled
                      label='Service Account email'
                      variant='outlined'
                      onChange={(event) => handleChange('gcp_service_account', event.target.value)}
                      value={values.service_account_email}
                      helperText='Only used for System Health, Inventory, etc.'
                    />
                  </>
                )
              }

              {
                values.system_health_dashboard && (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        System health dashboard
                      </Typography>
                    </Box>

                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      label='Customized Grafana dashboard'
                      variant='outlined'
                      onChange={(event) => handleChange('grafana_external_link', event.target.value)}
                      value={values.grafana_external_link}
                      helperText='If blank Built-in (default) will be used'
                    />
                  </>
                )
              }

              {
                values.workspace_license && (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        Domain name
                      </Typography>
                    </Box>

                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      label='Identifier for Workspace license'
                      variant='outlined'
                      onChange={(event) => handleChange('domain_name', event.target.value)}
                      value={values.domain_name}
                      helperText='Only used for Workspace license'
                    />
                  </>
                )
              }

              {
                values.documentation && (
                  <>
                    <Box mb={2}>
                      <Typography variant='h6'>
                        Documentation
                      </Typography>
                    </Box>

                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      label='Customized Google Drive folder'
                      variant='outlined'
                      onChange={(event) => handleChange('drive_id', event.target.value)}
                      value={values.drive_id}
                      helperText='If blank Built-in (default) will be used'
                    />
                  </>
                )
              }
            </Paper>
          )
        }

        {renderAdvancedSettings()}

      </Grid>
    </Grid>

  );

  const renderButtons = () => (
    <SubmitGroup
      cancel={() => back()}
      updateIndicator={updateIndicator}
    />
  );

  return (
    <ValidatorForm onSubmit={() => updateForm(values)}>

      {renderForm()}
      {renderButtons()}

    </ValidatorForm>
  );
};

Form.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.any).isRequired,
  projects: PropTypes.arrayOf(PropTypes.any).isRequired,
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateIndicator: PropTypes.bool.isRequired,
  back: PropTypes.func.isRequired
};

export default Form;
