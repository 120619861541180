import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Grid, Paper, Divider, Typography
} from '@material-ui/core';
import colors from '../../helpers/colors';
import { form } from '../../helpers/styles';
import { jiraTicketById, ticketComments } from '../../api/support';
import LoadingIndicator from '../../components/LoadingIndicator';
import Attachments from './Attachments';
import { dateMoment } from '../../helpers/date';
import { firstResponseCalc } from '../../helpers/support';
import Comments from './Comments';
import Status from './Status';
import SnackBar, { defaultSnackState } from '../../components/SnackBar';
import Satisfaction from './Satisfaction';
import TitleBar from '../../components/TitleBar';
import Back from '../../components/Button/Back';

const useStyles = makeStyles(() => ({
  ...form,
  summaryTitle: {
    fontSize: '13pt',
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.darkGray
  },
  title: {
    fontSize: '11pt',
    fontWeight: 'bold',
    color: colors.darkGray
  },
  dividerGroup: {
    marginTop: 20,
    backgroundColor: 'whitesmoke'
  },
  button: {
    color: colors.neutralGray,
    borderColor: colors.neutralGray
  },
  description: {
    fontSize: '11pt',
    textAlign: 'justify',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line'
  }
}));

const View = () => {
  const { id } = useParams();
  const classes = useStyles();

  const [snack, setSnack] = useState(defaultSnackState);

  const [state, setState] = useState({
    isLoading: true,
    updateIndicator: false,
    data: null,
    comments: []
  });

  const handleStateChange = (key, value) => {
    const newState = {
      ...state,
      [key]: value
    };
    setState(newState);
  };

  const requestData = () => {
    jiraTicketById(id).then((data) => {
      ticketComments(id).then((comments) => {
        const newState = {
          ...state,
          isLoading: false,
          comments: comments.reverse(),
          data,
        };
        setState(newState);
      });
    });
  };

  const requestTicketData = () => {
    jiraTicketById(id).then((data) => {
      const newState = {
        ...state,
        isLoading: false,
        data,
      };
      setState(newState);
    });
  };

  const requestComments = () => {
    ticketComments(id).then((comments) => {
      const newState = {
        ...state,
        updateIndicator: false,
        comments: comments.reverse(),
      };
      setState(newState);
    });
  };

  useEffect(() => {
    requestData();
  }, []);

  const firstResponse = () => firstResponseCalc(
    state.data.creation_date,
    state.data.first_response_date
  );

  return (
    <>
      <TitleBar
        title='Rapid Architecture Ticket'
        subTitle={state.data && `(${state.data.customer_name})`}
        updateIndicator={state.updateIndicator}
      >
        <Back path='/supportrequest' />
      </TitleBar>
      {
        state.isLoading ? <LoadingIndicator /> : (
          <Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Paper
                  elevation={0}
                  className={classes.paper}
                  style={{ height: '100%' }}
                >
                  <Typography variant='h6' className={classes.summaryTitle}>
                    {`${id} ${state.data.summary}`}
                  </Typography>

                  <Box>
                    <Typography className={classes.title}>
                      Description
                    </Typography>
                    <Typography className={classes.description}>
                      {state.data.description}
                    </Typography>
                  </Box>

                  <Divider className={classes.dividerGroup} />

                  <Box mt={2}>
                    <Typography className={classes.title}>
                      {`Attachments (${state.data.attachments.length})`}
                    </Typography>
                    <Attachments
                      data={state.data}
                      updateIndicator={state.updateIndicator}
                      setUpdateIndicator={(value) => handleStateChange('updateIndicator', value)}
                      reload={(message) => {
                        setSnack({
                          open: true,
                          message
                        });

                        requestTicketData();
                      }}
                    />
                  </Box>

                  <Divider className={classes.dividerGroup} />

                  {
                    state.data.status === 'Closed' ? (
                      <Box mt={2}>
                        <Typography className={classes.title}>
                          Customer Satisfaction
                        </Typography>

                        <Satisfaction
                          data={state.data}
                          updateIndicator={state.updateIndicator}
                          setUpdateIndicator={(value) => handleStateChange('updateIndicator', value)}
                          openFeedBack={!state.data.customer_satisfaction_score}
                          reload={() => {
                            setSnack({
                              open: true,
                              message: 'Satisfaction level saved'
                            });

                            requestTicketData();
                          }}
                        />

                        <Divider className={classes.dividerGroup} />
                      </Box>
                    ) : null
                  }

                  <Box mt={2}>
                    <Typography className={classes.title}>
                      {`Comments (${state.comments.length})`}
                    </Typography>

                    <Comments
                      data={state.data}
                      comments={state.comments}
                      updateIndicator={state.updateIndicator}
                      setUpdateIndicator={(value) => handleStateChange('updateIndicator', value)}
                      reload={() => {
                        setSnack({
                          open: true,
                          message: 'Comment saved'
                        });

                        requestComments();
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper
                  elevation={0}
                  className={classes.paper}
                  style={{ height: '100%' }}
                >
                  <Status
                    data={state.data}
                    updateIndicator={state.updateIndicator}
                    setUpdateIndicator={(value) => handleStateChange('updateIndicator', value)}
                    reload={(message) => {
                      setSnack({
                        open: true,
                        message
                      });

                      requestData();
                    }}
                  />

                  <Box mt={2}>
                    <Typography className={classes.title}>
                      Created
                    </Typography>
                    <Typography>
                      {dateMoment(state.data.creation_date, 'YYYY/MM/DD HH:mm')}
                    </Typography>
                    <Typography>
                      {state.data.creation_by}
                    </Typography>
                  </Box>

                  {
                    state.data.first_response_date ? (
                      <Box mt={2}>
                        <Typography className={classes.title}>
                          First Response
                        </Typography>
                        <Typography>
                          {dateMoment(state.data.first_response_date, 'YYYY/MM/DD HH:mm')}
                          {' ('}
                          {firstResponse()}
                          {')'}
                        </Typography>
                        <Typography>
                          {state.data.first_response_by}
                        </Typography>
                      </Box>
                    ) : null
                  }

                  {
                    state.data.update_by && state.data.update_date ? (
                      <Box mt={2}>
                        <Typography className={classes.title}>
                          Updated
                        </Typography>
                        <Typography>
                          {dateMoment(state.data.update_date, 'YYYY/MM/DD HH:mm')}
                        </Typography>
                        <Typography>
                          {state.data.update_by}
                        </Typography>
                      </Box>
                    ) : null
                  }

                </Paper>
              </Grid>
            </Grid>

            <SnackBar
              open={snack.open}
              message={snack.message}
              onClose={() => setSnack(defaultSnackState)}
            />
          </Box>
        )
      }
    </>
  );
};

export default View;
