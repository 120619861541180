/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
import React, {
  useEffect, useState, useContext, useRef, useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Button, Grid, TextField, Tab, Tabs, Paper, MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from 'mui-datatables';
import ReactJson from 'react-json-view';
import { makeStyles } from '@material-ui/core/styles';
import { ForceGraph2D, ForceGraph3D } from 'react-force-graph';
import * as THREE from 'three';
import TitleBar from '../../components/TitleBar';
import { Context } from '../../components/ContextProvider';
import LoadingIndicator from '../../components/LoadingIndicator';
import { inventoryVisualization, inventoryNode } from '../../api/inventory';
import log from '../../helpers/log';
import colors from '../../helpers/colors';
import { columns, newCameraPosition } from '../../helpers/inventory';

const useStyles = makeStyles(() => ({
  tab: {
    '&.Mui-selected': {
      color: colors.poppy
    }
  },
  paper: {
    padding: 20
  },
  paperJson: {
    padding: 20,
    marginTop: 20
  },
  divider: {
    marginTop: 20,
    marginBottom: 20
  }
}));

const View = () => {
  const fgRef = useRef();
  const history = useHistory();
  const classes = useStyles();

  const { selectedCustomer } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [updateIndicator, setUpdateIndicator] = useState(false);
  const [nodeDictName, setNodeDictName] = useState({});
  const [nodeDetails, setNodeDetails] = useState(null);
  const [nodeData, setNodeData] = useState({});
  const [hoverNode, setHoverNode] = useState(null);
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [selectedGCPTab, setSelectedGCPTab] = useState(0);
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [selectedDagOrientation, setSelectedDagOrientation] = useState({
    dagOrientation: 'radialin',
  });

  const handleClick = useCallback((node) => {
    fgRef.current.cameraPosition(
      newCameraPosition(node),
      node,
      3000
    );
  }, [fgRef]);

  const handleDagOrientationChange = (data) => {
    setSelectedDagOrientation(data);
  };

  const onSelectedGCPTabChanged = (_, val) => {
    setSelectedGCPTab(val);
  };

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
      node.links.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleInvestigate = (data) => {
    if (data) {
      setAutocompleteValue(data.id);

      const node = data;
      handleNodeHover(node);
      fgRef.current.zoom(2, 3000);
      fgRef.current.centerAt(
        node.x,
        node.y,
        3000 // ms transition duration
      );

      setUpdateIndicator(true);
      inventoryNode(selectedCustomer, node.id).then((response) => {
        const rows = [];
        rows.push({ attribute: 'Asset Name', value: response.name });
        rows.push({ attribute: 'Asset Type', value: response.asset_type });
        rows.push({ attribute: 'Location', value: response.location });

        setNodeDetails(rows);
        setNodeData(JSON.parse(response.data));
      }).catch(() => {
        log.error('error retrieving inventory node');
      }).finally(() => {
        setUpdateIndicator(false);
      });
    } else {
      fgRef.current.zoomToFit(3000, 2);
      setNodeDetails(null);
    }
  };

  const onFilter = (event, data) => {
    if (data && fgRef.current.zoom) {
      const node = nodeDictName[data];
      handleNodeHover(node);

      fgRef.current.zoom(2, 3000);
      fgRef.current.centerAt(
        node.x,
        node.y,
        3000 // ms transition duration
      );
      handleInvestigate(node);
    } else {
      fgRef.current.zoomToFit(3000, 2);
    }
  };

  const paintRing = useCallback((node, ctx) => {
    const NODE_R = 4;
    // add ring just for highlighted nodes
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
    ctx.fillStyle = node === hoverNode ? 'red' : 'orange';
    ctx.fill();
  }, [hoverNode]);

  const VisualizeData = () => {
    inventoryVisualization(selectedCustomer).then((data) => {
      const nodeDict = {};
      const nodeDictByName = {};

      for (const node of data.nodes) {
        nodeDict[node.id] = node;
        nodeDictByName[node.asset_name] = node;
      }

      setNodeDictName(nodeDictByName);

      data.links.forEach((link) => {
        const a = nodeDict[link.source];
        const b = nodeDict[link.target];

        !a.neighbors && (a.neighbors = []);
        !b.neighbors && (b.neighbors = []);
        a.neighbors.push(b);
        b.neighbors.push(a);

        !a.links && (a.links = []);
        !b.links && (b.links = []);
        a.links.push(link);
        b.links.push(link);
      });

      setGraphData(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    VisualizeData();
  }, [selectedCustomer]);

  const renderDragOrientation = () => (
    <TextField
      select
      fullWidth
      label='Dag Orientation'
      className={classes.input}
      variant='outlined'
      value={selectedDagOrientation.dagOrientation}
      onChange={(e) => handleDagOrientationChange({ dagOrientation: e.target.value })}
      disabled={selectedGCPTab === 1}
      InputLabelProps={{
        shrink: true,
      }}
    >
      <MenuItem value='radialin'>Radial In</MenuItem>
      <MenuItem value='bu'>Bu</MenuItem>
      <MenuItem value='lr'>Lr</MenuItem>
      <MenuItem value='rl'>Rl</MenuItem>
      <MenuItem value='radialout'>Radial Out</MenuItem>
      <MenuItem value='tb'>td</MenuItem>
    </TextField>
  );

  const renderAssetName = () => (
    <Autocomplete
      fullWidth
      autoComplete
      className={classes.root}
      onChange={onFilter}
      value={autocompleteValue}
      options={graphData.nodes.map((asset) => asset.asset_name)}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Asset Name'
          variant='outlined'
        />
      )}
    />
  );

  const renderGraphTabs = () => (
    <div className={classes.chartContainer}>
      <Tabs
        value={selectedGCPTab}
        indicatorColor='primary'
        textColor='primary'
        onChange={onSelectedGCPTabChanged}
        TabIndicatorProps={{
          style: {
            background: colors.poppy
          }
        }}
      >
        <Tab label='2D' classes={{ root: classes.tab }} />
        <Tab label='3D' classes={{ root: classes.tab }} />
      </Tabs>
      {
        selectedGCPTab === 0 ? (
          <ForceGraph2D
            width={600}
            height={600}
            ref={fgRef}
            graphData={graphData}
            dagMode={selectedDagOrientation.dagOrientation}
            dagLevelDistance={100}
            backgroundColor='#FFFFFF'
            linkColor={() => 'rgba(40,30,20,0.2)'}
            nodeRelSize={4}
            nodeId='id'
            nodeVal={(node) => 100 / (node.level + 1)}
            nodeLabel='asset_name'
            nodeAutoColorBy='module'
            linkDirectionalParticles={2}
            linkDirectionalParticleWidth={2}
            d3VelocityDecay={0.3}
            onNodeClick={handleInvestigate}
            onNodeDragEnd={(node) => {
              node.fx = node.x;
              node.fy = node.y;
              node.fz = node.z;
            }}
            nodeCanvasObjectMode={(node) => (highlightNodes.has(node) ? 'before' : undefined)}
            nodeCanvasObject={paintRing}
          />
        ) : (
          <ForceGraph3D
            ref={fgRef}
            width={600}
            height={600}
            backgroundColor='#fff'
            linkColor={() => 'rgba(240,240,240,1)'}
            linkWidth={3}
            nodeVal={(node) => 100 / (node.level + 1)}
            nodeLabel='id'
            nodeColor='#000000'
            nodeRelSize={4}
            dagLevelDistance={50}
            graphData={graphData}
            onNodeClick={handleClick}
            nodeThreeObject={({ img }) => {
              const imgTexture = new THREE.TextureLoader().load(`/images/${img}`);
              const material = new THREE.SpriteMaterial({ map: imgTexture });
              const sprite = new THREE.Sprite(material);
              sprite.scale.set(12, 12);

              return sprite;
            }}
            onNodeDragEnd={(node) => {
              node.fx = node.x;
              node.fy = node.y;
              node.fz = node.z;
            }}
          />
        )
      }
    </div>
  );

  const renderAssetDetails = () => (
    <>
      <MUIDataTable
        elevation={1}
        title='Asset Details'
        data={nodeDetails}
        columns={columns}
        options={{
          print: false,
          filter: false,
          viewColumns: false,
          filterType: 'textField',
          selectableRows: 'none'
        }}
      />

      <Paper className={classes.paperJson}>
        <ReactJson src={nodeData} theme='shapeshifter:inverted' />
      </Paper>
    </>
  );

  return (
    <>
      <TitleBar
        title='Visualize Inventory'
        updateIndicator={updateIndicator}
      >
        <Button
          className={classes.button}
          onClick={() => history.push('/inventory')}
          variant='outlined'
          component='span'
          size='small'
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </TitleBar>
      {
        isLoading ? <LoadingIndicator /> : (
          <>
            <Paper elevation={1} className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {renderAssetName()}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {renderDragOrientation()}
                </Grid>
              </Grid>
            </Paper>

            <div className={classes.divider} />

            <Grid container spacing={2}>
              <Grid item md={12} lg={6}>
                <Paper elevation={1} className={classes.paper}>
                  {renderGraphTabs()}
                </Paper>
              </Grid>
              {
                nodeDetails && !updateIndicator ? (
                  <Grid item md={12} lg={6}>
                    {renderAssetDetails()}
                  </Grid>

                ) : null
              }
            </Grid>
          </>
        )
      }
    </>
  );
};

export default View;
