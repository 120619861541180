import React, { useContext } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import { Context } from './components/ContextProvider';
import SignIn from './pages/SignIn';
import { validateCustomers } from './helpers/customers';

const App = () => {
  const { user, customers } = useContext(Context);

  return (
    <BrowserRouter>
      {
        user && user.claims && validateCustomers(customers)
          ? <Route component={Navigation} />
          : <Route path='*' component={SignIn} />
      }
    </BrowserRouter>
  );
};

export default App;
