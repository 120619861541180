import {
  Button, Paper, Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import useWorkspaceLicenseStyles from '../../styles';

function ViewOrderCard({
  license, managingOrder, slideDistance, onManageOrderClick
}) {
  const [style, animate] = useSpring(() => ({ x: 0, zIndex: 10 }));
  const classes = useWorkspaceLicenseStyles();

  useEffect(() => {
    if (managingOrder) {
      animate({ x: -slideDistance, delay: 100 });
    } else {
      animate({ x: 0, delay: 100 });
    }
  }, [managingOrder]);

  return (
    <>
      <animated.div style={style} className={classes.animatedContainer}>
        <Paper
          square
          elevation={1}
          className={classes.paper}
        >
          <div className={classes.cardHeader}>
            <Typography id='modal-modal-title' variant='h4' component='h2'>
              {license.sku_name}
            </Typography>
          </div>
          <div className={classes.orderInformation}>
            <div className={classes.titleContainer}>
              <div className={classes.skuContainer}>
                <Typography id='modal-modal-description'>
                  SKU
                </Typography>
                <Typography className={classes.skuNumber}>
                  {license.sku_id}
                </Typography>
              </div>
              <Typography id='modal-modal-description'>
                {license.volume_available}
                {' '}
                /
                {' '}
                {license.volume_total}
                {' '}
                Available
              </Typography>
            </div>
            <div className={classes.licenseField}>
              <Typography id='modal-modal-description'>
                Order Threshold
              </Typography>
              <Typography id='modal-modal-description'>{license.threshold}</Typography>
            </div>
            <div className={classes.licenseField}>
              <Typography id='modal-modal-description'>
                Auto Order
              </Typography>
              <Typography id='modal-modal-description'>{license.auto_order ? 'Yes' : 'No'}</Typography>
            </div>

          </div>

          <footer className={classes.oneButtonContainer}>
            <Button onClick={onManageOrderClick} variant='contained' className={classes.fullSpanButton}>
              Manage Order
            </Button>
          </footer>
        </Paper>
      </animated.div>
    </>
  );
}

ViewOrderCard.propTypes = {
  license: PropTypes.instanceOf(Object).isRequired,
  onManageOrderClick: PropTypes.func.isRequired,
  managingOrder: PropTypes.bool.isRequired,
  slideDistance: PropTypes.number.isRequired
};

export default ViewOrderCard;
