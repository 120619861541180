import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import colors from '../../helpers/colors';

/**
 * Centered Loading based on CircularProgress
 */
const LoadingIndicator = ({ minHeight }) => (

  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    minHeight={minHeight}
  >
    <CircularProgress size={50} style={{ color: colors.poppy }} />
  </Box>
);

LoadingIndicator.defaultProps = {
  minHeight: 300
};

LoadingIndicator.propTypes = {
  minHeight: PropTypes.number,
};

export default LoadingIndicator;
