import { makeStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 10;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP * 20,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  variant: 'menu'
};

export const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 20,
    width: '100%'
  },
  listItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    height: 32
  },
  indeterminateColor: {
    color: '#f50057'
  },
  selectAllText: {
    fontWeight: 600
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));
