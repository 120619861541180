import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { BarLoader as Loader } from 'react-spinners';
import colors from '../../helpers/colors';

/**
 * Centered Loading based on DotLoader
 */
const BarLoader = ({ loading }) => (
  <Box
    width={1}
    display='flex'
    justifyContent='center'
    alignItems='flex-end'
  >
    <Loader
      color={colors.poppy}
      loading={loading}
      width='100%'
    />
  </Box>
);

BarLoader.defaultProps = {
  loading: true,
};

BarLoader.propTypes = {
  loading: PropTypes.bool,
};

export default BarLoader;
