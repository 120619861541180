import React from 'react';
import PropTypes from 'prop-types';
import DialogMui from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';

const Dialog = ({
  open,
  onClose,
  title,
  children,
  actionButtons,
}) => (
  <DialogMui
    fullWidth
    maxWidth='sm'
    open={open}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    onClose={onClose}
    transitionDuration={{
      enter: 800,
      exit: 0
    }}
  >
    {title ? (
      <DialogTitle>{title}</DialogTitle>
    ) : null}
    <DialogContent>
      {children}
    </DialogContent>
    {!!actionButtons && open && <DialogActions>{actionButtons}</DialogActions>}
  </DialogMui>
);

Dialog.defaultProps = {
  open: false,
  onClose: null,
  title: '',
  actionButtons: null,
  children: null,
};

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  actionButtons: PropTypes.node,
  children: PropTypes.node
};

/**
 * Default Dialog State
 */
export const defaultDialogState = {
  open: false,
  title: '',
  description: '',
};

export default Dialog;
