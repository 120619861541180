import { currentTimeZone } from '../helpers/date';
import { createLog } from './requests';

/**
 * Logging
 * @param {Object} user
 * @param {Object} resource
 * @param {Object} data
 * @param {type} type
 */
const logging = async (
  user,
  resource,
  data,
  type = 'ERROR'
) => {
  const entry = {
    date: await currentTimeZone(),
    user: user.email,
    resource,
    type,
    data
  };

  return createLog(entry);
};

export default logging;
