import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, TextField, Typography, MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../helpers/colors';
import Dialog from '../../components/Dialog';
import {
  avaiableStatusList, saveNewComment, updateTicketPriority, updateTicketStatus
} from '../../api/support';
import { Context } from '../../components/ContextProvider';
import { jiraLimits, prioritylist, statusList } from '../../helpers/support';

const useStyles = makeStyles(() => ({
  textField: {
    paddingBottom: 20,
  },
  button: {
    marginTop: 10,
    color: colors.poppy,
    borderColor: colors.poppy
  },
  comment: {
    borderBottom: '1px solid whitesmoke',
    paddingBottom: 10,
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  changedValue: {
    fontWeight: 'bold',
    marginBottom: 10
  }
}));

const Status = ({
  data,
  reload,
  updateIndicator,
  setUpdateIndicator
}) => {
  const classes = useStyles();

  const { user } = useContext(Context);

  const [avaiableStatus, setAvaiableStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    status: data.status,
    priority: data.priority,
    error: false,
    type: '',
    comment: ''
  });

  useEffect(() => {
    const role = user.claims.admin ? 'admin' : 'customer';

    if (data.status !== 'Closed') {
      avaiableStatusList(role, state.status).then((response) => {
        setAvaiableStatus(response.transitions);
      });
    }
  }, [data]);

  const handleClose = () => {
    const newState = {
      ...state,
      comment: '',
      error: false
    };

    setState(newState);
    setOpen(false);
  };

  const handleStatusChange = (value) => {
    const newState = {
      ...state,
      type: 'Status',
      status: value,
    };

    setState(newState);
    setOpen(true);
  };

  const handlePriorityChange = (value) => {
    const newState = {
      ...state,
      type: 'Priority',
      priority: value,
    };

    setState(newState);
    setOpen(true);
  };

  const handleCommentChange = (value) => {
    const newState = {
      ...state,
      comment: value,
      error: false
    };

    setState(newState);
  };

  const setError = () => {
    const newState = {
      ...state,
      error: true
    };

    setState(newState);
  };

  const updateStatus = () => {
    const newComment = {
      message: state.comment.trim(),
      author: user.claims.email,
      date: new Date().toISOString().toString(),
      previous_status: data.status,
      current_status: state.status,
    };

    saveNewComment(data.jira_issue_key, newComment).then(() => {
      const status = {
        name: state.status,
        author: user.claims.email,
      };

      updateTicketStatus(data.jira_issue_key, status).then(() => {
        reload('status and comment saved');
      });
    }).finally(() => {
      handleCommentChange('');
    });
  };

  const updatePriority = () => {
    const body = {
      priority: {
        name: state.priority,
        author: user.claims.email,
      },
      comment_body: {
        message: state.comment.trim(),
        author: user.claims.email,
        date: new Date().toISOString().toString(),
        previous_status: data.priority,
        current_status: state.priority,
      }
    };

    updateTicketPriority(data.jira_issue_key, body).then(() => {
      reload('Priority saved');
    }).finally(() => {
      handleCommentChange('');
    });
  };

  const handleSubmit = () => {
    if (!state.comment) {
      setError();
    } else {
      setUpdateIndicator(true);
      setOpen(false);

      if (state.type === 'Status') {
        updateStatus();
      } else if (state.type === 'Priority') {
        updatePriority();
      }
    }
  };

  const disabledPriority = () => {
    if (
      !updateIndicator && (
        data.status === 'Open'
        || data.status === 'In Progress'
      )
    ) {
      return false;
    }

    return true;
  };

  const disabledStatus = () => {
    if (
      !updateIndicator && (
        data.status === 'Open'
        || data.status === 'In Progress'
        || data.status === 'Resolved'
      )
    ) {
      return false;
    }

    return true;
  };

  const renderChangedValue = () => {
    const changedValue = {
      Status: `${data.status} > ${state.status}`,
      Priority: `${data.priority} > ${state.priority}`
    };

    return (
      <Typography className={classes.changedValue}>
        {`${state.type}: ${changedValue[state.type]}`}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classes.textField}
        select
        fullWidth
        label='Status'
        variant='outlined'
        onChange={(event) => handleStatusChange(event.target.value)}
        value={data.status}
        disabled={disabledStatus()}
      >
        {statusList.map((value) => (
          <MenuItem
            key={value}
            value={value}
            disabled={!avaiableStatus.includes(value)}
          >
            {value}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className={classes.textField}
        select
        fullWidth
        label='Priority'
        variant='outlined'
        onChange={(event) => handlePriorityChange(event.target.value)}
        disabled={disabledPriority()}
        value={data.priority}
      >
        {prioritylist.priorities.map((key) => (
          <MenuItem
            key={key}
            value={key}
            disabled={disabledPriority()}
          >
            {key}
          </MenuItem>
        ))}
      </TextField>

      <Dialog
        title={`You are updating the ${state.type} of this support request`}
        open={open}
        onClose={() => handleClose()}
        actionButtons={(
          <>
            <Button
              onClick={() => handleClose()}
              color='primary'
            >
              Cancel
            </Button>
            <Button
              color='primary'
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </>
        )}
      >
        {renderChangedValue()}

        <TextField
          className={classes.textField}
          fullWidth
          label='Comment'
          variant='outlined'
          onChange={(event) => handleCommentChange(event.target.value)}
          multiline
          rows={6}
          value={state.comment}
          error={state.error && !state.comment}
          helperText={
            state.error && !state.comment
              ? 'This field is required'
              : `The comment cannot be longer than ${jiraLimits.comment} characters.`
          }
          inputProps={{
            maxLength: jiraLimits.comment,
          }}
        />
      </Dialog>
    </div>
  );
};

Status.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired,
  updateIndicator: PropTypes.bool.isRequired,
  setUpdateIndicator: PropTypes.func.isRequired,
};

export default Status;
