import loadingData from '../assets/images/loadingData.png';
import colors from './colors';

export const form = {
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  checked: {},
  checkBox: {
    '&$checked': {
      color: colors.poppy,
    }
  },
  paper: {
    padding: 20
  },
  buttons: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 450
  },
  textField: {
    marginBottom: 20
  },
  divider: {
    padding: 5,
    backgroundColor: 'white'
  },
};

export const viewLicenseStyle = {
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  gridFlexEnd: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'end'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },

  licenseButtons: {
    float: 'right',
    marginTop: 10,
    marginRight: 20,
    width: '60',
    height: '30',
    backgroundColor: colors.darkGray,
    color: colors.lightGray
  },
  licenseButtonSave: {
    float: 'right',
    marginTop: 10,
    marginRight: 20,
    width: '60',
    height: '30',
    backgroundColor: colors.poppy
  },

  listItemText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },

  licenseDivider: {
    backgroundColor: colors.darkGray,
    width: '1px'
  },

  primaryTextField: {
    marginTop: 5,
  },
  secondaryText: {
    textAlign: 'center',
    float: 'center',
    fontSize: '16px',
  },

  completeOrderHeader: {
    fontSize: '22px',
    textAlign: 'center',
    backgroundColor: colors.pink
  },
  completeOrderButtonSave: {
    width: '50%',
    backgroundColor: colors.poppy,
    color: colors.darkGray
  },
  completeOrderButtonCancel: {
    width: '50%',
    backgroundColor: colors.darkGray,
    color: colors.lightGray
  },

  orderButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 205,
    marginLeft: 80
  },

  paper: {
    width: 500,
    height: 650,
    backgroundColor: colors.backgroundGray,
  },
  headerOne: {
    fontSize: '22px',
    backgroundColor: colors.poppy,
    borderBottom: `1px solid ${colors.darkGray}`,
    borderColor: colors.poppy,
    textAlign: 'center',
    color: colors.darkGray
  },
  headerTwo: {
    fontSize: '22px',
    backgroundColor: colors.darkGray,
    borderBottom: `1px solid ${colors.darkGray}`,
    borderColor: colors.poppy,
    textAlign: 'center',
    color: colors.lightGray
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footerButtons: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between',
    backgroundColor: 'transparent'
  }
};

export const settingsControls = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 75px)',
    padding: 20,
    paddingTop: 0,
    '& .MuiMenuItem': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  controls: {
    paddingBottom: 20
  },
  input: {
    marginTop: 20,
    width: '100%',
  },
  checkInput: {
    marginTop: 20
  },
  footer: {
    width: 300,
    marginTop: 'auto'
  },
  divider: {
    marginBottom: 10
  },
  badge: {
    width: '90%'
  },
  badgeItem: {
    overflow: 'visible'
  },
  groupBy: {
    padding: 0,
    minWidth: 300
  }
};

export const billingProjects = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 20,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row'
  }
};

export const loadingStyles = {
  width: '99%',
  height: '495px',
  padding: 20,
  backgroundImage: `url(${loadingData})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '500px 275px',
  backgroundPosition: 'center'
};

export const checkLists = (theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    border: '1px solid lightgrey',
    width: 350,
    height: 400,
    overflow: 'auto',
  },
  button: {
    height: 40,
    margin: theme.spacing(0.5, 0),
  },
  checkbox: {
    padding: '2px',
  },
  checkboxLabel: {
    fontSize: '1em'
  },
});
