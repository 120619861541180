/* eslint-disable import/prefer-default-export */
import apiRequest from './apiRequest';

export const sendLicenseAction = (domainName, orderId, action) => {
  const options = {
    url: `/v1/licenses/orders/${domainName}/${orderId}/${action}`,
    method: 'POST'
  };
  return apiRequest(options);
};

export const getLicenseInfo = (domainName) => {
  const options = {
    url: `/v1/licenses/${domainName}`,
    method: 'GET'
  };

  return apiRequest(options);
};

export const getLicenseOrderInfo = (customerId, startDate, endDate) => {
  const options = {
    url: `/v1/licenses/orders/history/${customerId}?start_date=${startDate}&end_date=${endDate}`,
    method: 'GET'
  };

  return apiRequest(options);
};

export const getLicenseHistoryInfo = (domainName, active, startDate, endDate) => {
  const options = {
    url: `/v1/licenses/history/${domainName}/${active}?start_date=${startDate}&end_date=${endDate}`,
    method: 'GET'
  };
  return apiRequest(options);
};

export const modifyLicense = (customerName, skuId, data) => {
  const options = {
    url: `/v1/licenses/${customerName}/${skuId}/`,
    method: 'POST', // Should be PUT but backend needs to change
    data
  };
  return apiRequest(options);
};

export const createOrder = (data, domainName) => {
  const options = {
    url: `/v1/licenses/orders/${domainName}`,
    method: 'PUT', // Should be POST but backend needs to change
    data
  };
  return apiRequest(options);
};

export const createLicense = (data, customerId) => {
  const options = {
    url: `/v1/licenses/${customerId}`,
    method: 'PUT', // Should be POST but backend needs to change
    data
  };
  return apiRequest(options);
};
