import React, {
  useState, useEffect, useContext
} from 'react';
import { Context } from '../../../../components/ContextProvider';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { details } from '../../../../api/billing';
import { cacheName } from '../../../../helpers/utils';
import ChartMap from '../ChartMap';

const ChartProjectService = () => {
  const {
    selectedCustomer, settings, chartProjectService, updateContext,
    billingAccountId, billing
  } = useContext(Context);

  const { services } = billing;

  const cache = cacheName(`${selectedCustomer}-${billingAccountId}`, settings);

  const [isLoading, setIsLoading] = useState(true);

  const getTotalData = () => {
    if (services) {
      details(
        selectedCustomer,
        billingAccountId,
        settings,
        services
      ).then((response) => {
        updateContext({
          chartProjectService: {
            ...chartProjectService,
            services: settings.services,
            [cache]: response
          }
        });
      });
    }
  };

  useEffect(() => {
    if (
      chartProjectService
      && chartProjectService[cache]
      && chartProjectService.services === settings.services
    ) {
      setIsLoading(false);
    } else {
      getTotalData();
    }
  }, [billingAccountId]);

  return (
    isLoading ? <LoadingIndicator /> : (
      <ChartMap data={chartProjectService[cache]} />
    )
  );
};

export default ChartProjectService;
