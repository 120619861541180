import {
  Button, Paper, Typography
} from '@material-ui/core';
import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import useWorkspaceLicenseStyles from '../../styles';
import { sendLicenseAction } from '../../../../api/license';

import { Context } from '../../../../components/ContextProvider';
import SnackBar, { defaultErrorSnackState, defaultSnackState } from '../../../../components/SnackBar';

function ManageOrderCard({
  license, managingOrder, slideDistance
}) {
  const [style, animate] = useSpring(() => ({ opacity: 0, x: 0, zIndex: 5 }));
  const [snack, setSnack] = useState(defaultSnackState);

  const { customerData } = useContext(Context);

  const classes = useWorkspaceLicenseStyles();

  const onOrderAction = (action) => {
    sendLicenseAction(customerData.domain_name, license.order_id, action).then(() => {
      setSnack({ message: 'Action successful', open: true, onClose: () => setSnack(defaultSnackState) });
    }).catch(() => {
      setSnack({
        message: 'Action failed', severity: 'error', open: true, onClose: () => setSnack(defaultErrorSnackState)
      });
    });
  };

  useEffect(() => {
    if (managingOrder) {
      animate({ opacity: 1, x: slideDistance, delay: 100 });
    } else {
      animate({ opacity: 0, x: 0, delay: 100 });
    }
  }, [managingOrder]);

  return (
    <>
      <SnackBar
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        onClose={snack.onClose}
      />
      <animated.div style={style} className={classes.animatedContainer}>
        <Paper
          square
          elevation={1}
          className={classes.paper}
        >
          <div className={classes.orderHeader}>
            <Typography id='modal-modal-title' variant='h4' component='h2'>
              Manage Order
            </Typography>
          </div>
          <div className={classes.orderInformation}>
            <div className={classes.licenseField}>
              <Typography id='modal-modal-description'>
                Order amount:
              </Typography>
              <Typography id='modal-modal-description'>{license.order_amount}</Typography>
            </div>
            <div className={classes.licenseField}>
              <Typography id='modal-modal-description'>
                Order status:
              </Typography>
              <Typography id='modal-modal-description'>{license.status}</Typography>
            </div>
            <div className={classes.licenseField}>
              <Typography id='modal-modal-description'>
                User:
              </Typography>
              <Typography id='modal-modal-description'>{license.order_by}</Typography>
            </div>
            <div className={classes.licenseField}>
              <Typography id='modal-modal-description'>
                Comment:
              </Typography>
              <Typography id='modal-modal-description'>{license.comment}</Typography>
            </div>
            <div className={classes.licenseField}>
              <Button onClick={() => onOrderAction(true)} variant='contained' className={classes.updateButton}>
                Approve
              </Button>
              <Button onClick={() => onOrderAction(false)} variant='contained' className={classes.orderButton}>
                Reject
              </Button>
            </div>
          </div>
        </Paper>
      </animated.div>
    </>
  );
}

ManageOrderCard.propTypes = {
  license: PropTypes.instanceOf(Object).isRequired,
  managingOrder: PropTypes.bool.isRequired,
  slideDistance: PropTypes.number.isRequired
};

export default ManageOrderCard;
