// TODO make functions that return license columns, argument = action onClick

export const existingLicenseColumns = [{ title: 'License', field: 'sku_name' },
  { title: 'SKU', field: 'sku_id' },
  { title: 'Total', field: 'volume_total' },
  { title: 'Available', field: 'volume_available' },
  { title: 'Order Treshold', field: 'threshold' },
  { title: 'Pending order', field: 'new_order' },
  { title: 'Auto order', field: 'auto_order' },
];

// Workspace license table

export const orderLicenseColumns = [{ title: 'License', field: 'sku_name' },
  { title: 'SKU', field: 'sku_id' },
  { title: 'Order Amount', field: 'order_amount' },
  { title: 'Ordered by', field: 'order_by' },
  { title: 'Status', field: 'status' },
  { title: 'Fulfilled Time', field: 'order_time' }];

export const historyLicenseColumns = [{ title: 'Date', field: 'date' },
  { title: 'License', field: 'sku_name' },
  { title: 'SKU', field: 'sku_id' },
  { title: 'Total', field: 'volume_total' },
  { title: 'Available', field: 'volume_available' }];

export const workspaceLabels = ['Existing', 'Orders', 'History'];

export const sortListData = (isLeftList, selectedLicense, isExistingList) => {
  if (isExistingList) {
    if (isLeftList) {
      return {
        sku_name: selectedLicense.sku_name,
        sku_id: selectedLicense.sku_id,
        volume_total: selectedLicense.volume_total,
        volume_available: selectedLicense.volume_available,
        threshold: selectedLicense.threshold,
        auto_order: selectedLicense.auto_order,
        new_order: selectedLicense.new_order,
      };
    }
    return {
      order_amount: '',
      order_by: '',
      comment: ''
    };
  }
  if (isLeftList) {
    return {
      sku_name: selectedLicense.sku_name,
      sku_id: selectedLicense.sku_id,
      volume_total: selectedLicense.volume_total,
      volume_available: selectedLicense.volume_available,
      threshold: selectedLicense.threshold,
      auto_order: selectedLicense.auto_order,
    };
  }
  return {
    order_amount: selectedLicense.order_amount,
    status: selectedLicense.status,
    order_by: selectedLicense.order_by,
    comment: selectedLicense.comment
  };
};
