import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import colors from '../../helpers/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: '2px solid lightgray',
    width: '100%'
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    fontWeight: 500,
    fontSize: 14,
    color: colors.neutralGray
  }
}));

const DividerWithText = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};

DividerWithText.propTypes = {
  children: PropTypes.node.isRequired
};

export default DividerWithText;
