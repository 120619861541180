import React from 'react';
import PropTypes from 'prop-types';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Typography, Box } from '@material-ui/core';
import colors from '../../helpers/colors';

/**
 * Overrides Add Button Box
 */
const AddBox = ({ title }) => (
  <Box
    component='span'
    style={{
      backgroundColor: colors.poppy,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 5,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 4,
      boxShadow: '0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%'
    }}
  >
    <AddBoxIcon style={{
      color: 'white',
      fontSize: '20px',
    }}
    />
    <Typography style={{
      color: 'white',
      fontSize: '12px',
      paddingTop: 2,
      paddingLeft: 6,
      textTransform: 'uppercase'
    }}
    >
      {title}
    </Typography>
  </Box>
);

AddBox.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AddBox;
